import React from "react"
import tw from "twin.macro"
import { SearchDropDownProps } from "./SearchDropDown.d"

/**
 *
 * @author Stu Finn
 * @summary - Custom dropdown behaviour for Search functionality.
 *
 * @returns <SearchDropDown />
 *
 * @param {SearchDropDownProps} props
 * @param {boolean} props.show - Show or hide search dropdown
 * @param {children} props.children - Child elements passed to dropdown
 * @param {number} props.index - Index of Nav item
 * @param {boolean} props.mobileView - passed state of mobile view
 * @param {boolean} props.toggleMobileDropdown - state for display of dropdown in mobile view
 *
 * @todo - Unit tests
 */

const SearchDropDown: React.FC<SearchDropDownProps> = ({
  show,
  children,
  index,
  mobileView,
  toggleMobileDropdown,
}): JSX.Element => {
  return (
    <>
      <div
        css={[
          tw`grid justify-items-center w-full absolute top-full left-0 bg-gray-50 max-h-0  transition-all  ease-out duration-300 overflow-hidden shadow-3`,
          show && index && show === index && tw` max-h-screen z-50`,
          mobileView && toggleMobileDropdown && tw` max-h-screen z-50`,
        ]}
      >
        {children}
      </div>
      <div
        css={[
          tw`hidden`,
          show &&
            tw`lg:(block bg-gray-900 opacity-70 h-full w-full fixed top-[118px] left-0 z-40)`,
        ]}
      ></div>
    </>
  )
}

export default SearchDropDown
