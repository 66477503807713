import React, { useState } from "react"
import tw from "twin.macro"
import { RefreshProps } from "./Refresh.d"
import Icon from "../Icon"

const Refresh: React.FC<RefreshProps> = ({
  label,
  checked = false,
  onChange = () => null,
  ...remainingProps
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <label
      htmlFor={label}
      css={[tw`flex items-center gap-5`, tw`w-auto`]}
      {...remainingProps}
    >
      <input
        type="checkbox"
        name="toggle"
        id={label}
        checked={checked}
        onChange={onChange}
        css={[tw`h-10 w-10 absolute appearance-none cursor-pointer`]}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <span
        css={[
          tw`h-9 w-9 p-1 rounded-full bg-white flex items-center transition-transform duration-100 relative pointer-events-none`,
          isHovered && tw`transform rotate-180`,
        ]}
      >
        <Icon.Refresh colorA="gray-600" colorB="gray-800" css={[tw`h-9 w-9`]} />
      </span>
      {label}
    </label>
  )
}

export default Refresh
