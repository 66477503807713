import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * EvBattery icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const EvBattery = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg fill="none" viewBox="0 0 37 36" {...remainingProps}>
      <mask
        id="a"
        width="37"
        height="36"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path fill="#fff" d="M36.5 0H.5v36h36V0Z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill={colorString}
          d="M34.813 18c0-4.48-3.494-8.133-7.903-8.41-.277-4.41-3.93-7.902-8.41-7.902s-8.133 3.493-8.41 7.901C5.68 9.867 2.187 13.521 2.187 18c0 4.48 3.493 8.133 7.901 8.41.278 4.41 3.932 7.902 8.411 7.902 4.48 0 8.133-3.493 8.41-7.901 4.41-.278 7.902-3.932 7.902-8.411Zm-9.665 6.75H11.852c.538-3.187 3.31-5.625 6.648-5.625 3.338 0 6.11 2.438 6.648 5.625Zm-13.296-13.5h13.297c-.539 3.187-3.311 5.625-6.649 5.625s-6.11-2.438-6.648-5.625ZM18.5 3.375c3.532 0 6.434 2.728 6.721 6.188H11.78c.287-3.46 3.19-6.188 6.721-6.188ZM3.875 18c0-3.562 2.775-6.48 6.275-6.726A8.433 8.433 0 0 0 15.49 18a8.434 8.434 0 0 0-5.34 6.726C6.65 24.48 3.875 21.562 3.875 18ZM18.5 32.625c-3.532 0-6.434-2.728-6.721-6.188h13.443c-.288 3.46-3.19 6.188-6.722 6.188Zm8.35-7.9A8.434 8.434 0 0 0 21.51 18a8.434 8.434 0 0 0 5.34-6.726c3.5.245 6.275 3.164 6.275 6.726s-2.775 6.48-6.275 6.726Z"
        />
      </g>
    </svg>
  )
}

export default EvBattery
