import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Phone icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Phone: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-2 -2 76 76" {...remainingProps}>
      <path
        d="M14.68.002c1.886.044 3.326.955 4.066 1.978a114 114 0 0 1 1.417 2.095l.777 1.165c.691 1.039 1.482 2.231 2.282 3.441l.96 1.453a779.618 779.618 0 0 1 4.047 6.17c.553.87.826 2.012.689 3.322-.102.978-.437 2.068-.995 3.124-.442.843-1.674 3.025-2.664 4.77l-.614 1.078c1.176 1.619 3.806 4.934 8.81 9.937 5.004 5.003 8.32 7.636 9.939 8.813.552-.314 1.221-.695 1.913-1.087 2.248-1.27 3.378-1.899 3.927-2.187 2.488-1.316 4.939-1.234 6.415-.333.892.546 5.94 3.884 9.807 6.46l1.581 1.054 1.206.806c1.032.69 1.555 1.044 1.735 1.17 1.064.745 1.977 2.183 2.02 4.07.028 1.226-.312 2.654-1.296 4.057C69.055 63.716 61.336 72.094 58.265 72c-2.913-.094-14.544-.174-36.319-21.954C.16 28.255.092 16.62.001 13.765-.1 10.649 8.28 2.932 10.638 1.282c1.4-.976 2.818-1.308 4.042-1.28Z"
        stroke={colorString}
        stroke-width="4"
        fill="none"
        fill-rule="evenodd"
      />
    </svg>
  )
}

export default Phone
