import { useCallback } from "react"
import { useTealiumContext } from "../../contexts/Tealium"
import { Vehicle } from "../../global"
import { firstLettersCapital, toUsd } from "../../helpers"
import useTealiumEvent from "./useTealiumEvent"

/**
 * @author Becca
 * @summary returns functions for tealium "carousel_click" events on inventory carousel views, including panorama events, and adding analytic-ids to panorama elements
 */

const useCustomCarouselEvent = (
  vehicle: Vehicle,
  segment: string,
  location: string,
  index: number,
  hasPortPhotos: string,
) => {
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  const addAnalyticIds = (
    el: HTMLElement,
    onFullView?: boolean,
    onDefaultView?: boolean,
  ) => {
    //additional logic needed for elements not available on panorama load (i.e. the default view button)
    const addOnClick = onFullView || onDefaultView || false
    const condAnalyId =
      location == "gallery" ? "gallery:gallery" : `${location}:${index + 1}`

    if (!addOnClick) {
      const zoomInButton = el.getElementsByClassName(
        "pnlm-zoom-in pnlm-sprite pnlm-control",
      )[0]
      zoomInButton?.setAttribute(
        "analytics-id",
        `panorama zoom in:${condAnalyId}`,
      )

      const zoomOutButton = el.getElementsByClassName(
        "pnlm-zoom-out pnlm-sprite pnlm-control",
      )[0]
      zoomOutButton?.setAttribute(
        "analytics-id",
        `panorama zoom out:${condAnalyId}`,
      )

      const panContainer = el.getElementsByClassName("pnlm-dragfix")[0]
      panContainer?.setAttribute("analytics-id", `panorama pan:${condAnalyId}`)

      const fullscreenView = el.getElementsByClassName(
        "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control",
      )[0]
      fullscreenView?.setAttribute(
        "analytics-id",
        `panorama full view:${condAnalyId}`,
      )
    }
    //remove current analytics-id and add updated for default view -> fullscreen view
    if (onFullView) {
      const currentEl = el.parentElement
      const defaultView = currentEl.getElementsByClassName(
        "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control",
      )[0]
      defaultView?.removeAttribute("analytics-id")
      defaultView?.setAttribute(
        "analytics-id",
        `panorama default view:${condAnalyId}`,
      )
    }
    //remove current analytics-id and add updated for fullscreen view -> default view
    if (onDefaultView) {
      const currentEl = el.parentElement
      const defaultView = currentEl.getElementsByClassName(
        "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control",
      )[0]
      defaultView?.removeAttribute("analytics-id")
      defaultView?.setAttribute(
        "analytics-id",
        `panorama full view:${condAnalyId}`,
      )
    }
  }

  const handleClick = useCallback(
    e => {
      const handleActionForLoc = (eventName: string) => {
        if (location == "inventory" || location == "gallery") {
          return eventName
        } else {
          return `${location}:${eventName}`
        }
      }
      trackTealEvent({
        ...tealPageData,
        tealium_event: "carousel_click",
        carousel_action:
          e.target.className == "pnlm-zoom-in pnlm-sprite pnlm-control"
            ? handleActionForLoc(`panorama zoom in`)
            : e.target.className == "pnlm-zoom-out pnlm-sprite pnlm-control"
              ? handleActionForLoc(`panorama zoom out`)
              : e.target.className ==
                  "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control"
                ? handleActionForLoc(`panorama full view`)
                : e.target.className ==
                    "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control pnlm-fullscreen-toggle-button-active"
                  ? handleActionForLoc(`panorama default view`)
                  : handleActionForLoc(`panorama pan`),
        vehicle_model: vehicle.series,
        vehicle_exterior_color: vehicle.color.exterior.name,
        vehicle_year: vehicle.year,
        vehicle_interior_color: vehicle.color.interior.name,
        vehicle_trim: vehicle.grade,
        vehicle_trim_id: vehicle.modelNumber,
        vehicle_vin: vehicle.vin,
        allocated_dealer_name: firstLettersCapital(vehicle.dealerName),
        allocated_dealer_code: vehicle.dealer,
        vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
        vehicle_model_truncated: vehicle.series,
        vehicle_segment_vehicle_page: segment,
        has_port_photos: hasPortPhotos,
      })
      //update analytics-ids on view change
      if (
        e.target.className ==
        "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control"
      ) {
        addAnalyticIds(e.target, true)
      }
      if (
        e.target.className ==
        "pnlm-fullscreen-toggle-button pnlm-sprite pnlm-fullscreen-toggle-button-inactive pnlm-controls pnlm-control pnlm-fullscreen-toggle-button-active"
      ) {
        addAnalyticIds(e.target, false, true)
      }
    },
    [vehicle, segment, tealPageData],
  )

  const inventoryCarouselTealClick = (view: string) => {
    trackTealEvent({
      tealium_event: "carousel_click",
      carousel_action: view,
      vehicle_model: vehicle.series,
      vehicle_exterior_color: vehicle.color.exterior.name,
      vehicle_year: vehicle.year,
      vehicle_interior_color: vehicle.color.interior.name,
      vehicle_trim: vehicle.grade,
      vehicle_trim_id: vehicle.modelNumber,
      vehicle_vin: vehicle.vin,
      allocated_dealer_name: firstLettersCapital(vehicle.dealerName),
      allocated_dealer_code: vehicle.dealer,
      vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
      vehicle_model_truncated: vehicle.series,
      vehicle_segment_vehicle_page: segment,
      has_port_photos: hasPortPhotos,
    })
  }
  return {
    inventoryCarouselTealClick,
    handleClick,
    addAnalyticIds,
  }
}

export default useCustomCarouselEvent
