import { Disclaimer } from "./disclaimers.d"

export enum ACTION_TYPES {
  SET_DISCLAIMERS,
  TOGGLE_MODAL,
  SET_ACTIVE_DISCLAIMER,
  SET_OPEN_ON_LOAD,
  SET_ACTIVE_DISCLAIMER_POPOVER_ID,
}

export const toggleDisclaimersModal = (highlightedDisclaimer?: string) => ({
  type: ACTION_TYPES.TOGGLE_MODAL,
  payload: highlightedDisclaimer,
})

export const setActiveDisclaimer = (code: string) => ({
  type: ACTION_TYPES.SET_ACTIVE_DISCLAIMER,
  payload: code,
})

export const setDisclaimers = (disclaimers: Disclaimer[]) => ({
  type: ACTION_TYPES.SET_DISCLAIMERS,
  payload: disclaimers,
})

export const setOpenOnLoad = (codes: string[]) => ({
  type: ACTION_TYPES.SET_OPEN_ON_LOAD,
  payload: codes,
})

export const setActiveDisclaimerPopover = (componentId: number) => ({
  type: ACTION_TYPES.SET_ACTIVE_DISCLAIMER_POPOVER_ID,
  payload: componentId,
})
