import React from "react"
import tw from "twin.macro"
import { LogoProps } from "./Logo.d"

/**
 * Toyoguard Logo
 *
 * @param {"platinum" | "elite" | "plus" | "select"} props.plan - plan dictates text color, subtext, and presence of registered trademark icon. Defaults to no plan.
 * @returns <div />
 */

const Toyoguard: React.FC<LogoProps> = ({ plan = null }) => {
  return (
    <div>
      <svg
        width="40"
        height="46"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        css={[tw`inline-block align-top`]}
      >
        <g fill-rule="nonzero" fill="none">
          <path
            d="M27.03 60.828a46.044 46.044 0 0 1-16.138-11.095 39.105 39.105 0 0 1-9.177-15.788 47.09 47.09 0 0 1-.218-24.372l.276-1.162h2.189A37.477 37.477 0 0 0 26.624.732L27.595 0l.899.732a37.458 37.458 0 0 0 22.516 7.78h2.19l.29 1.162a47.245 47.245 0 0 1-.16 24.3 39.434 39.434 0 0 1-9.177 15.788 46.044 46.044 0 0 1-16.138 11.094l-.42.144-.493-.172"
            fill="#010101"
          />
          <path
            d="M53.482 10.112C44.47 10.304 35.666 7.438 28.565 2 21.424 7.468 12.563 10.336 3.5 10.112-5.45 47.802 28.565 60 28.565 60s33.91-12.198 24.917-49.888"
            fill="#010101"
          />
          <path
            d="M51.805 11.814A39.997 39.997 0 0 1 28.58 4a40.04 40.04 0 0 1-23.383 7.814 45.186 45.186 0 0 0 .317 21.93 37.001 37.001 0 0 0 8.462 14.886A44.044 44.044 0 0 0 28.58 59a44.353 44.353 0 0 0 14.417-10.37 37 37 0 0 0 8.476-14.886 45.025 45.025 0 0 0 .332-21.93m-3.503 21a33.946 33.946 0 0 1-7.684 13.55 41.513 41.513 0 0 1-12.038 8.99 41.51 41.51 0 0 1-12.11-8.99 33.772 33.772 0 0 1-7.683-13.55 40.505 40.505 0 0 1-.822-17.79A43.81 43.81 0 0 0 28.58 8.08a43.71 43.71 0 0 0 20.471 6.942 40.71 40.71 0 0 1-.75 17.791"
            fill="#686969"
          />
          <path
            d="M51.804 11.799A39.838 39.838 0 0 1 28.58 4a39.909 39.909 0 0 1-23.383 7.799 45.23 45.23 0 0 0 .318 21.945 37.001 37.001 0 0 0 8.462 14.886A44.043 44.043 0 0 0 28.58 59a44.352 44.352 0 0 0 14.416-10.37 37 37 0 0 0 8.477-14.886 45.07 45.07 0 0 0 .331-21.945M48.23 33.032a32.092 32.092 0 0 1-2.104 5.345l-.433.842-.447.813c-.144.247-.288.509-.447.756v.101l-.447.727-.1.13c-.145.233-.289.45-.447.668l-.13.19-.433.61-.187.246-.404.523-.26.334-.36.45-.475.596-.289.334-.663.74a41.235 41.235 0 0 1-12.023 8.918 41.236 41.236 0 0 1-12.11-8.99l-.662-.741-.36-.261-.361-.422a5.454 5.454 0 0 0-.36-.45l-.26-.334-.39-.523-.187-.247c-.158-.203-.288-.406-.432-.61l-.13-.32a10.74 10.74 0 0 1-.447-.667l-.158-.073-.39-.711v-.102a13.14 13.14 0 0 1-.461-.755l-.591-.886-.447-.843c-.1-.217-.202-.435-.317-.653-.115-.218-.188-.421-.288-.64a31.714 31.714 0 0 1-1.442-4.051h39.442m1.442-9.586v1.002c.014.257.014.514 0 .77v1.017l-.087.813c0 .276-.072.538-.1.799v.116c0 .262-.073.523-.116.784v.102l-.13.784H7.98c0-.261-.101-.523-.144-.784v-.087c0-.262-.087-.523-.116-.784v-.087c0-.262 0-.523-.1-.8 0-.275 0-.537-.087-.813v-8.467l.086-.798v-.146c0-.276 0-.566.116-.842v-.174l.13-.872A43.807 43.807 0 0 0 28.58 8.081a43.709 43.709 0 0 0 20.471 6.942l.13.872v.174c0 .276 0 .566.1.842v.146l.087.798v.102c0 .276 0 .552.072.842v4.575"
            fill="#E6E6E5"
          />
          <path
            fill="#056937"
            d="m12.23 42.48-.54-.98.62 1M13.3 43.42c-.22-.28-.4-.56-.6-.84.2.28.38.56.6.84M40.2 44.77l-.4.46.4-.46zM41.25 44.69l-.5.62.5-.62M44.31 41.5l-.62 1 .62-1M42.28 43.63l-.56.74.56-.74M13.28 44.37l-.56-.74.56.74M44.32 39.48l-.64 1.04.64-1.04M43.3 42.58l-.6.84.6-.84M14.2 45.18l-.4-.36.4.36zM45.3 37.42l-.6 1.16.6-1.16M49 18.43v1.14-1.14M49 20.44v1.12-1.12M49 19.45v1.1-1.1M49 20.42v1.16-1.16M48.95 17.42c0 .38 0 .76.1 1.16 0-.4 0-.78-.1-1.16M49 21.43v1.14-1.14M48.93 15.42c0 .4 0 .78.14 1.16 0-.38-.1-.76-.14-1.16M48.94 16.45l.12 1.1-.12-1.1M47.91 15.4l.18 1.2-.18-1.2M6 20v2-2M49.08 27.46c0 .36-.1.72-.16 1.08 0-.36.1-.72.16-1.08M47.91 28.54l.18-1.08-.18 1.08M49.07 26.45c0 .38-.1.74-.14 1.1 0-.36 0-.72.14-1.1M49.06 25.44l-.12 1.12.12-1.12M11.32 40.52l-.64-1.04.64 1.04M49 25.45v1.1-1.1M49 22.44v1.12-1.12M7.1 28.54c0-.36-.14-.72-.2-1.08 0 .36.12.72.2 1.08M6 26.56v-1.12 1.12M6.08 28.54c0-.36-.12-.72-.16-1.08 0 .36.1.72.16 1.08M6.06 26.56l-.12-1.12.12 1.12M6.07 27.55c0-.36 0-.72-.14-1.1 0 .38 0 .74.14 1.1M11.35 39.56l-.7-1.12.62 1.12M10.31 38.58c-.22-.38-.42-.78-.62-1.16.2.38.4.78.62 1.16M6 23.56v-1.12 1.12M6 24.53v-1.06 1.06M5.94 17.55l.12-1.1-.12 1.1M6 19.57v-1.14 1.14M5.92 16.58c0-.38 0-.76.16-1.16 0 .4 0 .78-.16 1.16M6 18.58v-1.16 1.16M6.91 16.6l.18-1.2-.18 1.2M6 20.55v-1.1 1.1M6 21.56v-1.12 1.12"
          />
          <path
            d="M48.97 21.716v-3.844c0-.297 0-.58-.088-.863v-.104l-.104-.82v-.149c0-.283-.087-.566-.122-.864v-.178L48.499 14 47 15.356c0 .253 0 .506.122.76v.148l.105.79v8.311c0 .298 0 .552-.105.79 0 .253 0 .506-.105.79V27.51L48.464 29l.157-.804v-.105c0-.268.087-.536.14-.804v-.12c0-.267 0-.535.121-.818l.105-.835v-1.043a5.998 5.998 0 0 0 0-.789v-1.936"
            fill="#FBFCFC"
          />
          <path
            d="M27 9.645V8a42.563 42.563 0 0 1-20 6.72L8.203 16A44.193 44.193 0 0 0 27 9.645M47.789 16 49 14.716A42.865 42.865 0 0 1 29 8v1.65A44.539 44.539 0 0 0 47.789 16"
            fill="#B4B4B4"
          />
          <path fill="#FBFCFC" d="M9.195 27.02 8 28.98h41l-1.195-1.96z" />
          <path
            d="M8.807 27.105V27c0-.281-.088-.548-.123-.785V17.036c.018-.264.053-.526.105-.785v-.148c0-.252 0-.504.123-.756L7.404 14l-.158.888v.178c0 .296 0 .578-.14.859v.148L7 16.887v8.648l.105.83c0 .28 0 .547.123.814v.118c0 .267.088.533.14.8v.103c0 .267.106.533.176.8L9 27.52v-.46"
            fill="#B4B4B4"
          />
          <path fill="#DE6249" d="M47 25.99v.02-.02" />
          <path
            d="M27.645 10A46.711 46.711 0 0 1 8.18 16.358l-.087.565v.297a6.981 6.981 0 0 0-.087.749v1.455c.007.127.007.254 0 .381v4.479a.565.565 0 0 1 0 .156V25.965a1.695 1.695 0 0 0 0 .283v.466c0 .212 0 .424.087.664a.18.18 0 0 1 0 .085V28h38.902v-.24a.937.937 0 0 1 0-.198V25.033a.675.675 0 0 0 0-.17 2.44 2.44 0 0 0 0-.325c.007-.08.007-.16 0-.24V19.367a3.377 3.377 0 0 0 0-.381V17.94c.006-.075.006-.15 0-.226a.565.565 0 0 0 0-.155v-.495c0-.24 0-.48-.102-.72a47.071 47.071 0 0 1-18.553-5.878L27.645 10"
            fill="#642C23"
          />
          <path
            d="M47 27.735V28v-.265m.27-1.17v.171-.172m.18-.89v.079c0 .296 0 .562-.18.811 0-.25 0-.515.18-.811v-.078m.18-1.155a.402.402 0 0 0 0 0m0-.952v.328a.816.816 0 0 1 0 .265v-.593m0-4.291v1.436-1.467M47.21 16.718a.815.815 0 0 0 0 0M47.06 16v.546V16"
            fill="#E79178"
          />
          <path
            d="M8.24 25.505V26v-.495M8 24.199v.721-.721m0-.615v.615-.615m0-4.412v.42-.42m0-.855a1.843 1.843 0 0 0 0 0m.21-1.201v.6-.6m.27-1.216v.075a3.802 3.802 0 0 1 0 0V15.9m.3-.9-.18.6.18-.6"
            fill="#C97762"
          />
          <path
            d="M9.187 16.363c0 .24 0 .48-.1.721v.141a6.634 6.634 0 0 0-.087.75v8.739c0 .24 0 .48.1.749V28h38.727v-.538c0-.268 0-.508.086-.749 0-.226 0-.466.087-.75V18.018l-.087-.75v-.14c0-.24 0-.481-.1-.722A46.324 46.324 0 0 1 28.565 10a46.33 46.33 0 0 1-19.378 6.363"
            fill="#D72027"
          />
          <path
            d="M51.878 12 53 10.492C44.317 10.687 35.838 7.687 29 2v1.885C35.646 9.052 43.643 11.888 51.878 12"
            fill="#B4B4B4"
          />
          <path
            d="M28 3.888V2c-6.838 5.722-15.322 8.723-24 8.49L5.101 12c8.24-.067 16.25-2.905 22.899-8.112"
            fill="#FBFCFC"
          />
          <path
            d="m53.507 11-1.145 1.447a44.474 44.474 0 0 1-.334 21.86 36.77 36.77 0 0 1-8.527 14.83A44.604 44.604 0 0 1 29 59.467V61s33.353-12.225 24.507-50"
            fill="#B4B4B4"
          />
          <path
            d="M13.382 49.137a36.728 36.728 0 0 1-8.47-14.83 44.802 44.802 0 0 1-.318-21.846L3.468 11C-5.29 48.775 28 61 28 61v-1.534a44.085 44.085 0 0 1-14.618-10.33"
            fill="#FBFCFC"
          />
          <path fill="#B4B4B4" d="M45.061 33.98 47 32.02H8l1.939 1.96z" />
          <path
            d="M45.939 33.403a31.947 31.947 0 0 1-6.784 10.75A40.382 40.382 0 0 1 28 52.425V54a42.035 42.035 0 0 0 12.28-8.86A32.99 32.99 0 0 0 48 32l-2.061 1.403z"
            fill="#FBFCFC"
          />
          <path
            d="M16.74 44.178a31.44 31.44 0 0 1-6.759-10.75L8 32a32.684 32.684 0 0 0 7.691 13.106A41.606 41.606 0 0 0 28 54v-1.57a40.077 40.077 0 0 1-11.26-8.252"
            fill="#B4B4B4"
          />
          <path
            d="M16.78 44.75A40.191 40.191 0 0 0 28.072 53a39.94 39.94 0 0 0 11.133-8.25A31.695 31.695 0 0 0 46 34H10a31.423 31.423 0 0 0 6.78 10.75"
            fill="#3C3C3F"
          />
        </g>
      </svg>
      <div css={[tw`inline-block`]}>
        <p
          css={[
            tw`inline-block font-semibold text-3xl text-white`,
            plan === "platinum" && tw`text-black`,
          ]}
        >
          TOYOGUARD
        </p>
        {plan && (
          <span css={[tw`block text-center -mt-2`]}>
            <hr
              css={[
                tw`inline-block w-4 border-white mb-1.5 mr-2`,
                plan === "platinum" && tw`border-red-300`,
              ]}
            />
            <p
              css={[
                tw`inline text-white text-lg font-semibold capitalize`,
                plan === "platinum" && tw`text-red-300`,
              ]}
            >
              {plan}
            </p>
            <hr
              css={[
                tw`inline-block w-4 border-white mb-1.5 ml-2`,
                plan === "platinum" && tw`border-red-300`,
              ]}
            />
          </span>
        )}
      </div>
    </div>
  )
}

export default Toyoguard
