import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Success icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Success = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-1 -1 26 26" {...remainingProps}>
      <g fill="none" fill-rule="evenodd">
        <circle
          stroke={colorString}
          stroke-width="2"
          fill={colorString}
          fill-rule="nonzero"
          cx="12"
          cy="12"
          r="12"
        />
        <path
          stroke="#FFF"
          stroke-width="2.5"
          stroke-linejoin="round"
          d="m6 11.2 4.421 4.8L18 8"
        />
      </g>
    </svg>
  )
}

export default Success
