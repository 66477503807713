import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Performance icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Performance = ({ ...remainingProps }: IconProps): JSX.Element => {
  return (
    <svg {...remainingProps} fill="none" viewBox="0 0 72 52">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.3451 36.0725L33.8408 40.3899L34.4821 28.0308L69.3451 36.0725Z"
        fill="white"
        stroke="black"
        stroke-width="3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.807 46.0282C29.5788 46.0282 24.5098 40.9089 24.5098 34.6146C24.5098 28.3246 29.5788 23.2053 35.807 23.2053C42.0396 23.2053 47.1086 28.3246 47.1086 34.6146C47.1086 40.9089 42.0396 46.0282 35.807 46.0282Z"
        fill="white"
      />
      <path
        d="M36.3828 46.0282C30.4724 46.0282 25.6621 41.1702 25.6621 35.1971C25.6621 29.228 30.4724 24.37 36.3828 24.37C42.2973 24.37 47.1076 29.228 47.1076 35.1971C47.1076 41.1702 42.2973 46.0282 36.3828 46.0282Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.2275 23.2053C29.7549 23.2053 24.5098 28.5024 24.5098 35.0392C24.5098 41.5759 29.7549 46.8768 36.2275 46.8768C42.7 46.8768 47.9451 41.5759 47.9451 35.0392C47.9451 28.5024 42.7 23.2053 36.2275 23.2053ZM36.2275 26.9728C40.6332 26.9728 44.2146 30.5934 44.2146 35.0392C44.2146 39.4887 40.6332 43.1093 36.2275 43.1093C31.8217 43.1093 28.2403 39.4887 28.2403 35.0392C28.2403 30.5934 31.8217 26.9728 36.2275 26.9728Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5358 9.85811L15.5129 6.31866C15.1482 6.57453 14.7758 6.83815 14.4035 7.12115C13.962 7.44291 13.5897 7.74142 13.2174 8.05544C13.225 8.05931 13.1252 8.15235 13.0177 8.24152L12.0926 9.02849C10.9525 10.083 9.96985 11.0715 9.06393 12.0717C8.74532 12.4323 8.52652 12.6804 8.31923 12.9285C8.1964 13.1029 8.01214 13.3239 7.81253 13.5332C7.67434 13.7077 7.37877 14.0837 7.10238 14.4753C6.78378 14.894 6.51891 15.2778 6.26172 15.6538L9.84702 17.7395C11.9352 14.6808 14.5455 12.0213 17.5358 9.85811Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.38198 20.6819C3.13631 21.1936 2.93286 21.6588 2.73325 22.1395C2.55284 22.5543 2.39929 22.942 2.2611 23.3336C2.09988 23.7522 2.01543 23.9693 1.94633 24.2019C1.52408 25.4386 1.20931 26.5086 0.971313 27.5049C0.871508 27.9081 0.779381 28.2958 0.698769 28.6718L0.645028 28.9199C0.556739 29.3308 0.479966 29.765 0.418548 30.1992C0.322582 30.6838 0.280357 30.9978 0.24197 31.2963C0.15752 31.905 0.103779 32.4012 0.0538763 32.9052C0.0385217 33.0796 0.0231671 33.2541 0.0078125 33.4324H4.35316C4.64106 29.6681 5.56618 26.0938 7.0287 22.8063L3.38198 20.6819Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 39.2588C0.0153546 39.4255 0.0268705 39.5961 0.0460638 39.7666L0.0921276 40.1814C0.130514 40.5459 0.168901 40.9025 0.218803 41.2553L0.253351 41.4995C0.287899 41.7632 0.326285 42.0151 0.380026 42.2671C0.479831 42.899 0.556604 43.341 0.65257 43.7829L0.959662 45.132C1.20917 46.171 1.52394 47.2332 1.91932 48.4001C2.01913 48.718 2.11126 48.9583 2.20338 49.1987C2.39916 49.7337 2.55654 50.1252 2.7216 50.509C2.9404 51.0363 3.14769 51.517 3.38185 51.9977L7.33182 49.6988C5.81171 46.4734 4.77528 42.9688 4.39909 39.2588H0Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.1215 0.00115967C32.9487 0.0166665 32.7722 0.0321734 32.5994 0.0476803C32.0006 0.109708 31.5591 0.152352 31.1292 0.214379L30.8759 0.24927C30.6072 0.291914 30.3653 0.322927 30.1273 0.377202C29.5055 0.477996 29.0679 0.555531 28.6264 0.648572C28.1159 0.760997 27.6975 0.854038 27.2982 0.95871C26.2695 1.2107 25.2177 1.52859 24.0584 1.93177C23.7705 2.02481 23.5556 2.10622 23.3483 2.18763L22.2581 2.61407C22.1737 2.64896 22.0892 2.68773 22.0047 2.73037C21.452 2.96297 20.9722 3.17232 20.5 3.4088L22.5038 6.92111C25.782 5.36266 29.3481 4.33921 33.1215 3.99418V0.00115967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.5042 3.40764C50.9937 3.15565 50.5369 2.95794 50.0647 2.75635C49.6463 2.57027 49.2663 2.4152 48.8824 2.27563C48.4679 2.11669 48.2529 2.0314 48.0226 1.95774C46.798 1.53518 45.7386 1.21341 44.7482 0.96918C44.3029 0.856755 43.8883 0.763714 43.4814 0.670673C42.9479 0.554371 42.5141 0.476837 42.0842 0.414809C41.6235 0.321768 41.328 0.279124 41.0439 0.244233C40.4528 0.158946 39.9998 0.116302 39.5583 0.069781C39.3088 0.0387672 39.0862 0.0193836 38.8789 0V3.9969C42.6523 4.33805 46.2222 5.3615 49.5005 6.91995L51.5042 3.40764Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.6719 49.6972L68.6295 52C68.879 51.4844 69.0786 51.0192 69.2783 50.5462C69.4587 50.1275 69.6084 49.736 69.7504 49.3483C69.8771 49.0149 69.9769 48.7513 70.069 48.4722C70.4874 47.2432 70.8022 46.1733 71.0402 45.177L71.3626 43.7697C71.4548 43.3471 71.5277 42.9091 71.593 42.4749C71.6237 42.3314 71.6467 42.1996 71.6659 42.0795L71.7734 41.2692C71.8425 40.773 71.8962 40.2807 71.9461 39.7728C71.9653 39.6022 71.9807 39.4239 71.9922 39.2495H67.6046C67.2284 42.9633 66.1958 46.4679 64.6719 49.6972Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.9993 33.4332C71.9878 33.2588 71.9724 33.0882 71.9532 32.9138L71.9071 32.499C71.8688 32.1345 71.8304 31.7779 71.7805 31.4251C71.719 30.9715 71.6807 30.6924 71.6192 30.4133C71.5194 29.7775 71.4427 29.3394 71.3505 28.8975L71.0396 27.5445C70.7939 26.5133 70.4792 25.4511 70.0799 24.2803C69.9917 23.9973 69.9034 23.7725 69.8228 23.5515C69.6001 22.9506 69.4466 22.5629 69.2777 22.1752C69.0589 21.6441 68.8477 21.1634 68.6174 20.6866L64.9707 22.811C66.4332 26.0946 67.3583 29.6728 67.6462 33.4332H71.9993Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.1056 17.6681L65.5719 15.6522C65.3032 15.2607 65.046 14.8924 64.7466 14.497C64.4548 14.0822 64.1592 13.7061 63.9558 13.458C63.8214 13.3262 63.641 13.1091 63.4683 12.8765C63.2994 12.6749 63.0767 12.4229 62.8695 12.1903C61.8599 11.0738 60.8772 10.0814 59.8523 9.12773L58.835 8.25934C58.7122 8.15855 58.6162 8.06938 58.5279 7.97247C58.24 7.73986 57.8715 7.44136 57.4493 7.1351C57.0577 6.84046 56.6854 6.57297 56.3207 6.32098L54.3477 9.76739C57.3648 11.9306 59.9943 14.5978 62.1056 17.6681Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.3451 36.0725L33.8408 40.3899L34.4821 28.0308L69.3451 36.0725Z"
        fill="white"
        stroke="black"
        stroke-width="3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.807 46.0282C29.5788 46.0282 24.5098 40.9089 24.5098 34.6146C24.5098 28.3246 29.5788 23.2053 35.807 23.2053C42.0396 23.2053 47.1086 28.3246 47.1086 34.6146C47.1086 40.9089 42.0396 46.0282 35.807 46.0282Z"
        fill="white"
      />
      <path
        d="M36.3828 46.0282C30.4724 46.0282 25.6621 41.1702 25.6621 35.1971C25.6621 29.228 30.4724 24.37 36.3828 24.37C42.2973 24.37 47.1076 29.228 47.1076 35.1971C47.1076 41.1702 42.2973 46.0282 36.3828 46.0282Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.2275 23.2053C29.7549 23.2053 24.5098 28.5024 24.5098 35.0392C24.5098 41.5759 29.7549 46.8768 36.2275 46.8768C42.7 46.8768 47.9451 41.5759 47.9451 35.0392C47.9451 28.5024 42.7 23.2053 36.2275 23.2053ZM36.2275 26.9728C40.6332 26.9728 44.2146 30.5934 44.2146 35.0392C44.2146 39.4887 40.6332 43.1093 36.2275 43.1093C31.8217 43.1093 28.2403 39.4887 28.2403 35.0392C28.2403 30.5934 31.8217 26.9728 36.2275 26.9728Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5358 9.85811L15.5129 6.31866C15.1482 6.57453 14.7758 6.83815 14.4035 7.12115C13.962 7.44291 13.5897 7.74142 13.2174 8.05544C13.225 8.05931 13.1252 8.15235 13.0177 8.24152L12.0926 9.02849C10.9525 10.083 9.96985 11.0715 9.06393 12.0717C8.74532 12.4323 8.52652 12.6804 8.31923 12.9285C8.1964 13.1029 8.01214 13.3239 7.81253 13.5332C7.67434 13.7077 7.37877 14.0837 7.10238 14.4753C6.78378 14.894 6.51891 15.2778 6.26172 15.6538L9.84702 17.7395C11.9352 14.6808 14.5455 12.0213 17.5358 9.85811Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.38198 20.6819C3.13631 21.1936 2.93286 21.6588 2.73325 22.1395C2.55284 22.5543 2.39929 22.942 2.2611 23.3336C2.09988 23.7522 2.01543 23.9693 1.94633 24.2019C1.52408 25.4386 1.20931 26.5086 0.971313 27.5049C0.871508 27.9081 0.779381 28.2958 0.698769 28.6718L0.645028 28.9199C0.556739 29.3308 0.479966 29.765 0.418548 30.1992C0.322582 30.6838 0.280357 30.9978 0.24197 31.2963C0.15752 31.905 0.103779 32.4012 0.0538763 32.9052C0.0385217 33.0796 0.0231671 33.2541 0.0078125 33.4324H4.35316C4.64106 29.6681 5.56618 26.0938 7.0287 22.8063L3.38198 20.6819Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 39.2588C0.0153546 39.4255 0.0268705 39.5961 0.0460638 39.7666L0.0921276 40.1814C0.130514 40.5459 0.168901 40.9025 0.218803 41.2553L0.253351 41.4995C0.287899 41.7632 0.326285 42.0151 0.380026 42.2671C0.479831 42.899 0.556604 43.341 0.65257 43.7829L0.959662 45.132C1.20917 46.171 1.52394 47.2332 1.91932 48.4001C2.01913 48.718 2.11126 48.9583 2.20338 49.1987C2.39916 49.7337 2.55654 50.1252 2.7216 50.509C2.9404 51.0363 3.14769 51.517 3.38185 51.9977L7.33182 49.6988C5.81171 46.4734 4.77528 42.9688 4.39909 39.2588H0Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.1215 0.00115967C32.9487 0.0166665 32.7722 0.0321734 32.5994 0.0476803C32.0006 0.109708 31.5591 0.152352 31.1292 0.214379L30.8759 0.24927C30.6072 0.291914 30.3653 0.322927 30.1273 0.377202C29.5055 0.477996 29.0679 0.555531 28.6264 0.648572C28.1159 0.760997 27.6975 0.854038 27.2982 0.95871C26.2695 1.2107 25.2177 1.52859 24.0584 1.93177C23.7705 2.02481 23.5556 2.10622 23.3483 2.18763L22.2581 2.61407C22.1737 2.64896 22.0892 2.68773 22.0047 2.73037C21.452 2.96297 20.9722 3.17232 20.5 3.4088L22.5038 6.92111C25.782 5.36266 29.3481 4.33921 33.1215 3.99418V0.00115967Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M51.5042 3.40764C50.9937 3.15565 50.5369 2.95794 50.0647 2.75635C49.6463 2.57027 49.2663 2.4152 48.8824 2.27563C48.4679 2.11669 48.2529 2.0314 48.0226 1.95774C46.798 1.53518 45.7386 1.21341 44.7482 0.96918C44.3029 0.856755 43.8883 0.763714 43.4814 0.670673C42.9479 0.554371 42.5141 0.476837 42.0842 0.414809C41.6235 0.321768 41.328 0.279124 41.0439 0.244233C40.4528 0.158946 39.9998 0.116302 39.5583 0.069781C39.3088 0.0387672 39.0862 0.0193836 38.8789 0V3.9969C42.6523 4.33805 46.2222 5.3615 49.5005 6.91995L51.5042 3.40764Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.6719 49.6972L68.6295 52C68.879 51.4844 69.0786 51.0192 69.2783 50.5462C69.4587 50.1275 69.6084 49.736 69.7504 49.3483C69.8771 49.0149 69.9769 48.7513 70.069 48.4722C70.4874 47.2432 70.8022 46.1733 71.0402 45.177L71.3626 43.7697C71.4548 43.3471 71.5277 42.9091 71.593 42.4749C71.6237 42.3314 71.6467 42.1996 71.6659 42.0795L71.7734 41.2692C71.8425 40.773 71.8962 40.2807 71.9461 39.7728C71.9653 39.6022 71.9807 39.4239 71.9922 39.2495H67.6046C67.2284 42.9633 66.1958 46.4679 64.6719 49.6972Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.9993 33.4332C71.9878 33.2588 71.9724 33.0882 71.9532 32.9138L71.9071 32.499C71.8688 32.1345 71.8304 31.7779 71.7805 31.4251C71.719 30.9715 71.6807 30.6924 71.6192 30.4133C71.5194 29.7775 71.4427 29.3394 71.3505 28.8975L71.0396 27.5445C70.7939 26.5133 70.4792 25.4511 70.0799 24.2803C69.9917 23.9973 69.9034 23.7725 69.8228 23.5515C69.6001 22.9506 69.4466 22.5629 69.2777 22.1752C69.0589 21.6441 68.8477 21.1634 68.6174 20.6866L64.9707 22.811C66.4332 26.0946 67.3583 29.6728 67.6462 33.4332H71.9993Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.1056 17.6681L65.5719 15.6522C65.3032 15.2607 65.046 14.8924 64.7466 14.497C64.4548 14.0822 64.1592 13.7061 63.9558 13.458C63.8214 13.3262 63.641 13.1091 63.4683 12.8765C63.2994 12.6749 63.0767 12.4229 62.8695 12.1903C61.8599 11.0738 60.8772 10.0814 59.8523 9.12773L58.835 8.25934C58.7122 8.15855 58.6162 8.06938 58.5279 7.97247C58.24 7.73986 57.8715 7.44136 57.4493 7.1351C57.0577 6.84046 56.6854 6.57297 56.3207 6.32098L54.3477 9.76739C57.3648 11.9306 59.9943 14.5978 62.1056 17.6681Z"
        fill="black"
      />
    </svg>
  )
}

export default Performance
