import React from "react"
import tw from "twin.macro"
import { LabelProps } from "./Label.d"

const Label: React.FC<LabelProps> = ({ children, ...remainingProps }) => {
  return (
    <label css={[tw``]} {...remainingProps}>
      {children}
    </label>
  )
}

export default Label
