import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Standard icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Standard = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg fill="none" viewBox="0 0 17 17" {...remainingProps}>
      <circle
        cx="8.5"
        cy="8.5"
        r="7.5"
        fill={colorString}
        stroke={colorString}
        strokeWidth="2"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.478 6.208a.753.753 0 0 1 1.04 0 .695.695 0 0 1 0 1.006l-4.41 4.266a.753.753 0 0 1-1.04 0L4.215 9.687a.695.695 0 0 1 0-1.005.753.753 0 0 1 1.04 0l1.333 1.29 3.89-3.764Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Standard
