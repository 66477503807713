import React from "react"
import { ChatBubbleButtonProps } from "./StickyFooter.d"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const ChatBubbleButton: React.FC<ChatBubbleButtonProps> = ({
  toggle,
  setToggle,
}) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <button
      onClick={() => {
        !toggle.chatBubble &&
          trackTealEvent({
            tealium_event: "chat_text_open",
            click_text: "chat bubble:open",
          })

        toggle.chatBubble &&
          trackTealEvent({
            tealium_event: "chat_text_open",
            click_text: "chat bubble:close",
          })
        // If other menu is open when this menu opens, close other menu
        if (toggle.jumpTo && !toggle.chatBubble)
          setToggle({
            ...toggle,
            chatBubble: true,
            jumpTo: false,
          })
        else setToggle({ ...toggle, chatBubble: !toggle.chatBubble })
      }}
      css={[
        tw`relative p-[10px] rounded-full transition-all duration-500 z-40 shadow-none`,
        toggle.chatBubble
          ? tw`bg-white shadow-none`
          : tw`bg-red-400 shadow-3 hover:(shadow-4)`,
      ]}
      analytics-id={
        toggle.chatBubble ? "close:chat bubble:" : "open:chat bubble:"
      }
    >
      <Icon.ChatBubble
        color="white"
        filled={true}
        css={[
          tw`h-7 z-40 transition-all duration-500`,
          "filter: drop-shadow(0px 6px 6px rgb(10, 22, 70, 0.1)) drop-shadow(0px 0px 1px rgb(10, 22, 70, 0.06));",
          toggle.chatBubble ? tw`opacity-0` : tw`opacity-100`,
        ]}
      />
      <Icon.X
        color="red-400"
        css={[
          tw`absolute top-3 left-3 transition-all duration-300 h-6`,
          toggle.chatBubble
            ? tw`-rotate-90 opacity-100`
            : tw`rotate-90 opacity-0`,
        ]}
      />
    </button>
  )
}

export default ChatBubbleButton
