import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * X icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const MatchFull: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 14 14" {...remainingProps}>
      <circle cx="7" cy="7" r="7" fill="black" />
    </svg>
  )
}

export default MatchFull
