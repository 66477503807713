import React from "react"
import tw from "twin.macro"
import { Chevron } from "../Icon"
import { CarouselNavigationProps } from "./Carousel.d"

const CarouselNavigation: React.FC<CarouselNavigationProps> = ({
  active,
  direction,
  color,
  ...remainingProps
}) => {
  return (
    <button
      css={[
        tw`bg-white h-10 w-16 flex justify-center items-center rounded-full transform transition-all duration-150 ease-out`,
        tw`md:(hover:(bg-gray-400 shadow-3) active:(scale-98))`,
        tw`focus-visible:(border-white outline-[dashed] outline-offset-[2px] outline-white p-1)`,
      ]}
      {...remainingProps}
    >
      <Chevron
        direction={direction === "prev" ? "left" : "right"}
        css={[tw`h-5 md:(h-5)`]}
        color={color}
      />
    </button>
  )
}

export default CarouselNavigation
