import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Clock icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Clock: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <g fill={colorString} fill-rule="evenodd">
        <path d="M67.377 38.281c-1.122 15.487-13.601 27.97-29.094 29.092v-7.165h-4.566v7.165C18.224 66.25 5.745 53.768 4.623 38.28h8.37v-4.566h-8.37C5.745 18.228 18.224 5.745 33.717 4.627v5.96h4.566v-5.96c15.493 1.118 27.972 13.6 29.094 29.088h-8.37v4.566h8.37ZM36 0C16.148 0 0 16.152 0 35.998 0 55.848 16.148 72 36 72s36-16.152 36-36.002C72 16.152 55.852 0 36 0Z" />
        <path d="M38.282 15.211h-4.567v23.068H49v-4.814H38.282" />
      </g>
    </svg>
  )
}

export default Clock
