import { useTealiumContext } from "../../contexts/Tealium"
import initTealiumTracker from "tealium-tracker"
import { TealEventData } from "./Tealium"

/**
 *
 * @author Stu
 * @summary This wraps the event functionality of the tealium-tracker module.
 *
 * @todo Not all data from page load should be included. Additional logic is required and will be added iteratively.
 *
 */

const isBrowser = typeof window !== "undefined"

const useTealiumEvent = () => {
  const { tealPageData } = useTealiumContext()

  // Module is disabled in gatsby-node.js due to a reference to the document object, which is not compatible with SSR builds.
  // Below checks if the code is running in a broswer before calling the initTealiumTracker function, since module is not available during build time.
  if (isBrowser) {
    const { trackEvent } = initTealiumTracker()

    // tealEventData must be spread after tealPageData second to overwrite some values in context if needed
    const trackTealEvent = (tealEventData: TealEventData) => {
      trackEvent({ ...tealPageData, ...tealEventData })
    }
    return { trackTealEvent }
  } else {
    // A default function in the case that the browser does not exist
    const trackTealEvent = () => console.log("Default track event message")
    return { trackTealEvent }
  }
}

export default useTealiumEvent
