import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Checkmark icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Checkmark: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 8.9 7.1" {...remainingProps}>
      <path
        d="M7.445.992a.809.809 0 0 1 1.13 0 .778.778 0 0 1 0 1.112L3.78 6.824a.809.809 0 0 1-1.13 0L.633 4.84a.778.778 0 0 1 0-1.112.809.809 0 0 1 1.13 0l1.45 1.427L7.446.992Z"
        fill={colorString}
        fill-rule="evenodd"
      />
    </svg>
  )
}

export default Checkmark
