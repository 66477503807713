import React, { useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Specifications icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */

const Specifications: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 74 74" fill="none" {...remainingProps}>
      <path
        d="M37 1C17.1229 1 1 17.1229 1 37C1 56.8771 17.1229 73 37 73C56.8771 73 73 56.8771 73 37C72.9743 17.1229 56.8771 1.02571 37 1ZM37 70.4286C18.5371 70.4286 3.57143 55.4629 3.57143 37C3.57143 18.5371 18.5371 3.57143 37 3.57143C55.4629 3.57143 70.4286 18.5371 70.4286 37C70.4029 55.45 55.45 70.4029 37 70.4286Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M19.6431 30.5972C16.1074 30.5972 13.2402 33.4643 13.2402 37C13.2402 40.5357 16.1074 43.4029 19.6431 43.4029C23.1788 43.4029 26.0459 40.5357 26.0459 37C26.0459 33.4643 23.1788 30.5972 19.6431 30.5972ZM19.6431 40.8315C17.5345 40.8315 15.8117 39.1215 15.8117 37C15.8117 34.8915 17.5217 33.1686 19.6431 33.1686C21.7517 33.1686 23.4745 34.8786 23.4745 37C23.4745 39.1086 21.7645 40.8315 19.6431 40.8315Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M37.0005 30.5972C33.4648 30.5972 30.5977 33.4643 30.5977 37C30.5977 40.5357 33.4648 43.4029 37.0005 43.4029C40.5362 43.4029 43.4034 40.5357 43.4034 37C43.4034 33.4643 40.5362 30.5972 37.0005 30.5972ZM37.0005 40.8315C34.8919 40.8315 33.1691 39.1215 33.1691 37C33.1691 34.8915 34.8791 33.1686 37.0005 33.1686C39.1091 33.1686 40.8319 34.8786 40.8319 37C40.8319 39.1086 39.1219 40.8315 37.0005 40.8315Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M54.3579 30.5972C50.8222 30.5972 47.9551 33.4643 47.9551 37C47.9551 40.5357 50.8222 43.4029 54.3579 43.4029C57.8936 43.4029 60.7608 40.5357 60.7608 37C60.7608 33.4643 57.8936 30.5972 54.3579 30.5972ZM54.3579 40.8315C52.2494 40.8315 50.5265 39.1215 50.5265 37C50.5265 34.8915 52.2365 33.1686 54.3579 33.1686C56.4665 33.1686 58.1894 34.8786 58.1894 37C58.1894 39.1086 56.4794 40.8315 54.3579 40.8315Z"
        fill="black"
        stroke="black"
      />
    </svg>
  )
}

export default Specifications
