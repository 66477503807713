import React from "react"
import {
  SocialMediaIconProps,
  SocialMediaIconsProps,
} from "./SocialMediaIcons.d"
import tw from "twin.macro"
import SocialMediaIcon from "./SocialMediaIcon"

const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({
  items,
  ...remainingProps
}) => {
  if (!items) return null
  return (
    <ul
      css={[
        tw`flex items-center flex-wrap justify-between gap-x-2`,
        tw`sm:(justify-center gap-x-12)`,
        tw`lg:(justify-between gap-x-2)`,
      ]}
      aria-label="Social Media Links"
      {...remainingProps}
    >
      {items.map((item: SocialMediaIconProps) => (
        <SocialMediaIcon network={item.network} url={item.url} />
      ))}
    </ul>
  )
}

export default SocialMediaIcons
