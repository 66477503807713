import React from "react"
import tw from "twin.macro"

/**
 * QuestionMarkCircle icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const QuestionMarkCircle = ({ ...remainingProps }: any): JSX.Element => {
  return (
    <svg
      viewBox="0 0 15 15"
      style={{ fill: "currentColor" }}
      {...remainingProps}
    >
      <g fill-rule="evenodd">
        <path d="M7.5.714a6.786 6.786 0 1 0 0 13.572A6.786 6.786 0 0 0 7.5.714ZM0 7.5a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Z" />
        <path d="M6.944 3.492c-.591 0-1.071.48-1.071 1.072v.952h-.714v-.952c0-.987.8-1.786 1.785-1.786h1.112c.986 0 1.785.8 1.785 1.786v1.154c0 .473-.188.928-.523 1.263L8.171 8.127c-.201.2-.314.473-.314.757v1.393h-.714V8.885c0-.473.188-.927.523-1.262l1.147-1.148c.201-.2.314-.473.314-.757V4.564c0-.592-.48-1.072-1.072-1.072h-1.11ZM7.143 12.659v-1.588h.714v1.588z" />
      </g>
    </svg>
  )
}

export default QuestionMarkCircle
