import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * News icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const News = ({ color, filled, ...remainingProps }: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <g fill={colorString} fill-rule="evenodd">
        <path d="M31.936 18.292h24v-2.857h-24zM31.936 24.006h24v-2.857h-24zM31.936 29.72h24v-2.857h-24zM31.936 35.435h24v-2.857h-24zM31.936 41.006h24v-2.857h-24zM31.936 46.72h24v-2.857h-24zM31.936 52.435h24v-2.857h-24zM21.682 59.16V19.88H18.5v39.28c0 3.841 1.694 7.284 4.362 9.64h8.325l.04-.002c-5.267-.023-9.545-4.336-9.545-9.639" />
        <path d="M18.5 0v16.678h3.182V3.2h46.136v55.96c0 5.315-4.3 9.64-9.585 9.64H28.085l-.04-.002-.04.002H19.68a12.66 12.66 0 0 0 8.365 3.198l.04.002h30.148C65.27 72 71 66.238 71 59.16V0H18.5Z" />
        <path d="M22.862 68.8h-9.095c-5.285 0-9.585-4.325-9.585-9.64V19.878h17.5v-3.2H1V59.16C1 66.238 6.727 72 13.767 72h17.419l.041-.002a12.66 12.66 0 0 1-8.365-3.198" />
      </g>
    </svg>
  )
}

export default News
