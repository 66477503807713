module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SET Explore","short_name":"SET Explore","start_url":"/","background_color":"#eb0a1e","theme_color":"#eb0a1e","display":"minimal-ui","icon":"static/favicon-512x512.png","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6a10ff6a6c1788c2f984eac3e556415"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
