import React from "react"
import { IconProps } from "."
import { processIconColor } from "../../../helpers"

/**
 * Phone ringing icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const PhoneRinging: React.FC<IconProps> = ({
  color = "white",
  ...remainingProps
}) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 27 27" fill="none" {...remainingProps}>
      <g clipPath="url(#clip0_3973_7551)">
        <path
          d="M12.4595 7.73046C12.4981 7.95607 12.6835 8.10646 12.9161 8.08719C13.6648 7.98904 14.396 8.2022 14.9717 8.64633C15.5473 9.09046 15.941 9.75329 16.02 10.509C16.0585 10.7346 16.244 10.885 16.4766 10.8657C16.4959 10.8587 16.5414 10.8639 16.5606 10.8569C16.7338 10.7939 16.8456 10.6224 16.8141 10.416C16.6844 9.46101 16.2013 8.61277 15.4402 8.01825C14.6791 7.42374 13.7327 7.15811 12.7777 7.28783C12.5905 7.31234 12.4209 7.50485 12.4595 7.73046Z"
          fill={colorString}
        />
        <path
          d="M12.6408 5.83423C12.6794 6.05985 12.8649 6.21023 13.0975 6.19096C14.1819 6.05767 15.2718 6.35826 16.1257 7.02795C16.9795 7.69764 17.5501 8.66654 17.6903 9.77029C17.7288 9.9959 17.9143 10.1463 18.1469 10.127C18.1662 10.12 18.2117 10.1252 18.2309 10.1182C18.4041 10.0552 18.5159 9.88377 18.4844 9.67735C18.1513 6.96614 15.6703 5.05845 12.9783 5.38461C12.7719 5.41621 12.6023 5.60865 12.6408 5.83427L12.6408 5.83423Z"
          fill={colorString}
        />
        <path
          d="M20.085 9.94446C20.1043 9.93746 20.1498 9.94267 20.169 9.93567C20.3422 9.87264 20.454 9.70121 20.4225 9.4948C19.9684 5.67285 16.4744 2.95732 12.6594 3.43075C12.4338 3.46932 12.2904 3.674 12.3027 3.88741C12.3413 4.11302 12.5267 4.26341 12.7593 4.24414C16.116 3.82855 19.2058 6.21187 19.6214 9.56853C19.6407 9.80114 19.8524 9.96375 20.0851 9.94447L20.085 9.94446Z"
          fill={colorString}
        />
        <path
          d="M8.01749 11.3299C7.22868 11.617 6.80562 12.49 7.09972 13.2981L10.531 22.7254C10.8181 23.5142 11.6911 23.9373 12.4992 23.6432L17.309 21.8925C18.0978 21.6054 18.5209 20.7324 18.2268 19.9244L14.7955 10.497C14.5084 9.70822 13.6354 9.28516 12.8274 9.57926L8.01749 11.3299ZM9.98529 11.0495L11.1397 10.6293C11.2551 10.5873 11.3601 10.6362 11.4021 10.7517C11.4441 10.8671 11.3951 10.9721 11.2797 11.0141L10.1253 11.4342C10.0099 11.4763 9.90493 11.4273 9.86292 11.3119C9.82091 11.1964 9.86987 11.0915 9.98529 11.0495ZM14.6906 22.3009C14.3635 22.4199 13.9961 22.2486 13.8771 21.9215C13.7581 21.5945 13.9294 21.2271 14.2564 21.108C14.5835 20.989 14.9509 21.1603 15.0699 21.4874C15.1889 21.8144 15.0176 22.1818 14.6906 22.3009ZM14.131 11.0657L16.932 18.7615C17.0511 19.0885 16.892 19.4297 16.3725 19.6188L11.3703 21.4395C11.2356 21.4885 10.8944 21.3294 10.7754 21.0023L7.97437 13.3065C7.85534 12.9795 8.01444 12.6383 8.34148 12.5193L13.3437 10.6986C13.6708 10.5796 14.012 10.7387 14.131 11.0657Z"
          fill={colorString}
        />
      </g>
      <defs>
        <clipPath id="clip0_3973_7551">
          <rect
            width="20.4742"
            height="20.4742"
            fill={colorString}
            transform="translate(0.678192 7.02899) rotate(-20)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PhoneRinging
