import React from "react"
import tw from "twin.macro"
import { LogoProps } from "./Logo.d"

/**
 * Primary Toyota logo
 *
 * @param {boolean} text - display "Toyota" text next to logo icon. Defaults to display text.
 * @param {number} fontSize Can specify font size in px
 * @param {string} width Can specify width in px
 * @param {string} height Can specify height in px
 * @param {any} ..remainingProps Additional props specified in component declaration
 * @returns <div />
 */

const Toyota: React.FC<LogoProps> = ({
  showText = true,
  text = "TOYOTA",
  fontSize,
  height,
  width,
  ...remainingProps
}) => {
  return (
    <div css={[tw`inline-flex items-center`]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 89 88"
        css={tw`w-[55px] h-auto`}
        {...remainingProps}
      >
        <defs>
          <filter id="ii2q6tdrxa" color-interpolation-filters="auto">
            <feColorMatrix
              in="SourceGraphic"
              values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
            />
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <path
            fill="#E00A1F"
            d="M0 0H88V88H0z"
            transform="translate(-54.000000, -235.000000) translate(54.500000, 235.000000)"
          />
          <g
            filter="url(#ii2q6tdrxa)"
            transform="translate(-54.000000, -235.000000) translate(54.500000, 235.000000)"
          >
            <g>
              <path
                fill="#000"
                d="M30.85 0C13.859 0 .084 8.772.084 19.594c0 10.821 13.775 19.593 30.766 19.593s30.765-8.772 30.765-19.593C61.615 8.772 47.841 0 30.85 0zM8.054 11.84c.847 3.54 7.005 6.479 15.34 7.596l-.003.5c0 8.397 2.417 15.44 5.675 17.355-13.52-.646-24.184-8.101-24.184-17.203 0-2.988 1.15-5.798 3.172-8.249zm18.398 7.916c1.423.108 2.894.164 4.398.164 1.504 0 2.974-.056 4.397-.164-.201 6.227-2.093 11.107-4.397 11.107-2.304 0-4.196-4.88-4.398-11.107zm11.854-.32c8.523-1.143 14.77-4.19 15.389-7.838 2.148 2.506 3.375 5.404 3.375 8.49 0 9.163-10.808 16.657-24.457 17.215 3.268-1.897 5.696-8.952 5.696-17.366 0-.167-.001-.334-.003-.5zm-4.79-16.192c8.52.433 15.052 2.913 15.052 5.906 0 2.445-4.356 4.547-10.595 5.472-.68-5.232-2.335-9.432-4.457-11.378zm1.543 11.71c-1.349.111-2.759.17-4.21.17-1.45 0-2.86-.059-4.209-.17.567-4.937 2.237-8.515 4.21-8.515 1.973 0 3.643 3.578 4.21 8.516zm-11.333-.332c-6.238-.925-10.595-3.027-10.595-5.472 0-1.01.744-1.961 2.057-2.796 1.54-.774 3.215-1.439 4.996-1.974 2.296-.585 5.027-.985 8-1.136-2.122 1.946-3.778 6.146-4.458 11.378z"
                transform="translate(12.634525, 24.234375)"
              />
              <path
                d="M30.85 0C13.858 0 .084 8.772.084 19.594c0 10.821 13.774 19.593 30.766 19.593 16.991 0 30.765-8.772 30.765-19.593C61.615 8.772 47.841 0 30.85 0zM8.054 11.84c.847 3.54 7.005 6.479 15.34 7.596-.002.167-.003.333-.003.5 0 8.397 2.417 15.44 5.675 17.355-13.52-.646-24.184-8.101-24.184-17.203 0-2.988 1.15-5.798 3.172-8.249zm18.398 7.916c1.423.108 2.894.164 4.398.164 1.504 0 2.974-.056 4.397-.164-.201 6.227-2.093 11.107-4.397 11.107-2.304 0-4.196-4.88-4.398-11.107zm11.854-.32c8.523-1.143 14.77-4.19 15.389-7.838 2.148 2.506 3.375 5.404 3.375 8.49 0 9.163-10.809 16.657-24.457 17.215 3.268-1.897 5.696-8.952 5.696-17.366 0-.168-.001-.334-.003-.5zm-4.79-16.192c8.52.433 15.052 2.913 15.052 5.906 0 2.445-4.356 4.547-10.595 5.472-.68-5.232-2.335-9.432-4.457-11.378zm1.543 11.71c-1.349.111-2.759.17-4.21.17-1.45 0-2.86-.059-4.209-.17.567-4.937 2.237-8.515 4.21-8.515 1.972 0 3.643 3.578 4.21 8.516zm-11.333-.332c-6.238-.925-10.595-3.027-10.595-5.472 0-1.01.744-1.961 2.057-2.796 1.54-.774 3.215-1.439 4.996-1.974 2.296-.585 5.027-.985 8-1.136-2.122 1.946-3.778 6.146-4.458 11.378z"
                transform="translate(12.634525, 24.234375)"
              />
            </g>
          </g>
        </g>
      </svg>
      {showText && (
        <p
          css={[
            tw`font-bold ml-2 text-3xl`,
            fontSize && `font-size: ${fontSize}px`,
          ]}
        >
          {text}
        </p>
      )}
    </div>
  )
}

export default Toyota
