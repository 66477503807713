import React from "react"
import { SEOProps } from "./SEO.d"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const SEO: React.FC<SEOProps> = ({
  url,
  title,
  description,
  featuredImage,
  keywords,
  schema,
  canonicalPath,
}): JSX.Element => {
  const siteName = "Southeast Toyota Dealers"
  const titleValue = title && `${title}`
  const descriptionValue =
    description ||
    "Find your next car, truck, SUV, minivan, crossover, or hybrid at Toyota. See photos and dealers in Georgia, Florida, Alabama, and North and South Carolina."
  const featuredImageValue =
    featuredImage || process.env.GATSBY_ROOT_URL + `thumbnail.jpg`
  const urlValue = url || `${process.env.GATSBY_ROOT_URL}`

  const globalSEOData = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        seo
      }
    }
  `)

  if (canonicalPath?.charAt(0) === "/") {
    canonicalPath = canonicalPath.slice(1, canonicalPath.length - 1)
  }
  const canonicalUrl = canonicalPath
    ? `${process.env.GATSBY_ROOT_URL}${canonicalPath}`
    : url
  return (
    <Helmet>
      <title>{titleValue}</title>
      {keywords && keywords.length && (
        <meta name="keywords" content={keywords} />
      )}
      <meta name="description" content={descriptionValue} />
      <meta name="revised" content={Date.now().toLocaleString()} />
      <meta name="url" content={urlValue} />
      <meta property="og:title" content={titleValue} />
      <meta property="og:url" content={urlValue} />
      <meta property="og:image" content={featuredImageValue} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={descriptionValue} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@SoutheastToyota" />
      {schema && <script type="application/ld+json">{schema}</script>}
      {!schema && globalSEOData && (
        <script type="application/ld+json">
          {globalSEOData.sanitySiteSettings.seo}
        </script>
      )}
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  )
}

export default SEO
