import React from "react"
import tw from "twin.macro"
import { getTWColorHex } from "../../../helpers"
import { BadgeProps } from "./Badge.d"

/**
 * A badge component
 *
 * @component
 * @param {BadgeProps} props
 * @param {boolean} props.large -- controls size of the badge
 * @param {TWColor} props.color -- color of badge
 *
 * @returns <div></div>
 *
 */

const Badge: React.FC<BadgeProps> = ({
  large = false,
  color = "red-400",
  children,
  ...remainingProps
}) => {
  return (
    <div
      css={[
        tw`inline-block rounded-full text-white text-sm px-3 pt-1 pb-1.5 leading-none whitespace-nowrap`,
        large && tw`text-xs px-5`,
        `background-color: ${getTWColorHex(color)}`,
      ]}
      {...remainingProps}
    >
      {children}
    </div>
  )
}
export default Badge
