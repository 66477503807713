import React, { useContext, useEffect, useState } from "react"
import { ContactDealerProps } from "./ContactDealer.d"
import { Modal } from "../../atoms/Modal"
import tw from "twin.macro"
import ProgressIndicators from "./ProgressIndicators"
import ConfirmZip from "./ConfirmZip"
import DealerLocationView from "./DealerLocationView"
import { ContactContext } from "../../../contexts/Contact"
import {
  clearState,
  setActiveView,
  updateField,
} from "../../../contexts/Contact/actions"
import NameValidationView from "./NameValidationView"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick, tealiumSubmit } from "./TealiumHelpers"
import { reformattedContactView } from "../../../helpers"
import OptionalCommentsView from "./OptionalCommentsView"
import TactSummaryView from "./TactSummaryView"
import { LeadsClient } from "../../../clients/LeadsClient"
import { Lead } from "../../../clients/LeadsClient.d"
import TactNameView from "./TactNameView"
import { useLocation } from "@reach/router"

const TactContactDealer: React.FC<ContactDealerProps> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const { language, _ } = useContext(LanguageContext)
  const [submitting, setSubmitting] = useState(false)
  const [sentSuccess, setSentSuccess] = useState(null)
  const [
    {
      contactDealerSelection, // selected dealer passed from clicking Contact Dealer from a dealer
      dealer,
      activeView,
      gRecaptchaResponse,
      comments,
      leadType,
      firstName,
      lastName,
      phone,
      email,
    },
    dispatch,
  ] = useContext(ContactContext)
  const location = useLocation()

  useEffect(() => {
    // Close the modal when the URL changes
    // This is neccessary if the user clicked the CTA in the header to contact a dealer
    // and then trys to navigate backwards without closing the modal
    const handleUrlChange = () => {
      setModalIsOpen(false)
    }

    return () => {
      handleUrlChange()
    }
  }, [location])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData, updateContactDealerData } = useTealiumContext()
  // POST to leads API
  const onSubmit = async () => {
    setSubmitting(true)
    const lead: Lead = {
      LeadType: leadType,
      Comments: comments,
      FirstName: firstName,
      LastName: lastName,
      LanguageCode: language,
      DealerName: dealer.Name,
      DealerCode: dealer.DealerCode,
      contact_options: "",
      Email: email,
      Phone: phone,
      "g-recaptcha-response": gRecaptchaResponse,
    }
    try {
      const sentSuccesfully = await LeadsClient.createLead(lead)
      if (sentSuccesfully) {
        setSubmitting(false)
        setSentSuccess(true)
        tealiumSubmit(
          "thank_you_page",
          "Review & Submit Your Request",
          email,
          trackTealEvent,
          contactDealerData,
          contactDealerData?.dealerData,
          { contact_form_field: "TACT" },
        )
      } else {
        setSubmitting(false)
        setSentSuccess(false)
        tealiumSubmit(
          "error_page",
          "Review & Submit Your Request",
          email,
          trackTealEvent,
          contactDealerData,
          contactDealerData?.dealerData,
          { contact_form_field: "TACT" },
          "An unexpected error has occurred. Please try again.",
        )
      }
    } catch (error) {
      setSubmitting(false)
      setSentSuccess(false)
      tealiumSubmit(
        "error_page",
        "Review & Submit Your Request",
        email,
        trackTealEvent,
        contactDealerData,
        contactDealerData?.dealerData,
        { contact_form_field: "TACT" },
        "An unexpected error has occurred. Please try again.",
      )
    }
  }

  useEffect(() => {
    if (!modalIsOpen) return
    const preSelectedDealer = contactDealerSelection
    dispatch(updateField("dealer", preSelectedDealer))
    dispatch(setActiveView("NameView"))

    // // Tealium initial load view event
    // trackTealEvent({
    //   tealium_event: "contact_dealer",
    //   contact_question: "Select a Dealership to Continue",
    // })
    updateContactDealerData({
      dealerData: {
        dealer_name: preSelectedDealer.Name,
        dealer_code: preSelectedDealer.DealerCode,
      },
    })
  }, [modalIsOpen])

  // Steps for Progress Indicator
  enum Steps {
    ConfirmZip = null,
    DealerLocationView = null,
    SelectDealerView = null,
    NameView = 1,
    NameValidationView = 1,
    MethodOfContactView = null,
    OptionalCommentsView = 2,
    SummaryView = 3,
  }

  const resetForm = () => {
    setSentSuccess(null)
    dispatch(clearState())
    setModalIsOpen(false)
    setSubmitting(false)
    updateContactDealerData({
      dealerData: null,
    })
  }

  return (
    <Modal
      css={[
        tw`w-full h-full max-h-full bg-gray-50`,
        tw`lg:(max-w-[50rem] max-h-[475px])`,
        "z-index: 9999;",
      ]}
      open={modalIsOpen}
      onClose={() => {
        resetForm()
        tealiumNavClick(
          activeView === "SelectDealerView"
            ? "Select a Dealership to Continue"
            : "SelectVehicleView"
              ? "Were you interested in a specific vehicle?"
              : "What can we do for you?",
          "Close",
          trackTealEvent,
          contactDealerData,
        )
      }}
      hideCancelText={activeView === "SummaryView" && sentSuccess}
      analyticsId={`X:contact form:${reformattedContactView(activeView)}`}
    >
      <form css={[tw`h-full w-full p-6`]} id="contactDealer">
        <section
          css={[
            tw`relative flex flex-col justify-center items-center h-full w-full`,
          ]}
        >
          <ConfirmZip active={activeView === "ConfirmZip"} />
          <DealerLocationView active={activeView === "DealerLocationView"} />
          {/* <SelectTactDealerView
            active={activeView === "SelectDealerView"}
            showBackButton={false}
          /> */}
          <TactNameView
            active={activeView === "NameView"}
            showBackButton={false}
            nextPageOverride="OptionalCommentsView"
          />
          <NameValidationView
            nextPageOverride="OptionalCommentsView"
            active={activeView === "NameValidationView"}
          />
          {/* <TactMethodOfContactView
            active={activeView === "MethodOfContactView"}
          /> */}
          <OptionalCommentsView
            active={activeView === "OptionalCommentsView"}
            maxLength={250}
          />
          <TactSummaryView
            active={activeView === "SummaryView"}
            submitting={submitting}
            sentSuccess={sentSuccess}
            setSentSuccess={setSentSuccess}
            setModalIsOpen={setModalIsOpen}
            reset={resetForm}
            onSubmit={onSubmit}
          />
        </section>
      </form>

      {/* Progress indicators and buttons */}
      {Steps[activeView] && (
        <ProgressIndicators view={Steps[activeView]} totalSteps={3} />
      )}
    </Modal>
  )
}

export default TactContactDealer
