import React from "react"
import { graphql, useStaticQuery } from "gatsby"

/**
 * Hook retrieving a list of series that we have an XSeries model page for.
 * @returns {Array<{ name: string, slug: string }>} Array of objects with the series name and series slug properties
 */

const useXSeriesNameData = () => {
  const xseriesPages = useStaticQuery(graphql`
    query {
      xseries: allSanityPageXseriesModel {
        nodes {
          customModels {
            series {
              name
            }
          }
        }
      }
    }
  `)

  const xseries = xseriesPages?.xseries?.nodes
    ?.map(({ customModels }) => {
      return {
        name: customModels[0]?.series?.name,
        slug: customModels[0]?.series?.slug,
      }
    })
    ?.filter(series => series?.name)

  return xseries ? xseries : []
}

export default useXSeriesNameData
