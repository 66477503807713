import React, { useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Chevron icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {string} props.direction - Orientation of the chevron
 *
 * @returns <svg />
 */
const Chevron: React.FC<IconProps> = ({
  color,
  direction = "right",
  strokeWidth = null,
  ...remainingProps
}) => {
  let rotation = 0
  let viewBox = "0 -1 24 26"
  switch (direction) {
    case "left":
      rotation = 180
      break
    case "down":
      rotation = 90
      viewBox = "-1 0 26 24"
      break
    case "up":
      rotation = -90
      viewBox = "-1 0 26 24"
      break
    default:
      break
  }

  const colorString = processIconColor(color) || "currentColor"

  const [iconHeight, setIconHeight] = useState(0)

  const ref = useRef(null)

  useEffect(() => {
    setIconHeight(ref.current.clientHeight)
  }, [])

  const strokeByHeight = iconHeight > 24 ? "2" : "4"

  return (
    <svg viewBox={viewBox} {...remainingProps} ref={ref} css={[direction]}>
      <path
        transform={`rotate(${rotation}, ${24 / 2}, ${24 / 2})`}
        stroke={colorString}
        stroke-width={strokeWidth ? strokeWidth : strokeByHeight}
        d="m5 0 14 12L5 24"
        fill="none"
        fill-rule="evenodd"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Chevron
