import { ACTION_TYPES } from "./actions"
import { SeriesAccessoriesProviderProps } from "./seriesaccessories"

const seriesAccessoriesReducer = (
  state: SeriesAccessoriesProviderProps,
  action: { type: ACTION_TYPES; payload?: any },
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SERIES_ACCESSORIES_DATA:
      console.log("STATE in REDUCER", state, action.payload)
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

export default seriesAccessoriesReducer
