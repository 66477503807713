import React, { useEffect, useState, useContext } from "react"
import { FooterNavigationMenu, NavigationGroup } from "./Footer.d"
import tw from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { ToyotaLogo } from "../../atoms/Logo"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import {
  UserSettingsMenu,
  NavigationGroupComp,
  FooterAccordion,
  ToyotaMenu,
} from "./index"
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const Footer: React.FC = () => {
  const [data, setData] = useState(null)

  const footerData: FooterNavigationMenu = useStaticQuery(graphql`
    query {
      sanityNavigationFooter: sanityNavigationFooter(language: { eq: "en" }) {
        ...FooterNavigationMenu
      }
      esSanityNavigationFooter: sanityNavigationFooter(language: { eq: "es" }) {
        ...FooterNavigationMenu
      }
    }
  `)
  const { language } = useContext(LanguageContext)

  const [desktopView, setDesktopView] = useState(null)

  // Detect screen width - Nav groups rendered in NavigationGroupComp on desktop and FooterAccordion on mobile
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1025px)")
    setDesktopView(mediaQuery.matches || false)
    mediaQuery.addEventListener("change", e => {
      const mobileViewOn = e.matches
      setDesktopView(mobileViewOn)
    })
  }, [])

  useEffect(() => {
    if (language === "es") {
      setData(footerData?.esSanityNavigationFooter)
    } else {
      setData(footerData?.sanityNavigationFooter)
    }
  }, [language])

  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  return (
    <footer
      css={[tw`bg-black pb-12`]}
      aria-label="Footer Section"
      role="contentinfo"
    >
      <div
        css={[
          tw`grid auto-rows-min grid-cols-3 items-center gap-y-8 w-full max-w-[1440px] mx-auto py-10 px-6 bg-black text-white`,
          tw`sm:(px-10 py-12 gap-y-24)`,
        ]}
      >
        {/* Top row */}
        <Link
          to={language === "es" ? "/es/" : "/"}
          target="_self"
          aria-label="Explore Toyota homepage"
          css={[
            tw`col-span-3 justify-self-start border border-transparent p-1 -ml-1`,
            tw`lg:col-span-1`,
            tw`focus-visible:(border-white border-dashed border outline-none rounded-none p-1)`,
          ]}
          onClick={() =>
            trackTealEvent({
              tealium_event: "nav_click",
              click_text: "footer:logo",
            })
          }
          analytics-id="logo:footer:"
        >
          <ToyotaLogo fill={`#FFF`} />
        </Link>
        <SocialMediaIcons
          items={data?.socialMediaMenu}
          css={[tw`col-span-3`, tw`lg:col-span-1`]}
        />
        <UserSettingsMenu />
        {/* Middle row */}
        <div
          css={[
            tw`col-span-3 flex flex-col gap-8`,
            tw`lg:(flex-row flex-wrap justify-between)`,
          ]}
        >
          {desktopView ? (
            <NavigationGroupComp groups={data?.groups} />
          ) : (
            data?.groups?.map((group: any) => <FooterAccordion group={group} />)
          )}
        </div>
        {/* Bottom row */}
        <div css={[tw`col-span-3 text-center`]}>
          <ToyotaMenu links={data?.toyotaMenu} />
          <div
            css={[
              tw`w-full text-gray-600 mt-2`,
              "font-size: 0.625rem; line-height: 1.5rem;",
            ]}
          >
            {data?.copyrightText}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
