import React, { useContext } from "react"
import tw from "twin.macro"
import { CashCardProps } from "./OfferCard.d"
import { toUsd } from "../../../helpers"
import { LanguageContext } from "../../../contexts/Language"

const CashCard: React.FC<CashCardProps> = ({ cashAmount, type }) => {
  const { _ } = useContext(LanguageContext)
  return (
    <section css={[tw`flex gap-y-2 flex-wrap font-semibold`]}>
      {cashAmount && (
        <div
          css={[
            tw`flex justify-center text-center w-full text-red-400 text-4xl font-semibold pt-2`,
          ]}
        >
          <sub css={[tw`text-base align-top`]}>$</sub>
          {toUsd(cashAmount)}
        </div>
      )}
      <div
        css={[
          tw`tracking-widest uppercase font-semibold flex flex-col w-full text-center`,
          "font-size: 0.65rem",
        ]}
      >
        {type} {_("REBATE")}
      </div>
      {type !== "Bonus" && (
        <div
          css={[
            tw`flex flex-col w-full text-xs text-center text-gray-700 font-light`,
          ]}
        >
          {_("Applies to multiple series")}
        </div>
      )}
    </section>
  )
}

export default CashCard
