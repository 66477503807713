import Toyota from "./Toyota"
import Toyoguard from "./Toyoguard"
import ToyotaLogo from "./ToyotaLogo"
import ToyoguardPlatinumBlack from "./ToyoguardPlatinumBlack"
import ToyoguardPlatinumWhite from "./ToyoguardPlatinumWhite"

export {
  Toyota,
  Toyoguard,
  ToyotaLogo,
  ToyoguardPlatinumBlack,
  ToyoguardPlatinumWhite,
}

export default {
  Toyota,
  Toyoguard,
  ToyotaLogo,
  ToyoguardPlatinumBlack,
  ToyoguardPlatinumWhite,
}
