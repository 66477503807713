import React, { createContext, useReducer } from "react"
import { ContactContextState } from "./contact.d"
import contactReducer from "./reducers"

export const defaultState: ContactContextState = {
  isContactDealerModalOpen: false,
  isTactContactDealerModalOpen: false,
  isFavoritesContactDealerModalOpen: false,
  contactDealerSelection: null,
  confirmContactDealerSelection: false,
  dealer: null,
  vehicle: null,
  seriesName: "",
  trim: "",
  activeView: "ConfirmZip",
  previousView: null,
  leadType: null,
  gRecaptchaResponse: null,
  contactType: "email",
  contact: "",
  phone: "",
  email: "",
  comments: "",
  fullName: "",
  firstName: "",
  lastName: "",
  contactButtonType: "ContactDealer",
  favoriteVehicles: [],
}

export const ContactContext = createContext<
  [ContactContextState, React.Dispatch<any>]
>([defaultState, () => {}])

export const ContactProvider = ({ children, value }) => {
  const [contactState, dispatch] = useReducer(contactReducer, defaultState)

  return (
    <ContactContext.Provider value={[contactState, dispatch]}>
      {children}
    </ContactContext.Provider>
  )
}
