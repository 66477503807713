import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Loader icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Loader = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 100 100" {...remainingProps}>
      <g
        transform="rotate(0 50 50)"
        // style="transform:matrix(1, 0, 0, 1, 0, 0);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.111112"
          // style="opacity:0.111112;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(40 50 50)"
        // style="transform:matrix(0.766044, 0.642788, -0.642788, 0.766044, 43.8372, -20.4416);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.222223"
          // style="opacity:0.222223;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(80 50 50)"
        // style="transform:matrix(0.173648, 0.984808, -0.984808, 0.173648, 90.558, -7.9228);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.333334"
          // style="opacity:0.333334;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(120 50 50)"
        // style="transform:matrix(-0.5, 0.866025, -0.866025, -0.5, 118.301, 31.6987);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.444445"
          // style="opacity:0.444445;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(160 50 50)"
        // style="transform:matrix(-0.939693, 0.34202, -0.34202, -0.939693, 114.086, 79.8836);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.555556"
          // style="opacity:0.555556;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(200 50 50)"
        // style="transform:matrix(-0.939693, -0.34202, 0.34202, -0.939693, 79.8836, 114.086);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.666667"
          // style="opacity:0.666667;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(240 50 50)"
        // style="transform:matrix(-0.5, -0.866025, 0.866025, -0.5, 31.6987, 118.301);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.777778"
          // style="opacity:0.777778;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(280 50 50)"
        // style="transform:matrix(0.173648, -0.984808, 0.984808, 0.173648, -7.9228, 90.558);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="0.888889"
          // style="opacity:0.888889;animation-play-state:paused"
        ></rect>
      </g>
      <g
        transform="rotate(320 50 50)"
        // style="transform:matrix(0.766044, -0.642788, 0.642788, 0.766044, -20.4416, 43.8372);animation-play-state:paused"
      >
        <rect
          x="47"
          y="27"
          rx="3"
          ry="3"
          width="6"
          height="6"
          fill={colorString}
          opacity="1"
          // style="animation-play-state:paused"
        ></rect>
      </g>
    </svg>
  )
}

export default Loader
