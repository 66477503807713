import { ReactChildren, ReactChild } from "react"

export enum ORIGIN_Y {
  TOP = "top",
  BOTTOM = "bottom",
}
export enum ORIGIN_X {
  LEFT = "left",
  RIGHT = "right",
}

export type PopoverOrigin = `${ORIGIN_Y}-${ORIGIN_X}`

export enum POPOVER_ORIGINS {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
}

export type PopoverProps = {
  open?: boolean
  onClose?: () => void
  origin: POPOVER_ORIGINS | PopoverOrigin
  children?: ReactChildren | ReactChild
  closeButtonStyle?: any
  closeButtonColor?: string
}
