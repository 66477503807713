import React, { useContext } from "react"
import tw from "twin.macro"
import { HeaderContext } from "../../../contexts/Header/context"
import Icon from "../../atoms/Icon"
import { FavoritesDropDownProps } from "./FavoritesDropDown.d"

/**
 *
 * @author Cody Clark
 * @summary - Custom dropdown behaviour for Favorites functionality.
 *
 * @returns <FavoritesDropDown />
 *
 * @param {FavoritesDropDownProps} props
 * @param {boolean} props.show - Show or hide search dropdown
 * @param {children} props.children - Child elements passed to dropdown
 * @param {number} props.index - Index of Nav item
 * @param {boolean} props.mobileView - passed state of mobile view
 * @param {boolean} props.toggleMobileDropdown - state for display of dropdown in mobile view
 *
 * @todo - Unit tests
 */

const FavoritesDropDown: React.FC<FavoritesDropDownProps> = ({
  show,
  children,
  mobileView,
  toggleMobileDropdown,
}): JSX.Element => {
  const { setOpen } = useContext(HeaderContext)
  return (
    <>
      <div
        css={[
          tw`justify-items-center w-full fixed top-0 left-0 bg-gray-50 hidden max-h-0  transition-all  ease-out duration-300 shadow-3 overflow-x-auto pb-20`,
          tw`lg:(absolute grid top-full scrollbar-hide)`,
          show && tw` max-h-screen grid z-50`,
          mobileView && toggleMobileDropdown && tw` max-h-screen grid z-50`,
        ]}
      >
        <button
          css={[
            tw`absolute top-4 right-6 text-gray-800 p-2 border border-transparent`,
            tw`lg:(hidden)`,
            tw`focus-visible:(border-gray-700 border-dashed border outline-none p-2)`,
          ]}
          onClick={() => {
            setOpen(null)
          }}
        >
          <Icon.X color={"gray-800"} css={[tw`w-4`]} />
        </button>
        {children}
      </div>
      <div
        css={[
          tw`hidden`,
          show &&
            tw`lg:(block bg-gray-900 opacity-70 h-full w-full fixed top-[118px] left-0 z-40)`,
        ]}
      ></div>
    </>
  )
}

export default FavoritesDropDown
