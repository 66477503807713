import { ACTION_TYPES } from "../ConfirmZip/actions"
import { ConfirmZipContextState } from "./confirmZip.d"

const confirmZipReducer = (
  state: ConfirmZipContextState,
  action: { type: ACTION_TYPES; payload?: any },
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CONFIRM_ZIP_MODAL_OPEN:
      return { ...state, isZipModalOpen: action.payload }
    default:
      return state
  }
}

export default confirmZipReducer
