export type RefineInAction = {
  inProgress: boolean
  refinementValue?: string | null
  tealiumEvent: string | null
  customer_zip?: string | null
  confirmZipEvent?: boolean | null
  dealerName?: string
  dealerCode?: string
  filtersQueue?: Object[]
  couponModuleText?: string
  filterValues?: any[]
}

export const refineInActionDefault: RefineInAction = {
  inProgress: false,
  refinementValue: null,
  tealiumEvent: null,
  customer_zip: null,
  confirmZipEvent: null,
  filtersQueue: [],
}

export type ContactDealerData = {
  fromInventory?: {
    vehicle_model?: string
    vehicle_year?: string
    vehicle_trim?: string
    vehicle_trim_id?: string
    vehicle_vin?: string
    vehicle_exterior_color?: string
    vehicle_interior_color?: string
    vehicle_sale_price?: string
    vehicle_segment_vehicle_page?: string
    vehicle_model_truncated?: string
  } | null
  fromSelection?: {
    vehicle_model?: string
    vehicle_trim?: string
    vehicle_model_truncated?: string
    vehicle_year?: string
    vehicle_segment_vehicle_page?: string
  } | null
  dealerData?: {
    dealer_name: string
    dealer_code: string
  } | null
  allocatedDealer?: {
    allocated_dealer_name: string
    allocated_dealer_code: string
  } | null
}

export type TealiumData = {
  tealPageData: {
    vehicle_year?: string | number
    vehicle_model?: string
    user_agent?: string
    site_section?: string
    page_url?: string
    page_type?: string
    page_name?: string
    page_language?: string
    geolocation_zip?: string
    customer_zip?: number
    customer_ip?: string
    vehicle_segment_vehicle_page?: string
    vehicle_powertrain?: string
    offer_type?: string
  }
  writeTealPageData: React.Dispatch<any>
  updateTealPageData: React.Dispatch<any>
  vehicleTealData: {
    vehicle_model_trim?: any // Not in SDR. Uses values for vehicle model _and_ trim (for tealium call in ModelImageViewerWithHotSpots.tsx)
    exterior_color?: string
    vehicle_exterior_color?: string
    interior_color?: string
    vehicle_interior_color?: string
    vehicle_trim?: string
    vehicle_trim_id?: string
    vehicle_model?: string
    vehicle_segment_vehicle_page?: string
    vehicle_series?: string // not in SDR but used in XseriesHotspot.tsx
    vehicle_model_truncated?: string
    vehicle_config?: string
    vehicle_year?: string | number
    vehicle_vin?: string
    vehicle_sale_price?: string
    family?: {
      family?: string
    }
    dealer_code?: string | string[]
    dealer_name?: string | string[]
    allocated_dealer_code?: string | string[]
    allocated_dealer_name?: string | string[]
    vehicle_powertrain?: string
  }

  updateVehicleTealData: React.Dispatch<any>
  setVehicleTealData: React.Dispatch<any>
  deleteVehicleTealData: () => void
  inventoryResults?: any
  setInventoryResults?: React.Dispatch<any>
  updateContactDealerData?: React.Dispatch<ContactDealerData>
  contactDealerData?: ContactDealerData
  refineInAction?: RefineInAction
  setRefineInAction?: React.Dispatch<any>
  zipEvent?: {
    inProgress: boolean
    prevZip: string
    searchInput: string
    isGlobal?: boolean
  }
  setZipEvent?: React.Dispatch<any>
  inventoryFilters: {
    refinement_value: string
    dealer_code?: string
    dealer_name?: string
  }[]
  addInventoryFilterToQueue: React.Dispatch<any>
  removeInventoryFilterFromQueue: React.Dispatch<any>
  clearInventoryFilterQueue: () => void
  removeInventoryFilterFromQueueByKey: React.Dispatch<any>
  swapInventoryFilterInQueue: (filter: Object, filterKey: string) => void
  clearAllInventoryFilterQueue: () => void
  addInventoryPriceFilterToQueue: (filter: Object, filterKey: string) => void
  lifestyleLanderTealData: { selectedCategories: string[] }
  updateLifestyleLanderTealData: React.Dispatch<any>
}

export const initialValue: TealiumData = {
  tealPageData: {},
  writeTealPageData: () => console.log("Tealium Page Data Written"),
  updateTealPageData: () => console.log("Tealium Page Data Updated"),
  setInventoryResults: () => console.log("Inventory Page Data Set"),
  setVehicleTealData: () => console.log("Tealium Vehicle Data is set"),
  deleteVehicleTealData: () => console.log("Tealium Vehicle Data Delete"),
  vehicleTealData: {},
  updateVehicleTealData: () => console.log("Vehicle Data Updated"),
  contactDealerData: {
    fromInventory: {
      vehicle_model: null,
      vehicle_year: null,
      vehicle_trim: null,
      vehicle_trim_id: null,
      vehicle_vin: null,
      vehicle_exterior_color: null,
      vehicle_sale_price: null,
    },
    fromSelection: {
      vehicle_model: null,
      vehicle_trim: null,
    },
    dealerData: {
      dealer_code: null,
      dealer_name: null,
    },
  },
  updateContactDealerData: () => console.log("Contact Dealer Data Updated"),
  refineInAction: refineInActionDefault,
  setRefineInAction: () => console.log("Set refine in action"),
  zipEvent: {
    inProgress: false,
    prevZip: null,
    searchInput: null,
    isGlobal: false,
  },
  inventoryFilters: [],
  addInventoryFilterToQueue: () =>
    console.log("Inventory Filter Added to Queue"),
  removeInventoryFilterFromQueue: () =>
    console.log("Inventory Filter Removed from Queue"),
  clearInventoryFilterQueue: () =>
    console.log("Inventory Filter Queue Cleared"),
  removeInventoryFilterFromQueueByKey: () =>
    console.log("Inventory Filter Removed from Queue by Key"),
  swapInventoryFilterInQueue: () =>
    console.log("Inventory Price Filter Removed from Queue"),
  clearAllInventoryFilterQueue: () =>
    console.log("Inventory Filter Queue Cleared"),
  addInventoryPriceFilterToQueue: () =>
    console.log("Inventory Price Filter Removed from Queue"),
  lifestyleLanderTealData: { selectedCategories: [] },
  updateLifestyleLanderTealData: () =>
    console.log("Update Lifestyle Lander Teal Data"),
}

export type TealiumProviderProps = {
  tealPageData: Object
  writeTealPageData: () => React.Dispatch<any>
  vehicleTealData: Object
  updateVehicleTealData: React.Dispatch<any>
  deleteVehicleTealData: React.Dispatch<any>
  contactDealerData: Object
  updateContactDealerData: () => React.Dispatch<any>
  inventoryFilters: Object
  addInventoryFilterToQueue: React.Dispatch<any>
  removeInventoryFilterFromQueue: React.Dispatch<any>
  clearInventoryFilterQueue: React.Dispatch<any>
  removeInventoryFilterFromQueueByKey: React.Dispatch<any>
  swapInventoryFilterInQueue: React.Dispatch<Object | string>
  clearAllInventoryFilterQueue: React.Dispatch<any>
  addInventoryPriceFilterToQueue: React.Dispatch<Object | string>
  lifestyleLanderTealData: Object
  updateLifestyleLanderTealData: React.Dispatch<Object | string>
}
