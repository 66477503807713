import React, { useEffect } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ExternalVideoProps } from "./Video.d"
import useComponentId from "../../../hooks/useIdGenerator"

const ExternalVideo: React.FC<ExternalVideoProps> = ({
  videoSectionHeading,
  videoSectionVideo,
  autoPlay = false,
  videoCss,
  extraTealiumValues,
}) => {
  const uuid = useComponentId()
  // Tealium for video events using YouTube iFrame API
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const videoId = videoSectionVideo?.embedUrl?.split("/").pop()
  let player: any

  const handleTealiumEvent = (
    action: string,
    variable: string,
    title?: string,
  ) => {
    const tealDataFromParent = window?.parent?.teal
    trackTealEvent({
      //manual work around on page refresh when tealPageData is no longer availabe in the iframe
      ...(!tealPageData.page_name && {
        page_url: tealDataFromParent?.page_url,
        page_type: tealDataFromParent?.page_type,
        page_name: tealDataFromParent?.page_name,
        page_language: tealDataFromParent?.page_language,
        geolocation_zip: tealDataFromParent?.geolocation_zip,
        full_page_name: tealDataFromParent?.full_page_name,
        customer_ip: tealDataFromParent?.customer_ip,
      }),
      tealium_event: "video_event",
      video_type: `${
        videoSectionHeading || title
      }-${videoId}|${action}|${variable}`,
      ...(extraTealiumValues && extraTealiumValues),
    })
  }
  const onPlayerStateChange = (event: { data: any; target: any }) => {
    const currentTime = Math.round(event.target.getCurrentTime()).toString()
    const title = event.target.getVideoData().title

    if (event.data == 1) {
      if (currentTime === "0") {
        handleTealiumEvent("start", `0:${currentTime}`, title)
      }
    }
    if (event.data == 2) {
      const currentPercent = Math.round(
        (parseInt(currentTime) / Math.round(event.target.getDuration())) * 100,
      ).toString()
      handleTealiumEvent("pause", `${currentPercent}:${currentTime}`, title)
    }
    if (event.data == 0) {
      handleTealiumEvent("ended", `100:${currentTime}`, title)
    }
  }

  //create player object and generate iFrame
  useEffect(() => {
    try {
      window.YT.ready(function () {
        player = new window.YT.Player(`${uuid}`, {
          videoId: videoId,
          allow: "autoplay; encrypted-media",
          title: "video",
          host: "https://www.youtube.com",
          playerVars: {
            autoplay: autoPlay ? 1 : 0,
          },
          enablejsapi: 1,
          events: {
            onStateChange: onPlayerStateChange,
          },
          modestbranding: 1,
          origin: window.location.href,
        })
      })
    } catch (err) {
      console.log("Error loading youtube video:", err.message)
    }
  }, [])

  return (
    <div
      id={`${uuid}`}
      css={videoCss ? videoCss : [tw`h-1/3 w-full p-12`, tw`md:(h-5/6 w-full)`]}
    ></div>
  )
}

export default ExternalVideo
