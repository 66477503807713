import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import Icon from "../../atoms/Icon"
import {
  HelpItemProps,
  SearchHeadingProps,
  SearchListProps,
} from "./SearchMenu.d"
import { Link } from "../../atoms/Link"
import { SearchQuery } from "../../atoms/SearchQuery"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { openContactDealerModal } from "../../../contexts/Contact/actions"
import { ContactContext } from "../../../contexts/Contact"
import { useTealiumContext } from "../../../contexts/Tealium"
import { getPageLoadTime } from "../../../helpers"
import { SearchContext } from "../../../contexts/Search"

/**
 *
 * @author Stu Finn
 * @summary - Search menu that lives below the global search bar. Should display popular searches and
 *    " need help?" sections by default, and dynamically suggested search queries.
 *
 * @returns <SearchMenu />

 *
 * @todo - Responsive behavior.
 * @todo - Import "popularSearches" data from Sanity
 * @todo - Include i18n / Spanish
 * @todo - Unit tests
 */

const SearchMenu: React.FC = (): JSX.Element => {
  // Retrieve "Popular Search" data from Sanity
  const { allSanitySearch } = useStaticQuery(graphql`
    query SearchMenuQuery {
      allSanitySearch {
        nodes {
          language
          popularSearchSection {
            ColumnA
            ColumnB
          }
        }
      }
    }
  `)

  const [{}, dispatch] = useContext(ContactContext)

  // Use desctructuring to retrieve the language context value (i.e. string value of "en" or "es")
  const { language, _ } = useContext(LanguageContext)
  const [{ query }, searchDispatch] = useContext(SearchContext)

  // Filter data from graphQL query to match language from Context
  const popularSearchData = allSanitySearch.nodes.filter(
    (node: { language: string }) => node.language === language,
  )
  // Use destructuring to get an object with the "popular searches" arrays. Renamed to "popularSearches"
  const { popularSearchSection: popularSearches } = popularSearchData[0]

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const {
    updateContactDealerData,
    setVehicleTealData,
    tealPageData,
    vehicleTealData,
  } = useTealiumContext()

  // Reusable fuction for multiple, similar calls
  const handleTealEvent: (eventName: string, moreData?: {}) => void = (
    eventName = "No event name provided",
    moreData = {},
  ) => {
    trackTealEvent({
      tealium_event: eventName,
      ...moreData,
    })
  }

  return (
    <article css={[tw`flex justify-between mx-8`]}>
      {/* Popular searches section */}
      <section>
        <Heading>{_("Popular Searches")}</Heading>
        <div css={[tw`grid grid-cols-2 justify-start gap-x-20`]}>
          {/* Left Column */}
          <WordColumn listWords={popularSearches?.ColumnA} />
          {/* Right Column */}
          <WordColumn listWords={popularSearches?.ColumnB} />
        </div>
      </section>

      {/* Need help section */}
      <section>
        <Heading>{_("Need Help?")}</Heading>
        <div css={[tw`flex`]}>
          <HelpItem
            title={_("Chat with Us")}
            onClick={() => {
              window &&
                window.ActivEngage.launchChat({
                  vehicleVin: "",
                  vehicleYear: "",
                  vehicleMake: "Toyota",
                  vehicleModel: "",
                  vehicleStatus: "",
                  launchSource: "Search",
                })
              window?.ActivEngage?.data?.setChatData({
                geolocation_zipcode: tealPageData?.geolocation_zip || null,
                dealer_name: vehicleTealData?.allocated_dealer_name || null,
                dealer_code: vehicleTealData?.allocated_dealer_code || null,
                vehicle_name: vehicleTealData?.vehicle_model_truncated || null,
                vehicle_year: vehicleTealData?.vehicle_year || null,
                vehicle_model: vehicleTealData?.vehicle_trim || null,
                vehicle_trim_id: vehicleTealData?.vehicle_trim_id || null,
                page_name: tealPageData?.page_name || null,
                domain: window?.location?.hostname || null,
                url: window?.location?.href || null,
                referrer: document?.referrer || null,
                page_language: tealPageData?.page_language || null,
                vehicle_sale_price: vehicleTealData?.vehicle_sale_price || null,
                page_load_time: getPageLoadTime() || null,
                search_query: query || null,
                page_type: tealPageData?.page_type || null,
              })
              console.info(
                "Active Engage Chat Data",
                window?.ActivEngage?.data?.getChatData(),
              )
              handleTealEvent("chat_nav_click"),
                trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `top nav:Chat with Us`,
                })
            }}
            analytics-id="chat now:top nav:search"
          >
            <Icon.ChatBubble
              color="red-400"
              filled={true}
              css={[
                tw`h-3`,
                "filter: drop-shadow(0px 6px 6px rgb(10, 22, 70, 0.1)) drop-shadow(0px 0px 1px rgb(10, 22, 70, 0.06));",
                tw`md:(h-9)`,
              ]}
            />
          </HelpItem>
          <HelpItem
            title={_("Contact Dealer")}
            onClick={() => {
              dispatch(openContactDealerModal())
              trackTealEvent({
                tealium_event: "contact_dealer_nav",
                click_text: "top nav:Contact Dealer",
              })
              trackTealEvent({
                tealium_event: "nav_click",
                click_text: `top nav:Contact Dealer`,
              })
              if (tealPageData.page_type !== "inventory vdp") {
                updateContactDealerData({
                  fromInventory: null,
                  fromSelection: null,
                  allocatedDealer: null,
                })
                setVehicleTealData(null)
              }
            }}
            analytics-id="contact:top nav:search"
          >
            <Icon.Directions color="red-400" css={[tw`h-3`, tw`md:(h-9)`]} />
          </HelpItem>
        </div>
      </section>
    </article>
  )
}

const Heading: React.FC<SearchHeadingProps> = ({ children }) => (
  <h2 css={[tw`font-sans font-light text-base mb-5 tracking-widest`]}>
    {children}
  </h2>
)

const HelpItem: React.FC<HelpItemProps> = ({
  title,
  children,
  onClick,
  ...remainingProps
}) => (
  <Link
    className="group"
    css={[
      tw`grid pr-10 justify-items-center pt-0 cursor-pointer`,
      tw`last:pr-0`,
    ]}
    onClick={onClick}
    {...remainingProps}
  >
    <div
      css={[
        tw`  text-base font-semibold`,
        tw`group-hover:text-red-500`,
        `line-height: 18px`,
      ]}
    >
      {title}
    </div>
    <div css={[tw`mt-5`]}>{children}</div>
  </Link>
)

/**
 *
 * @author Stu
 * @summary - A grid column of Search Query items.
 * @param {string[]} listWords  - Search terms to include in list
 *
 * @returns <WordColumn />
 *
 * @todo - Unit tests
 */

const WordColumn: React.FC<SearchListProps> = ({ listWords }) => {
  return (
    <div css={[tw`grid gap-y-4 text-sm font-semibold`]}>
      {listWords?.map((searchTerm: string, index: number) => (
        <SearchQuery
          key={`${searchTerm}-${index}`}
          searchTerm={searchTerm}
          index={index}
        />
      ))}
    </div>
  )
}

export default SearchMenu
