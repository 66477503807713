import { Link } from "../Link"
import tw from "twin.macro"
import { NavListItemProps } from "./NavList.d"
import { LinkType } from "../../../global"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { HeaderContext } from "../../../contexts/Header"
import { useContext } from "react"
import { getCTALinkValue } from "../../../helpers"

/**
 * List navigation with full microdata schema.
 *
 * Takes a list of NavListItems as prop "items".
 *
 * @component
 * @param {NavListItemProps} props
 * @param {NavListItem[]} props.items - List of ListItem elements
 * @param {string} props.header - Heading text
 * @param {boolean} props.light - Controls whether text is light or dark
 *
 * @returns <NavList />
 */
const NavList: React.FC<NavListItemProps> = ({
  light = false,
  animated = false,
  header = "",
  items = [],
  ...remainingProps
}) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { section } = remainingProps
  const { open, setOpen } = useContext(HeaderContext)
  return (
    <div
      css={[tw`mb-6 lg:(ml-6)`]}
      itemScope
      itemType="https://schema.org/ListItem"
      {...remainingProps}
    >
      <h3
        css={[
          tw`mb-1 font-semibold uppercase`,
          tw`text-black`,
          light && tw`text-gray-100`,
        ]}
      >
        {header}
      </h3>
      <ul
        css={[
          tw`w-full`,
          tw`md:(w-full)`,
          tw`lg:(w-auto min-w-[9rem])`,
          section?.toLowerCase() === "accessories" ||
            (section?.toLowerCase() === "accesorios" &&
              tw`w-full md:(w-[240px] lg:(w-auto))`),
        ]}
      >
        {items.map((item: LinkType, index) => {
          let itemLink = getCTALinkValue(item)
          return (
            <li
              key={item._key}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              css={[tw`pb-1`]}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "nav_click",
                  click_text: `top nav:${item?.displayText}`,
                })
                setOpen(null)
              }}
              analytics-id={
                section ? `${item?.displayText}:top nav:${section}` : null
              }
            >
              <Link
                animated
                animatedThin
                to={itemLink}
                target={item?.linkType === "external" ? "_blank" : "_self"}
                css={[
                  tw`p-0 text-black leading-7 border border-transparent focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
                  light &&
                    tw`text-gray-500 text-xs focus-visible:outline-white`,
                ]}
              >
                <span itemProp="name">{item.displayText}</span>
              </Link>
              <meta itemProp="position" content={`${index++}`} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default NavList
