import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { ProgressIndicatorsProps } from "./ContactDealer.d"

const ProgressIndicators: React.FC<ProgressIndicatorsProps> = ({
  view,
  totalSteps,
}) => {
  let steps = []
  for (let i = 1; i <= totalSteps; i++) {
    steps.push({
      position: i,
    })
  }

  return (
    view !== 0 && (
      <section
        css={[tw`w-full absolute bottom-6 flex justify-center lg:bottom-4`]}
      >
        {steps?.map(({ position }) => (
          <Icon.Bar
            color={view >= position ? "red-400" : "gray-400"}
            css={[tw`max-h-8 inline-flex w-8`]}
          />
        ))}
      </section>
    )
  )
}

export default ProgressIndicators
