import React, { useReducer } from "react"
import { createContext } from "react"
import { LocalStorageClient } from "../../clients/LocalStorageClient"
import { ConfirmZipContextState } from "./confirmZip.d"
import confirmZipReducer from "./reducer"

const defaultState: ConfirmZipContextState = {
  isZipModalOpen: false,
}

export const ConfirmZipContext = createContext<
  [ConfirmZipContextState, React.Dispatch<any>]
>([defaultState, () => {}])

export const ConfirmZipProvider = ({ children, value }) => {
  const [confirmZipState, dispatch] = useReducer(
    confirmZipReducer,
    defaultState,
  )

  return (
    <ConfirmZipContext.Provider value={[confirmZipState, dispatch]}>
      {children}
    </ConfirmZipContext.Provider>
  )
}
