import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Menu icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Menu: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 26 17" {...remainingProps}>
      <g fill={colorString} fill-rule="evenodd">
        <path d="M.406 0h25.188c.224 0 .406.336.406.75v1.5c0 .414-.182.75-.406.75H.406C.182 3 0 2.664 0 2.25V.75C0 .335.182 0 .406 0ZM.406 7h25.188c.224 0 .406.336.406.75v1.5c0 .414-.182.75-.406.75H.406C.182 10 0 9.664 0 9.25v-1.5C0 7.335.182 7 .406 7ZM.406 14h25.188c.224 0 .406.336.406.75v1.5c0 .414-.182.75-.406.75H.406C.182 17 0 16.664 0 16.25v-1.5c0-.414.182-.75.406-.75Z" />
      </g>
    </svg>
  )
}

export default Menu
