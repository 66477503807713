import { LocalStorageValue } from "./LocalStorageClient.d"

/** Class representing a client to interface with the browser's Local Storage API */
export class LocalStorageClient {
  /**
   * Overwite the current value for key in Local Storage
   *
   * @param {string} key The name of the property to overwrite.
   * @param {LocalStorageValue} value The value to write to local storage
   *
   * @returns {LocalStorageValue} The parsed value written to Local Storage
   */
  public static write(
    key: string,
    value: LocalStorageValue,
  ): LocalStorageValue {
    if (this.isDefined()) {
      const parsedValue =
        typeof value === "string" ? value : JSON.stringify(value)
      localStorage.setItem(key, parsedValue)
      return parsedValue
    }

    return null
  }

  /**
   * Appends the value for a key if it exists, otherwise, create it.
   *
   * @param key The key to append the value of
   * @param {LocalStorageValue} value The value to append
   * @returns {any} The parsed value written to Local Storage
   */
  public static put(key: string, value: Object | any[] | string | number): any {
    if (this.isDefined()) {
      const currentValue = this.read(key)

      if (!currentValue) return this.write(key, value)

      if (Array.isArray(currentValue)) {
        if (Array.isArray(value)) {
          return this.write(key, [...currentValue, ...value])
        }

        return this.write(key, [...currentValue, value])
      }

      if (typeof value === "string") {
        return this.write(key, currentValue + value)
      }

      if (typeof value === "object" && typeof currentValue === "object") {
        return this.write(key, { ...currentValue, ...value })
      }

      throw new Error(
        `Type mismatch! Current value for "${key}" is type ${typeof currentValue}, but passed value is typeof ${typeof value}.`,
      )
    }

    return null
  }

  /**
   * Retrieve the value of "key" in Local Storage
   *
   * @param {string} key The key of the property to retrieve
   * @returns {LocalStorageValue} The value in Local Storage
   */
  public static read(key: string): LocalStorageValue {
    if (this.isDefined()) {
      const value = window.localStorage.getItem(key)
      if (value) {
        return this.isJSON(value) || value
      }
    }

    return null
  }

  /**
   * Deletes part or all of a key from Local Storage
   *
   * @param {string} key The key to delete a value from
   * @param {string} value (optional) The value to delete from the key. If no value provided, entire key is removed.
   * @param {string} keyProp (optional) The prop to use to check the value to delete from the key.
   * @returns {void}
   */
  public static delete(key: string, value?: string, keyProp?: string): void {
    if (!value) {
      localStorage.removeItem(key)
    }

    const currentValue = this.read(key)
    if (!currentValue) return

    let newValue
    if (Array.isArray(currentValue)) {
      if (keyProp) {
        newValue = currentValue.filter(
          currentValue => currentValue[keyProp] !== value,
        )
      } else {
        newValue = currentValue.filter(currentValue => currentValue !== value)
      }
    }

    if (typeof value === "string" && typeof currentValue === "string") {
      newValue = currentValue.replace(value, "")
    }

    if (!newValue || !newValue.length) {
      localStorage.removeItem(key)
    } else {
      this.write(key, newValue)
    }
  }

  /**
   * Check if window and window.localStorage exist.
   *
   * @returns {boolean}
   */
  private static isDefined(): boolean {
    return (
      typeof window !== "undefined" &&
      typeof window.localStorage !== "undefined"
    )
  }

  /**
   * Determine if the passed string value is valid JSON
   *
   * @param {string} value
   * @returns {Object | boolean} If true, the parsed JSON, else false
   */
  private static isJSON(value: string): Object | boolean {
    try {
      return JSON.parse(value)
    } catch (e) {
      return false
    }
  }
}

export { LocalStorageValue }
