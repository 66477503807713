import React, { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FamilySeriesContextProviderProps } from "./familySeries.d"
import _ from "lodash"

/**
 * Family Series context, single source of truth/entry point for family series data. Filtered once then passed to all components utilizing the data.
 * @author Cody
 */

export const FamilySeriesContext = createContext(null)

export const FamilySeriesProvider: React.FC<
  FamilySeriesContextProviderProps
> = ({ children }) => {
  const families = useStaticQuery(FamilySeriesQuery).families.nodes
  const orderedFamilies = _.orderBy(families, ["name"], ["asc"])

  return (
    <FamilySeriesContext.Provider value={[orderedFamilies]}>
      {children}
    </FamilySeriesContext.Provider>
  )
}

const FamilySeriesQuery = graphql`
  query FamilySeriesQuery {
    families: allSanityFamily(filter: { isActive: { eq: true } }) {
      nodes {
        id
        name
        isActive
        routes {
          seriesImage {
            ...SanityImageData
          }
          route {
            slug {
              current
            }
            page {
              ... on SanityPageSeries {
                title
                vehicle {
                  models {
                    ... on SanityVehicleModel {
                      featuredModel
                      model {
                        ... on SanityModel {
                          _id
                          isHybridModel
                          code
                          series {
                            name
                            msrp
                            mpgCity
                            mpgHighway
                          }
                          jellybean {
                            asset {
                              altText
                              _id
                              gatsbyImageData(placeholder: NONE)
                            }
                          }
                        }
                        ... on SanityCustomModel {
                          _id
                          series {
                            name
                            msrp
                            mpgCity
                            mpgHighway
                          }
                          jellybean {
                            asset {
                              altText
                              _id
                              gatsbyImageData(placeholder: NONE)
                            }
                          }
                        }
                      }
                    }
                    ... on SanityVehicleModelGroup {
                      _key
                      _type
                      models {
                        featuredModel
                        model {
                          ... on SanityModel {
                            _id
                            isHybridModel
                            code
                            series {
                              name
                              msrp
                              mpgCity
                              mpgHighway
                            }
                            jellybean {
                              asset {
                                altText
                                _id
                                gatsbyImageData(placeholder: NONE)
                              }
                            }
                          }
                          ... on SanityCustomModel {
                            _id
                            series {
                              name
                              msrp
                              mpgCity
                              mpgHighway
                            }
                            jellybean {
                              asset {
                                altText
                                _id
                                gatsbyImageData(placeholder: NONE)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
