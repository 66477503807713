import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import Icon from "../Icon"
import { ShareButtonProps } from "./ShareButton.d"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

/**
 * Share Button
 *
 * @component
 * @author Dani
 * @summary - Share button that invokes the native sharing mechanism of the device with navigator.share() to share data.
 *
 * @param {string} url - Optional url to share. Default is the canonical url for the current page.
 * @param {title} string - Optional title to describe what you're sharing. Default is the siteName. If sharing via email, this is the subject.
 * @param {text} string - Optional text to describe what you're sharing.
 * @param children
 * @returns <button></button> OR null if the device doesn't support navigator.share()
 */

const ShareButton = ({
  url,
  title,
  text,
  iconStyles,
  children,
  print_coupon_type,
  print_coupon_title,
  index,
  ...remainingProps
}: ShareButtonProps): JSX.Element => {
  const [isSupported, setIsSupported] = useState(true)
  const siteName = "Southeast Toyota Dealers"
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const handleShare = async () => {
    trackTealEvent({
      tealium_event: "share_coupon",
      print_coupon_name: "Share",
      print_coupon_type: print_coupon_type ? `${print_coupon_type}` : "",
      print_coupon_title: print_coupon_title ? `${print_coupon_title}` : "",
    })
    try {
      await navigator.share({
        url: url || window.location.href,
        title: title || siteName,
        text: text || "",
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    // Button will only display if device supports navigator.share()
    if (typeof navigator.share === "undefined") {
      setIsSupported(false)
    }
  }, [])

  if (!isSupported) return null

  return (
    <button
      css={[tw`flex items-center text-sm font-semibold mr-4 -mt-1.5`]}
      onClick={handleShare}
      analytics-id={`share:coupon:${index + 1}`}
      {...remainingProps}
    >
      <Icon.Share color="gray-900" css={[iconStyles]} />
      {children && <span>{children}</span>}
    </button>
  )
}

export default ShareButton
