import { Lead } from "./LeadsClient.d"

export class LeadsClient {
  private static key: string = `${process.env.GATSBY_LEADS_SERVICE_API_KEY}`
  private static url: string = `${process.env.GATSBY_LEADS_SERVICE_API_URL}`

  /**
   * Post simple lead
   *
   * @param {Lead} lead - The lead details that will be sent in the body of the request
   *
   * @returns {boolean} - Boolean indicating if the lead was sent successfully
   */
  public static createLead = async (lead: Lead): Promise<boolean> => {
    const body = {
      LeadSource: "Explore",
      Make: "Toyota",
      ...lead,
    }

    // Ensuring 'GR Supra' is sent as the series name instead of 'Supra'
    if (body.Model?.toLowerCase() === "supra") {
      body.Model = "GR Supra"
    }
    // We're sending the series name as a backup value for the trim in some instances, so ensuring 'GR Supra' is sent instead of 'Supra' here too.
    if (body?.Trim?.toLowerCase() === "supra") {
      body.Trim = "GR Supra"
    }

    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": this.key,
      },
      body: JSON.stringify(body),
    }

    try {
      const res = await fetch(`${this.url}`, config)
      if (res.ok) {
        return true
      }
    } catch (err) {
      console.log("Error while submiting lead:", err.message)
    }
    return false
  }
}
