import React, { useState } from "react"
import tw from "twin.macro"
import { FieldProps } from "."
import { Label } from "../Label"

const field =
  (
    FieldComponent: React.FC<
      React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>
    >,
  ) =>
  ({ name, label, error = false, ...remainingProps }: FieldProps) => {
    const [isActive, setIsActive] = useState(false)

    return (
      <div css={[tw`relative`]} className="form-field">
        {/* Conditionally render only if label value is defined */}
        {label && (
          <Label
            htmlFor={name}
            css={[
              tw`transition-all normal-case`,
              isActive
                ? tw`relative top-0 text-sm text-black font-bold`
                : tw`relative top-4 text-lg text-gray-600 cursor-text font-semibold`,
            ]}
          >
            {error || label}
          </Label>
        )}
        <FieldComponent
          onFocus={() => setIsActive(true)}
          onBlur={e => (e.target.value === "" ? setIsActive(false) : null)}
          id={name}
          css={[
            tw`block w-full border-gray-400 border-b py-1`,
            tw`focus:outline-none` /* Removes blue outline on focus */,
            error && tw`border-red-600 text-red-600`,
          ]}
          {...remainingProps}
        />
        {/* Bottom border animation */}
        <div
          css={[
            tw`invisible block border border-red-400 absolute bottom-0 left-1/2 w-0 h-px transform -translate-x-1/2 transition-all duration-500`,
            isActive && tw`visible w-full z-10`,
          ]}
        ></div>
      </div>
    )
  }

export default field
