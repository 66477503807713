import { useContext } from "react"
import { LocationContext } from "../contexts/Location"

/**
 * Hook provides a function that extracts dealer names and dealer codes from the dealer variable in LocationContext
 * @author Stu
 * @returns {() => DealerInfo} returns an function getDealerInfo, which itself returns an object that contains an array of dealer names, an array of dealer codes, and the number of search results
 */

//

type DealerInfo = {
  dealerNames: string[]
  dealerCodes: string[]
  searchResults: number
}

const useDealers = () => {
  const [{ dealers }] = useContext(LocationContext)

  const getDealerInfo = (): DealerInfo => {
    const dealerNames = dealers?.map(
      dealer => dealer?.Name || "Error with Dealer Name",
    )
    const dealerCodes = dealers?.map(
      dealer => dealer?.DealerCode || "Error with Dealer Code",
    )

    const dealerInfo = {
      dealerNames,
      dealerCodes,
      searchResults: dealers?.length,
    }

    return dealerInfo
  }

  return { getDealerInfo }
}

export default useDealers
