import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * X icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const ZoomIn: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 19 19" {...remainingProps}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4744 12.2937L18.7762 17.6237C19.0859 17.9502 19.0725 18.4661 18.7463 18.776C18.4201 19.086 17.9047 19.0726 17.595 18.7461L12.3273 13.4503C9.20542 15.9834 4.64929 15.6308 1.95368 12.6475C-0.74193 9.66411 -0.635679 5.09183 2.1956 2.23712C5.02687 -0.617591 9.59445 -0.757838 12.5953 1.9178C15.5961 4.59344 15.9822 9.1506 13.4744 12.2937ZM3.31525 11.7743C4.43448 12.8935 5.95335 13.5199 7.53548 13.5149C9.11893 13.5209 10.6393 12.8944 11.7595 11.7743C12.8778 10.6642 13.5069 9.15308 13.5069 7.57668C13.5069 6.00028 12.8778 4.48916 11.7595 3.37907C9.42344 1.05562 5.65132 1.05562 3.31525 3.37907C2.19692 4.48916 1.56787 6.00028 1.56787 7.57668C1.56787 9.15308 2.19692 10.6642 3.31525 11.7743Z"
        fill="#E0E0E0"
      />
      <path
        d="M8 8V11H7V8H4V7H7V4H8V7H11V8H8Z"
        fill="#E0E0E0"
        stroke="#E0E0E0"
      />
    </svg>
  )
}

export default ZoomIn
