import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import { CarouselProps } from "./Carousel.d"
import useInterval from "../../../hooks/useInterval"

/**
 * @component
 * @author Michael Panik
 * @summary
 *
 * @param {CarouselProps} props React Props
 * @param {[]} props.slides slides for iteration
 * @param {boolean} props.autoplay enables the slides to advance at an interval
 *   defaults to false
 * @param {number} props.timeout number of milliseconds until the slide advances
 *   defaults to 4000 ms
 * @returns <ul></ul>
 */

const Carousel: React.FC<CarouselProps> = ({
  slides,
  autoplay = false,
  timeout = 4000,
  children,
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0)

  const changeSlide = (slideId: number): boolean => {
    if (slideId >= slides?.length) {
      setActiveSlide(0)
      return true
    }

    if (slideId < 0) {
      setActiveSlide(slides?.length - 1)
      return true
    }

    if (slideId < slides?.length) {
      setActiveSlide(slideId)
      return true
    }
  }

  // AutoPlay
  // useInterval(() => {
  //   if (!autoplay) return
  //   changeSlide(activeSlide + 1)
  // }, timeout)

  return (
    <ul css={[tw`relative h-full`]}>
      {children({ activeSlide, setActiveSlide, changeSlide, slides, timeout })}
    </ul>
  )
}

export default Carousel
