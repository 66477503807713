import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * HalfStar icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Star: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 18 17" {...remainingProps}>
      <path
        d="M17.93 6.4a1.52 1.52 0 0 0-1.23-1l-4.38-.62-2-3.91A1.52 1.52 0 0 0 9 0a1.52 1.52 0 0 0-1.36.83l-2 3.91-4.34.62a1.5 1.5 0 0 0-.84 2.57l3.17 3-.75 4.28a1.47 1.47 0 0 0 .59 1.46 1.52 1.52 0 0 0 .9.3 1.65 1.65 0 0 0 .71-.17l3.92-2 3.92 2a1.54 1.54 0 0 0 1.61-.13 1.49 1.49 0 0 0 .59-1.46L14.37 11l3.17-3a1.5 1.5 0 0 0 .39-1.6Zm-1.08.8-3.56 3.41.85 4.81a.49.49 0 0 1-.2.47.54.54 0 0 1-.56 0L9 13.67V1a.53.53 0 0 1 .47.28l2.19 4.37 4.9.7a.52.52 0 0 1 .44.35.49.49 0 0 1-.15.5Z"
        fill={colorString}
      />
    </svg>
  )
}

export default Star
