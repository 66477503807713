import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import React, { createContext, useEffect, useReducer } from "react"
import { setDisclaimers, setOpenOnLoad } from "./actions"
import { Disclaimer, DisclaimersContextState } from "./disclaimers.d"
import disclaimersReducer from "./reducers"

const defaultState: DisclaimersContextState = {
  disclaimers: [],
  open: false,
  openOnLoad: [],
  activeDisclaimerPopoverId: null,
}

export const DisclaimersContext = createContext<
  [DisclaimersContextState, React.Dispatch<any>]
>([defaultState, () => {}])

export const DisclaimersProvider = ({ children, value }) => {
  const allGlobalDisclaimers = useStaticQuery(
    GlobalDisclaimersQuery,
  )?.globalDisclaimers
  const globalDisclaimers =
    value?.language === "es"
      ? allGlobalDisclaimers?.globalDisclaimersES
      : allGlobalDisclaimers?.globalDisclaimers

  const [disclaimersState, dispatch] = useReducer(
    disclaimersReducer,
    defaultState,
  )

  useEffect(() => {
    const filteredGlobalDisclaimers = globalDisclaimers?.filter(
      (disclaimer: Disclaimer) => {
        const isDuplicate = value?.disclaimers?.some(
          (pageDisclaimer: Disclaimer) =>
            pageDisclaimer?.code === disclaimer?.code,
        )
        return isDuplicate ? null : disclaimer
      },
    )

    let pageAndGlobalDisclaimers = value?.disclaimers ? value.disclaimers : []

    if (filteredGlobalDisclaimers?.length > 0) {
      pageAndGlobalDisclaimers = [
        ...pageAndGlobalDisclaimers,
        ...filteredGlobalDisclaimers,
      ]
    }
    dispatch(setDisclaimers(pageAndGlobalDisclaimers))

    if (value?.openOnLoad) {
      dispatch(setOpenOnLoad(value.openOnLoad))
    }
  }, [value])

  return (
    <DisclaimersContext.Provider value={[disclaimersState, dispatch]}>
      {children}
    </DisclaimersContext.Provider>
  )
}

const GlobalDisclaimersQuery = graphql`
  query GlobalDisclaimersQuery {
    globalDisclaimers: sanitySiteSettings {
      globalDisclaimers {
        code
        disclaimer
      }
      globalDisclaimersES {
        code
        disclaimer
      }
    }
  }
`
