import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { css } from "@emotion/react"
import { windowExists } from "../../../helpers"

/**
 *
 *
 * Qualtrics Website Feedback API Docs: https://api.qualtrics.com/7023b0cd7acdd-website-feedback-java-script-api
 *
 * @returns Button that triggers the Qualtrics Feedback form modal to open
 */

const FeedbackFormButton: React.FC = () => {
  const qsiScript = `
  (function(){var g=function(e,h,f,g){
    this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
    this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
    this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
    this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
    this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
    try{(new g(100,"r","QSI_S_ZN_bJGFOO5D4KdksnQ","https://znbjgfoo5d4kdksnq-setoyota.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_bJGFOO5D4KdksnQ")).start()}catch(i){}})();
    `

  // Inject script
  useEffect(() => {
    if (!document?.getElementById("qualtrics_feedback_form_script")) {
      let script = document?.createElement("script")
      script.type = "text/javascript"
      script.innerHTML = qsiScript
      script.id = "qualtrics_feedback_form_script"
      document?.head?.appendChild(script)

      let divEl = document.createElement("div")
      divEl.id = "ZN_bJGFOO5D4KdksnQ"
      divEl.innerHTML = "<!--DO NOT REMOVE-CONTENTS PLACED HERE-->"
      document?.body?.appendChild(divEl)
    }
  }, [])

  const handleFeedbackClick = () => {
    if (window?.QSI?.API) {
      window.QSI.API.unload()
      window.QSI.API.load().then(window.QSI.API.run())
    }
  }

  const underlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-0.5 bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `

  return (
    <button
      css={[tw`inline-block relative py-2`, underlineAnimation]}
      onClick={() => handleFeedbackClick()}
    >
      Feedback
    </button>
  )
}

export default FeedbackFormButton
