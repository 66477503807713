import React from "react"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { ThreeArrowAnimationProps } from "./ThreeArrowAnimation.d"

const ThreeArrowAnimation = ({
  selected,
  large,
}: ThreeArrowAnimationProps): JSX.Element => {
  return (
    <>
      <Icon.Chevron
        direction="right"
        color="red-200"
        className="lightestRed"
        css={[
          tw`absolute -right-3 top-3 w-2 translate-x-0 -translate-y-0.5 opacity-0`,
          tw`group-hover:(opacity-100 delay-100 translate-x-0)`,
          tw`group-focus:(opacity-100 delay-100 translate-x-0)`,
          selected && tw`opacity-100`,
          large && tw`font-semibold w-4 -right-6 top-6`,
        ]}
      />
      <Icon.Chevron
        direction="right"
        color="red-300"
        className="lighterRed"
        css={[
          tw`absolute -right-3 top-3 w-2 translate-x-0 -translate-y-0.5 opacity-0`,
          tw`group-hover:(opacity-100 duration-200 translate-x-1.5)`,
          tw`group-focus:(opacity-100 duration-200 translate-x-1.5)`,
          selected && tw`opacity-100 translate-x-1.5`,
          large &&
            tw`font-semibold w-4 -right-6 top-6 group-hover:(translate-x-2) group-focus:(translate-x-2)`,
        ]}
      />
      <Icon.Chevron
        direction="right"
        color="red-400"
        className="darkRed"
        css={[
          tw`absolute -right-3 top-3 w-2 translate-x-0 -translate-y-0.5`,
          tw`group-hover:(opacity-100 duration-300 translate-x-3)`,
          tw`group-focus:(opacity-100 duration-300 translate-x-3)`,
          selected && tw`opacity-100 translate-x-3`,
          large &&
            tw`font-semibold w-4 -right-6 top-6 group-hover:(translate-x-4) group-focus:(translate-x-4)`,
        ]}
      />
    </>
  )
}

export default ThreeArrowAnimation
