import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Info icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const InfoInverted = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 0C9.31375 0 12 2.68625 12 6C12 9.31375 9.31375 12 6 12C2.68625 12 0 9.31375 0 6C0 2.68625 2.68625 0 6 0Z"
        fill="#CED4DA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.473 9H6.49425V4.9195H5.473V9ZM5.55475 3.8025C5.435 3.6815 5.375 3.535 5.375 3.363C5.375 3.191 5.435 3.04575 5.55475 2.9275C5.6745 2.80925 5.8245 2.75 6.004 2.75C6.1785 2.75 6.32525 2.80925 6.44525 2.9275C6.565 3.04575 6.625 3.191 6.625 3.363C6.625 3.535 6.565 3.6815 6.44525 3.8025C6.32525 3.9235 6.1785 3.984 6.004 3.984C5.8245 3.984 5.6745 3.9235 5.55475 3.8025Z"
        fill="#22252B"
        stroke="#22252B"
        stroke-width="0.25"
      />
    </svg>
  )
}

export default InfoInverted
