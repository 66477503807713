import React from "react"
import tw from "twin.macro"
import { LogoProps } from "./Logo.d"

/**
 * Primary Toyota logo
 *
 * @param {boolean} text - display "Toyota" text next to logo icon. Defaults to display text.
 * @param {number} fontSize Can specify font size in px
 * @param {string} width Can specify width in px
 * @param {string} height Can specify height in px
 * @param {any} ..remainingProps Additional props specified in component declaration
 * @returns <div />
 */

const ToyotaLogo: React.FC<LogoProps> = ({ fill }) => {
  return (
    <svg
      width="122"
      height="33"
      viewBox="0 0 122 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill-rule="nonzero" fill="none">
        <title>Toyota</title>
        <path
          d="m112.584 17.122 1.92-4.891 1.92 4.89h-3.84zm3.885-7.673h-3.977l-5.53 13.282h3.427l1.28-3.231h5.623l1.28 3.23H122L116.469 9.45zM88.585 20.442c-2.24 0-4.022-1.974-4.022-4.397s1.828-4.398 4.022-4.398c2.24 0 4.023 1.975 4.023 4.398 0 2.423-1.783 4.397-4.023 4.397m0-11.442c-3.93 0-7.13 3.141-7.13 7s3.2 7 7.13 7c3.931 0 7.131-3.141 7.131-7 .046-3.859-3.2-7-7.13-7M60.38 20.442c-2.24 0-4.022-1.974-4.022-4.397s1.828-4.398 4.022-4.398c2.24 0 4.023 1.975 4.023 4.398 0 2.423-1.783 4.397-4.023 4.397m0-11.442c-3.93 0-7.13 3.141-7.13 7s3.2 7 7.13 7c3.932 0 7.131-3.141 7.131-7s-3.2-7-7.13-7m17.644.449-3.52 5.384-3.52-5.384h-3.382l5.44 8.346v4.936h3.016v-4.936l5.44-8.346h-3.474zm29.712 0H96.173v2.557h4.297v10.725h3.017V12.006h4.251V9.45zM41 12.006h4.297v10.725h3.017V12.006h4.25V9.45H41v2.557z"
          fill={fill ? fill : ""}
        />
        <path fill="#EB0A1E" d="M0 0h32.587v32.587H0z" />
        <path
          d="M16.317 8.158c-6.784 0-12.009 3.438-12.009 8.159 0 4.72 5.225 8.158 12.009 8.158 6.783 0 12.008-3.438 12.008-8.158 0-4.721-5.225-8.159-12.008-8.159zm7.333 3.805c0 1.1-1.742 1.925-4.492 2.245-.275-2.566-1.008-4.262-1.97-4.904 2.979.184 6.462 1.238 6.462 2.659zm-7.333 8.708c-.963 0-1.742-1.513-1.788-4.4.596.046 1.192.091 1.788.091.596 0 1.237-.045 1.787-.091-.046 2.933-.825 4.4-1.787 4.4zm0-6.28c-.596 0-1.146 0-1.696-.045.275-1.742.916-3.163 1.696-3.163.779 0 1.42 1.421 1.695 3.163-.55 0-1.1.046-1.695.046zm-.871-5.087c-.963.642-1.696 2.338-1.971 4.904-2.75-.32-4.492-1.146-4.492-2.245 0-1.421 3.53-2.475 6.463-2.659zM6.37 15.95a5.15 5.15 0 0 1 1.054-3.162c.275 1.558 2.52 2.887 5.958 3.345v.138c0 3.758.871 6.187 2.063 6.966-5.088-.32-9.075-3.483-9.075-7.287zm10.816 7.288c1.192-.78 2.063-3.209 2.063-6.967v-.138c3.438-.504 5.683-1.833 5.958-3.345a5.15 5.15 0 0 1 1.055 3.162c0 3.804-3.988 6.967-9.076 7.288z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default ToyotaLogo
