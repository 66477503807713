import { navigate } from "gatsby"
import React, { useContext } from "react"
import tw from "twin.macro"
import { HeaderContext } from "../../../contexts/Header"
import { SearchContext } from "../../../contexts/Search"
import { SearchQueryProps } from "./SearchQuery.d"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { LanguageContext } from "../../../contexts/Language"

/**
 *
 * @author Stu
 * @summary - Simple text element that triggers a search query.
 * @param {string} props.searchTerm - Value for search term and display
 * @param {string} props.key - Value for "key" attribute when used with .map()
 *
 * @returns <SearchQuery />

 *
 * @todo - Unit tests
 */

const SearchQuery: React.FC<SearchQueryProps> = props => {
  const { index } = props
  const [searchData, dispatch] = useContext(SearchContext)
  const { setToggleMenu, setOpen } = useContext(HeaderContext)
  const { language } = useContext(LanguageContext)
  const handleClick = (query: string) => {
    dispatch({ type: "CLEAR_SEARCH_RESULTS" })
    dispatch({ type: "SET_SEARCH_ENTRY", payload: query })
    dispatch({ type: "SET_SEARCH_QUERY", payload: query })
    setToggleMenu(false)
    setOpen(null)
    navigate(language === "es" ? "/es/search/" : "/search/")
  }
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()

  return (
    <span
      css={[tw`cursor-pointer`, tw`hover:text-red-400`]}
      onClick={() => {
        handleClick(props.searchTerm)
        trackTealEvent({
          tealium_event: "cta_click",
          page_type: tealPageData.page_type,
          link_href: "/search/",
          coupon_module_text: props.searchTerm,
          search_query: props.searchTerm,
          page_name: "search results",
        })
      }}
      key={props.key}
      title={`Search for "${props.searchTerm}"`}
      analytics-id={`search term:sidebar:${index + 1}`}
    >
      {props.searchTerm}
    </span>
  )
}

export default SearchQuery
