import React, { useContext, useEffect, useState } from "react"
import { ContactDealerProps, ContactFormUserFlow } from "./ContactDealer.d"
import { Modal } from "../../atoms/Modal"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import IntroView from "./IntroView"
import ProgressIndicators from "./ProgressIndicators"
import SummaryView from "./SummaryView"
import MethodOfContactView from "./MethodOfContactView"
import SelectVehicleView from "./SelectVehicleView"
import ConfirmZip from "./ConfirmZip"
import DealerLocationView from "./DealerLocationView"
import SelectDealerView from "./SelectDealerView"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { ContactContext } from "../../../contexts/Contact"
import {
  clearState,
  closeFavoritesContactDealerModal,
  setActiveView,
  updateField,
} from "../../../contexts/Contact/actions"
import ScheduleAServiceApptView from "./ScheduleAServiceApptView"
import { LocationContext } from "../../../contexts/Location"
import NameView from "./NameView"
import NameValidationView from "./NameValidationView"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick, tealiumSubmit } from "./TealiumHelpers"
import { reformattedContactView } from "../../../helpers"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import CheckAvailabilityMethod from "./CheckAvailabilityMethod"
import OptionalCommentsView from "./OptionalCommentsView"
import useVehicleData from "../../../hooks/Tealium/useVehicleData"
import ShareFavoritesView from "./ShareFavoritesView"
import SelectFavoriteVehiclesView from "./SelectFavoriteVehiclesView"
import { InventoryClient } from "../../../clients/InventoryClient"
import SelectFavoriteSeriesView from "./ShareFavoriteSeries"

const FavoritesContactDealer: React.FC<ContactDealerProps> = ({
  modalIsOpen,
  setModalIsOpen,
}) => {
  const [{ zip }] = useContext(LocationContext)
  const { language, _ } = useContext(LanguageContext)
  const [submitting, setSubmitting] = useState(false)
  const [sentSuccess, setSentSuccess] = useState(null)
  const [{ favoritedDealer, favoritedVehicles }] = useContext(FavoritesContext)
  const [
    {
      dealer,
      activeView,
      seriesName,
      trim,
      vehicle,
      gRecaptchaResponse,
      contactType,
      comments,
      leadType,
      contact,
      firstName,
      lastName,
    },
    dispatch,
  ] = useContext(ContactContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const {
    contactDealerData,
    updateContactDealerData,
    deleteVehicleTealData,
    tealPageData,
    lifestyleLanderTealData,
  } = useTealiumContext()
  const [vehicleData] = useVehicleData()

  // POST to leads API
  const onSubmit = async () => {
    setSubmitting(true)
    try {
      const url = `${process.env.GATSBY_LEADS_SERVICE_API_URL}`
      const body = {
        LeadType: leadType,
        Comments: comments,
        FirstName: firstName,
        LastName: lastName,
        LeadSource: "Explore",
        LanguageCode: language,
        DealerName: dealer.Name,
        DealerCode: dealer.DealerCode,
        Make: "Toyota",
        Model: seriesName || "",
        Trim: trim || "",
        ExteriorColor: vehicle?.color?.exterior?.name || "",
        InteriorColor: vehicle?.color?.interior?.name || "",
        Price: vehicle?.msrp || "",
        Year: vehicle?.year || "",
        contact_options: contactType,
        Email: contactType === "email" ? contact : "",
        Phone: contactType === "phone" ? contact : "",
        "g-recaptcha-response": gRecaptchaResponse,
      }
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-api-key": process.env.GATSBY_LEADS_SERVICE_API_KEY,
        },
        body: JSON.stringify(body),
      }
      const response = await fetch(url, config)

      if (response.ok) {
        setSubmitting(false)
        setSentSuccess(true)
        tealiumSubmit(
          "thank_you_page",
          "Review & Submit Your Request",
          contact,
          trackTealEvent,
          contactDealerData,
          contactDealerData?.dealerData,
          {
            ...(tealPageData.page_type == "lifestyle" && {
              vehicle_category: lifestyleLanderTealData.selectedCategories,
            }),
          },
        )
      }
    } catch (error) {
      setSubmitting(false)
      setSentSuccess(false)
      console.error("Error submitting form: ", error)
      tealiumSubmit(
        "error_page",
        "Review & Submit Your Request",
        contact,
        trackTealEvent,
        contactDealerData,
        contactDealerData?.dealerData,
        {
          ...(tealPageData.page_type == "lifestyle" && {
            vehicle_category: lifestyleLanderTealData.selectedCategories,
          }),
        },
        "An unexpected error has occurred. Please try again.",
      )
    }
  }

  // Setting first form view based on favorited dealer if preselected.
  useEffect(() => {
    const zipIsConfirmed = LocalStorageClient.read("confirmedZip")

    if (favoritedDealer) {
      dispatch(updateField("dealer", favoritedDealer))
      dispatch(setActiveView("ShareFavoritesView"))
    } else if (zip && zipIsConfirmed) {
      dispatch(setActiveView("SelectDealerView"))
      // } else if (ShareFavoritesView) {
      //   dispatch(setActiveView("OptionalCommentsView"))
    } else {
      dispatch(setActiveView("ConfirmZip"))
    }

    // Tealium
    if (favoritedDealer) {
      updateContactDealerData({
        dealerData: {
          dealer_name: favoritedDealer?.Name,
          dealer_code: favoritedDealer?.DealerCode,
        },
        fromInventory: null,
      })
    }
    // Don't send vehicle info if on inventory page
    deleteVehicleTealData()
  }, [modalIsOpen])

  // Getting favorite vehicles using favorited vins
  useEffect(() => {
    const getVehicleData = async () => {
      const data = await InventoryClient.get({
        vin: favoritedVehicles.join(","),
      })
      dispatch(updateField("favoriteVehicles", data?.vehicles))
    }
    if (
      modalIsOpen &&
      Array.isArray(favoritedVehicles) &&
      favoritedVehicles?.length > 0
    ) {
      getVehicleData()
    }
  }, [modalIsOpen])

  // Steps for Progress Indicator
  enum Steps {
    IntroView = 1,
    ConfirmZip = null,
    DealerLocationView = null,
    SelectDealerView = null,
    ShareFavoritesView = 1,
    SelectFavoriteVehiclesView = 2,
    SelectVehicleView = 2,
    NameView = 3,
    NameValidationView = 3,
    MethodOfContactView = 3,
    OptionalCommentsView = 4,
    SummaryView = 5,
  }

  // Data from GraphQL query (bottom of page)
  const data = useStaticQuery(Query)

  // Series and model data for VehicleSelectionView
  const seriesData = data?.series?.nodes
  const modelsData = data?.models?.group

  const resetForm = () => {
    setSentSuccess(null)
    dispatch(clearState())
    setModalIsOpen(false)
    setSubmitting(false)
    updateContactDealerData({
      fromInventory: null,
      fromSelection: null,
      dealerData: null,
    })
  }

  const contactInputDetails = [
    {
      label: _("Email"),
      value: "email",
      type: "Email Address",
      input: "email",
      regEx: new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
      validationMessage: _("Please enter a valid email address."),
    },
    {
      label: _("Phone"),
      value: "phone",
      type: "Phone Number",
      input: "tel",
      regEx: new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/),
      validationMessage: _("Please enter a valid phone number."),
    },
  ]

  return (
    <Modal
      css={[
        tw`w-full h-full max-h-full bg-gray-50`,
        tw`lg:(max-w-[50rem] max-h-[475px])`,
        "z-index: 9999;",
      ]}
      open={modalIsOpen}
      onClose={() => {
        resetForm()
        tealiumNavClick(
          activeView === "SelectDealerView"
            ? "Select a Dealership to Continue"
            : "SelectVehicleView"
              ? "Were you interested in a specific vehicle?"
              : "What can we do for you?",
          "Close",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      }}
      analyticsId={`X:contact form:${reformattedContactView(activeView)}`}
    >
      <form css={[tw`h-full w-full p-6 pb-12`]} id="contactDealer">
        <section
          css={[
            tw`relative flex flex-col justify-center items-center h-full w-full`,
          ]}
        >
          <ConfirmZip active={activeView === "ConfirmZip"} />
          <DealerLocationView active={activeView === "DealerLocationView"} />
          <IntroView active={activeView === "IntroView"} />
          <SelectDealerView
            active={activeView === "SelectDealerView"}
            nextView={"ShareFavoritesView"}
          />
          <ShareFavoritesView active={activeView === "ShareFavoritesView"} />
          <SelectFavoriteSeriesView
            active={activeView === "SelectFavoriteSeriesView"}
          />
          <SelectFavoriteVehiclesView
            active={activeView === "SelectFavoriteVehiclesView"}
          />
          <CheckAvailabilityMethod
            active={activeView === "CheckAvailabilityMethod"}
            closeModal={() => {
              resetForm()
              setModalIsOpen(false)
            }}
          />
          <SelectVehicleView
            active={activeView === "SelectVehicleView"}
            seriesData={seriesData}
            modelsData={modelsData}
            prevView="ShareFavoritesView"
          />
          <NameView
            active={activeView === "NameView"}
            contactInputs={contactInputDetails}
            prevView="SelectFavoriteVehiclesView"
            nextPageOverride="OptionalCommentsView"
          />
          <NameValidationView active={activeView === "NameValidationView"} />
          <MethodOfContactView
            active={activeView === "MethodOfContactView"}
            contactInputs={contactInputDetails}
          />
          <OptionalCommentsView
            active={activeView === "OptionalCommentsView"}
          />
          <SummaryView
            active={activeView === "SummaryView"}
            submitting={submitting}
            sentSuccess={sentSuccess}
            setSentSuccess={setSentSuccess}
            setModalIsOpen={setModalIsOpen}
            reset={resetForm}
            onSubmit={onSubmit}
          />
        </section>
      </form>

      {/* Progress indicators and buttons */}
      {Steps[activeView] && (
        <ProgressIndicators view={Steps[activeView]} totalSteps={5} />
      )}
    </Modal>
  )
}

const Query = graphql`
  {
    series: allSanitySeries {
      nodes {
        name
        currentYear
      }
    }
    models: allSanityModel {
      group(field: { series: { name: SELECT } }) {
        fieldValue
        nodes {
          name
          year
        }
      }
    }
  }
`

export default FavoritesContactDealer
