import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Help icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Help = ({ color, filled, ...remainingProps }: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-2 -2 76 76" {...remainingProps}>
      <g stroke={colorString} fill="none" fill-rule="evenodd">
        <circle stroke-width="4" cx="36" cy="36" r="36" />
        <g fill={colorString}>
          <path d="M34.816 44.115c.27-.757.768-1.66 1.496-2.69.726-1.03 1.964-2.57 3.707-4.527 1.7-1.915 2.92-3.473 3.664-4.76.743-1.285 1.113-2.831 1.113-4.658 0-2.392-.74-4.423-2.23-6.01-1.489-1.58-3.667-2.483-6.535-2.486-2.661 0-4.777.826-6.35 2.307-1.573 1.487-2.58 4.576-3.033 6.716A66.991 66.991 0 0 0 24 27.323c.45-2.684 1.76-6.328 3.946-8.29 2.186-1.955 4.906-3.036 8.147-3.033 2.453.004 4.582.62 6.38 1.746a11.419 11.419 0 0 1 4.113 4.45A12.367 12.367 0 0 1 48 27.976c0 2.357-.48 4.304-1.444 5.878-.967 1.576-2.376 3.255-4.238 5.129-1.945 1.911-3.213 3.455-3.794 4.503-.582 1.052-.894 2.153-.935 3.288a60.805 60.805 0 0 0-3.178 0c0-1.014.134-1.9.405-2.658M34.078 55.24a2.5 2.5 0 0 1-.775-1.858c0-.772.258-1.41.775-1.918.515-.508 1.147-.762 1.891-.762s1.385.254 1.923.762c.536.508.805 1.146.805 1.918a2.46 2.46 0 0 1-.805 1.857A2.71 2.71 0 0 1 35.97 56c-.744 0-1.376-.254-1.891-.76" />
        </g>
      </g>
    </svg>
  )
}

export default Help
