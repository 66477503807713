import React, {
  ReactChild,
  ReactChildren,
  useRef,
  useEffect,
  KeyboardEvent,
} from "react"
import { PopoverProps, POPOVER_ORIGINS, ORIGIN_X, ORIGIN_Y } from "./Popover.d"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"

/**
 * @component
 * @author Matt Carstensen
 * @summary Popover
 *
 * @param   {PopoverProps} props React Props
 * @param   {boolean} props.open indicates whether this component is open
 * @param   {POPOVER_ORIGINS} props.origin which corner of the parent element does the Popover Gravitate to
 * @param   {ReactChild | ReactChildren} props.children components contained within
 * @param   {Object} props.remainingProps number of milliseconds until the slide advances
 * @return  {JSX.Element} <div></div>
 */

const Popover: React.FC<PopoverProps> = ({
  open = false,
  origin = POPOVER_ORIGINS.BOTTOM_LEFT,
  onClose,
  children,
  closeButtonStyle = null,
  closeButtonColor = null,
  ...remainingProps
}: PopoverProps): JSX.Element => {
  const popoverRef = useRef(null)
  const [originY, originX] = origin.split("-")
  // useOnClickOutside(popoverRef, onClose)
  const focusableElements =
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
  let firstFocusElement: HTMLElement
  let lastFocusElement: HTMLElement

  const handleKeyDown: any = (
    e: KeyboardEvent<HTMLElement>,
    firstFocusElement: HTMLElement,
    lastFocusElement: HTMLElement,
  ) => {
    if (e.key === "Tab" && document.activeElement === lastFocusElement) {
      firstFocusElement.focus()
      e.preventDefault()
    }
    if (
      e.shiftKey &&
      e.key === "Tab" &&
      document.activeElement === firstFocusElement
    ) {
      lastFocusElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    // Trap focus within modal when modal is open
    if (open && popoverRef.current) {
      const popoverFocusElements =
        popoverRef.current.querySelectorAll(focusableElements)
      firstFocusElement = popoverFocusElements[0] as HTMLElement
      lastFocusElement = popoverFocusElements[
        popoverFocusElements.length - 1
      ] as HTMLElement
      popoverRef.current.addEventListener("keydown", (e: any) =>
        handleKeyDown(e, firstFocusElement, lastFocusElement),
      )
    }

    // // Focus within popover when opened. TabIndex must be set to -1
    // // https://dev.to/westbrookc16/managing-focus-in-react-3n13
    if (typeof window !== "undefined" && open) {
      popoverRef.current.focus()
    }

    return () =>
      popoverRef.current?.removeEventListener("keydown", (e: any) =>
        handleKeyDown(e, firstFocusElement, lastFocusElement),
      )
  }, [open, popoverRef])

  return (
    <>
      {open && (
        <div
          ref={popoverRef}
          aria-label={"dialog box"}
          role="dialog"
          aria-labelledby="title"
          aria-describedby="description"
          css={[
            tw`bg-white rounded-lg rounded-tr-none shadow-5 z-[999]`,
            tw`absolute pointer-events-none mt-[-100%] opacity-0 transition-all duration-500 ease-in-out transform`,

            // Y AXIS Origin
            originY === ORIGIN_Y.BOTTOM && `top: 100%;`,
            originY === ORIGIN_Y.TOP && `bottom: 100%`,
            // X Axis Origin
            originX === ORIGIN_X.LEFT && tw`left-0`,
            originX === ORIGIN_X.RIGHT && tw`right-0`,
            // Remove Rounded Corner from Origin
            origin === POPOVER_ORIGINS.BOTTOM_LEFT &&
              tw`rounded-tl-none origin-top-left`,
            origin === POPOVER_ORIGINS.BOTTOM_RIGHT &&
              tw`rounded-tr-none origin-top-right`,
            origin === POPOVER_ORIGINS.TOP_LEFT &&
              tw`rounded-bl-none origin-bottom-left`,
            origin === POPOVER_ORIGINS.TOP_RIGHT &&
              tw`rounded-br-none origin-bottom-right`,

            // If open
            open && tw`mt-0 opacity-100 pointer-events-auto`,
          ]}
          {...remainingProps}
        >
          <button
            onClick={onClose}
            aria-label="Close"
            css={[
              tw`absolute top-4 right-8 text-gray-800 p-2 border border-transparent`,
              tw`focus-visible:(border-gray-700 border-dashed border outline-none p-2)`,
              `z-index: 99999;`,
              closeButtonStyle,
            ]}
          >
            <Icon.X color={closeButtonColor ?? "gray-800"} css={[tw`w-4`]} />
          </button>
          {children}
        </div>
      )}
    </>
  )
}

export default Popover
