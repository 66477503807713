import { useRef } from "react"

let uniqueId = 0
const getUniqueId = () => uniqueId++

export default function useComponentId() {
  const idRef = useRef<number>()
  if (idRef.current === undefined) {
    idRef.current = getUniqueId()
  }
  return idRef.current
}
