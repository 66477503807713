import { useTealiumContext } from "../../contexts/Tealium"

/**
 * @author Stu
 * @summary retrieves any vehicle-specific data that has been set to TealiumContext and returns it for use in any Tealium calls
 *
 * @returns {array} - contains an object with vehicle-specific data from context, with only the required properties included (some extra properties may be included in the object for use in specific cases)
 */

const useVehicleData = () => {
  const { vehicleTealData } = useTealiumContext()

  return [extractVehicleValues(vehicleTealData)]
}

export default useVehicleData

/**
 * @author Stu
 * @summary Extracts certain vehicle data from the general vehicle object (i.e. data may be added that is not suitable for tealium event calls).
 *
 * @param {Object} vehicleData  - object of values associated with vehicles
 * @returns {Object} A new object containing only values that are valid for Tealium calls that use vehicle data.
 */
const extractVehicleValues = (vehicleData: {
  vehicle_exterior_color?: string
  vehicle_interior_color?: string
  vehicle_model?: string
  vehicle_model_trim?: any
  vehicle_model_truncated?: string
  vehicle_segment_vehicle_page?: string
  vehicle_trim?: string
  vehicle_trim_id?: string
  vehicle_vin?: string
  vehicle_year?: string | number
}) => {
  const newVehicleObj = {
    ...(vehicleData?.vehicle_exterior_color && {
      vehicle_exterior_color: vehicleData?.vehicle_exterior_color,
    }),
    ...(vehicleData?.vehicle_interior_color && {
      vehicle_interior_color: vehicleData?.vehicle_interior_color,
    }),
    ...(vehicleData?.vehicle_model && {
      vehicle_model: vehicleData?.vehicle_model,
    }),
    ...(vehicleData?.vehicle_model_trim && {
      vehicle_model_trim: vehicleData?.vehicle_model_trim,
    }),
    ...(vehicleData?.vehicle_model_truncated && {
      vehicle_model_truncated: vehicleData?.vehicle_model_truncated,
    }),
    ...(vehicleData?.vehicle_segment_vehicle_page && {
      vehicle_segment_vehicle_page: vehicleData?.vehicle_segment_vehicle_page,
    }),
    ...(vehicleData?.vehicle_trim && {
      vehicle_trim: vehicleData?.vehicle_trim,
    }),
    ...(vehicleData?.vehicle_trim_id && {
      vehicle_trim_id: vehicleData?.vehicle_trim_id,
    }),
    ...(vehicleData?.vehicle_vin && {
      vehicle_vin: vehicleData?.vehicle_vin,
    }),
    ...(vehicleData?.vehicle_year && {
      vehicle_year: vehicleData?.vehicle_year,
    }),
  }
  return newVehicleObj
}
