import React from "react"
import field from "../field/field"
import { InputProps } from "./Input.d"

const Input: React.FC<InputProps> = ({ ...remainingProps }) => {
  return (
    <input
      {...remainingProps}
      css={[
        /* Removes blue background color from chrome autocomplete */
        `
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:focus-visible,
          &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
        `,
      ]}
    />
  )
}

export default field(Input)
