import React, { useRef } from "react"
import tw from "twin.macro"
import Button from "../../atoms/Button/Button"
import { StyledComponent } from "@emotion/styled"
import { IButtonProps } from "../../atoms/Button"
import { RadioButtonProps } from "./ContactDealer.d"
import useComponentId from "../../../hooks/useIdGenerator"

export const ButtonDiv: StyledComponent<IButtonProps> =
  Button.withComponent("div")

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  name,
  handleChange,
  ...remainingProps
}) => {
  const inputRef = useRef()
  const uuid = useComponentId()
  return (
    <label
      htmlFor={`${label}-${uuid}`}
      css={[tw`text-black cursor-pointer p-4`]}
      {...remainingProps}
    >
      <input
        type="radio"
        id={`${label}-${uuid}`}
        css={tw`appearance-none`}
        value={value}
        name={name}
        ref={inputRef}
        onChange={e => handleChange(e.target.value)}
      />
      <ButtonDiv
        negative
        css={[
          tw`text-black cursor-pointer`,
          inputRef?.current?.checked && tw`bg-red-400 text-white`,
        ]}
      >
        {label}
      </ButtonDiv>
    </label>
  )
}

export default RadioButton
