import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * EvPluginInHybrid icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const EvPluginInHybrid = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg fill="none" viewBox="0 0 36 36" {...remainingProps}>
      <mask
        id="a"
        width="36"
        height="36"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path fill="#fff" d="M36 0H0v36h36V0Z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill={colorString}
          d="M34.313 18c0-8.995-7.319-16.313-16.313-16.313C9.005 1.688 1.687 9.005 1.687 18c0 8.994 7.318 16.313 16.313 16.313v-1.688c-8.064 0-14.625-6.56-14.625-14.625C3.375 9.936 9.936 3.375 18 3.375c7.781 0 14.163 6.108 14.6 13.781h-3.112c-.424-4.257-4.026-7.593-8.394-7.593h-8.438a3.097 3.097 0 0 0-3.094 3.093 3.097 3.097 0 0 0 3.094 3.094h4.5v4.5h-4.5a3.097 3.097 0 0 0-3.094 3.094 3.097 3.097 0 0 0 3.094 3.093h8.438c4.368 0 7.97-3.335 8.394-7.593h4.825V18Zm-21.657-3.938a1.408 1.408 0 0 1-1.406-1.406c0-.775.631-1.406 1.406-1.406h4.5v2.813h-4.5Zm0 10.688a1.407 1.407 0 0 1 0-2.813h4.5v2.813h-4.5Zm8.438 0h-2.25v-13.5h2.25a6.758 6.758 0 0 1 6.75 6.75 6.758 6.758 0 0 1-6.75 6.75Z"
        />
      </g>
    </svg>
  )
}

export default EvPluginInHybrid
