import { Disclaimer, DisclaimersContextState } from "./disclaimers.d"

import { ACTION_TYPES } from "./actions"

const disclaimersReducer = (
  state: DisclaimersContextState,
  action: { type: ACTION_TYPES; payload?: any },
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_DISCLAIMERS:
      return {
        ...state,
        disclaimers: action.payload,
      }
    case ACTION_TYPES.TOGGLE_MODAL:
      const newDisclaimers =
        action.payload &&
        state.disclaimers.map(disclaimer => ({
          ...disclaimer,
          active: disclaimer.code === action.payload,
        }))

      return {
        ...state,
        open: !state.open,
        disclaimers: newDisclaimers || state.disclaimers,
      }
    case ACTION_TYPES.SET_ACTIVE_DISCLAIMER:
      return {
        ...state,
        disclaimers: state.disclaimers.map(disclaimer => {
          disclaimer.active = action.payload === disclaimer.code
          return disclaimer
        }),
      }
    case ACTION_TYPES.SET_OPEN_ON_LOAD:
      return {
        ...state,
        openOnLoad: [...action.payload],
      }
    case ACTION_TYPES.SET_ACTIVE_DISCLAIMER_POPOVER_ID:
      return {
        ...state,
        activeDisclaimerPopoverId: action.payload,
      }
    default:
      return state
  }
}

export default disclaimersReducer
