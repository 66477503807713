import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { IImageProps } from "./Image.d"
import SanityImage from "gatsby-plugin-sanity-image"

/**
 * A generic image component to simplify use of multiple image formats
 *
 * @component
 * @param {IImageProps} props
 * @param {string|IGatsbyImageData} props.src - Either a string for static image or Gatsby Image Data object for dynamic images
 * @param {string} props.alt - Alt text for the image.
 *
 * @returns <img />
 */
const Image: React.FC<IImageProps> = ({
  src,
  imageData,
  alt,
  style,
  imgStyle,
  width,
  height,
  noPreview,
  solidColorPreview,
  transparentPreview,
  hideAlt = false,
  overrideAlt = null,
  // fit,
  ...remainingProps
}) => {
  // If noPreview or solidColorPreview is true, this will pass a null value to the preview
  // attribute that gatsby-plugin-sanity-image uses under the hood
  const noPreviewFlag: null | Object =
    noPreview || solidColorPreview || transparentPreview
      ? { preview: null }
      : null

  // Sanity image with asset, hotspot, and crop data
  if (imageData && !src) {
    // Prevent runtime errors if no image data is passed
    if (!imageData.asset) return null

    let altText = imageData?.asset?.altText

    if (hideAlt) {
      altText = ""
    } else if (overrideAlt) {
      altText = overrideAlt
    }

    // set alt to override
    // boolean
    return (
      <SanityImage
        {...imageData}
        {...remainingProps}
        {...noPreviewFlag}
        width={width}
        height={height}
        alt={altText}
        css={[
          solidColorPreview &&
            `background: ${imageData?.asset?.metadata?.palette?.dominant?.background};`,
          transparentPreview && `background: rgba(0, 0, 0, 0);`,
        ]}
      />
    )
  }

  // Static image
  if (typeof src === "string")
    return <img src={src} alt={alt} {...remainingProps} loading="lazy" />

  // GatsbyImage
  return (
    <GatsbyImage
      image={src}
      alt={alt}
      style={style}
      imgStyle={imgStyle}
      {...remainingProps}
    />
  )
}

export default Image
