import React, { useContext } from "react"
import tw from "twin.macro"
import { ModalProps } from "./Modal.d"
import { CloseBlock, X } from "../Icon"
import { LanguageContext } from "../../../contexts/Language"

/**
 * Close button that closes modal on click
 *
 * @param {boolean} props.open - Modal open or close state
 * @param {boolean} props.closeStyled - Style the close button with a red background
 * @param {ReactNode} props.children - Content inside the modal
 * @param {function} props.onClose - Close button onClick function
 *
 * @returns {JSX.Element} <button></button>
 */

const Close: React.FC<ModalProps> = ({
  open,
  closeStyled,
  onClose,
  hideCancelText,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)
  const isSafari =
    navigator.userAgent.includes("Safari/") &&
    !navigator.userAgent.includes("Chrome/" || "Chromium/")
  return (
    <>
      {closeStyled ? (
        <div
          css={[
            tw`sticky -mt-1 right-6 z-[99999] h-[9vh] w-full border-b-gray-200 border-b bg-white flex justify-end`,
            isSafari ? tw`bottom-[100%]` : tw`bottom-[93vh] lg:bottom-[80vh] `,
          ]}
        >
          <button
            aria-label="close"
            onClick={onClose}
            css={[
              tw` opacity-100 pr-6 flex gap-2 items-center hover:opacity-100`,
              tw`focus-visible:(outline-gray-50)`,
            ]}
            id="modalCloseBtn"
            {...remainingProps}
          >
            <div css={[tw`p-2 bg-red-500 rounded-lg hover:bg-toyotaRed`]}>
              <X color={"white"} css={[tw`w-3`]} />
            </div>
            {hideCancelText ? (
              <span css={[tw`uppercase text-sm font-semibold`]}>
                {_("Close")}
              </span>
            ) : (
              <span css={[tw`uppercase text-sm font-semibold`]}>
                {_("Cancel")}
              </span>
            )}
          </button>
        </div>
      ) : (
        <button
          aria-label="close"
          onClick={onClose}
          css={[
            tw`absolute right-6 top-6 flex gap-2 items-center opacity-100`,
            tw`focus-visible:(outline-gray-50)`,
          ]}
          id="modalCloseBtn"
          {...remainingProps}
        >
          <div css={[tw`flex justify-center`]}>
            <CloseBlock color={"red-400"} css={[tw`w-6 inline-flex mr-2`]} />
            {hideCancelText ? (
              <span css={[tw`uppercase text-sm font-semibold`]}>
                {_("Close")}
              </span>
            ) : (
              <span css={[tw`uppercase text-sm font-semibold`]}>
                {_("Cancel")}
              </span>
            )}
          </div>
        </button>
      )}
    </>
  )
}

export default Close
