import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * ArrowBlock icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const ArrowBlock = ({
  color,
  direction = "right",
  ...remainingProps
}: IconProps): JSX.Element => {
  let rotation = 0
  switch (direction) {
    case "left":
      rotation = 180
      break
    case "down":
      rotation = 90
      break
    case "up":
      rotation = -90
      break
    default:
      break
  }
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps} css={[direction]}>
      <g
        fill="none"
        fill-rule="evenodd"
        transform={`rotate(${rotation}, ${72 / 2}, ${72 / 2})`}
      >
        <rect
          fill={colorString}
          fill-rule="nonzero"
          width="72"
          height="72"
          rx="10"
        />
        <path
          stroke="#FFF"
          stroke-width="5"
          stroke-linejoin="round"
          d="m28 21 17 15-17 15"
        />
      </g>
    </svg>
  )
}

export default ArrowBlock
