import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Directions icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Directions = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 -1 72 74" {...remainingProps}>
      <g stroke={colorString} fill="none" fill-rule="evenodd">
        <path
          d="M36 0C18.87 0 6.31 18.46 14.636 33.476L36 72l21.364-38.524C65.689 18.46 53.13 0 36 0m0 2.88c7.467 0 14.507 4.094 18.372 10.686 3.51 5.986 3.685 12.734.482 18.51L36 66.074 17.146 32.076c-3.203-5.776-3.028-12.524.482-18.51C21.493 6.974 28.533 2.88 36 2.88"
          fill="#C00"
        />
        <path
          d="M36 12.48c-5.542 0-10.05 4.523-10.05 10.08 0 5.558 4.508 10.08 10.05 10.08 5.542 0 10.05-4.522 10.05-10.08 0-5.557-4.508-10.08-10.05-10.08"
          stroke-width="4"
        />
      </g>
    </svg>
  )
}

export default Directions
