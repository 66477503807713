import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Pinterest icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Pinterest: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 20 20" {...remainingProps}>
      <title>Follow us on Pinterest</title>
      <path
        d="M10 0C4.477 0 0 4.477 0 10a9.997 9.997 0 0 0 5.986 9.159c-.028-.7-.005-1.538.173-2.296l1.287-5.45s-.32-.639-.32-1.581c0-1.481.86-2.589 1.93-2.589.909 0 1.348.682 1.348 1.5 0 .915-.584 2.283-.883 3.55-.25 1.06.533 1.924 1.577 1.924 1.895 0 3.17-2.432 3.17-5.316 0-2.19-1.475-3.83-4.16-3.83-3.033 0-4.922 2.26-4.922 4.787 0 .872.257 1.486.659 1.962.186.219.211.306.143.557-.046.185-.156.627-.202.802-.067.255-.272.345-.501.25-1.397-.569-2.047-2.099-2.047-3.82 0-2.84 2.394-6.246 7.147-6.246 3.817 0 6.331 2.763 6.331 5.728 0 3.923-2.182 6.854-5.397 6.854-1.08 0-2.096-.584-2.442-1.246 0 0-.581 2.305-.703 2.749-.213.77-.628 1.541-1.007 2.142.898.266 1.848.41 2.833.41 5.522 0 9.999-4.476 9.999-10 0-5.523-4.477-10-10-10Z"
        fill={colorString}
        fill-rule="nonzero"
      />
    </svg>
  )
}

export default Pinterest
