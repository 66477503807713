import React from "react"
/**
 * Executes a Callback at a specified interval
 *
 * @param {Function} callback A Function to be executed.
 *    Stored in a ref in order to handle changes in execution,
 *    such as an updated parameters
 * @param {number} delay the duraction between callback executions.
 * @return { React.Ref } a ref to the function callback to the set interval.
 */
const useInterval = (
  callback: (any) => void,
  delay: number = 1000,
  isHovered: boolean,
) => {
  const intervalId = React.useRef(null)
  const savedCallback = React.useRef<Function>(callback)
  React.useEffect(() => {
    savedCallback.current = callback
  })
  React.useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === "number") {
      intervalId.current = isHovered
        ? intervalId.current
        : window.setInterval(tick, delay)
      return () => window.clearInterval(intervalId.current)
    }
  }, [delay, isHovered])
  return intervalId.current
}

export default useInterval
