import React, { useContext, useEffect, useState } from "react"
import FormPage from "./FormPage"
import { ViewProps } from "./ContactDealer.d"
import { LanguageContext } from "../../../contexts/Language"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView } from "../../../contexts/Contact/actions"
import { Button } from "../../atoms/Button"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const ShareFavoritesView: React.FC<ViewProps> = ({ active }) => {
  const [{}, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  const [isInitialView, setIsInitialView] = useState(true)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  useEffect(() => {
    if (active && isInitialView) {
      trackTealEvent({
        tealium_event: "contact_dealer",
        contact_question: "Would you like to share favorited vehicles?",
        ...contactDealerData?.dealerData,
      })
      setIsInitialView(false)
    }
  }, [active])

  if (!active) return null

  return (
    <FormPage
      active={active}
      heading={_("Would you like to share your favorited vehicles?")}
    >
      <div css={tw`grid grid-cols-2	gap-4 mt-10`}>
        <Button
          negative
          onClick={() => {
            dispatch(setActiveView("SelectFavoriteSeriesView"))
            trackTealEvent({
              tealium_event: "contact_form_nav",
              contact_question:
                "Would you like to share your favorited vehicles?",
              dropdown_selected: "Yes",
            })
          }}
          disabled={!active}
          css={[tw`text-black normal-case px-12 md:px-14`]}
          analytics-id="Yes:contact form: Would you like to share your favorited vehicles?"
        >
          {_("Yes")}
        </Button>

        <Button
          negative
          onClick={() => {
            dispatch(setActiveView("SelectVehicleView"))
            trackTealEvent({
              tealium_event: "contact_form_nav",
              contact_question:
                "Would you like to share your favorited vehicles?",
              dropdown_selected: "No",
            })
          }}
          disabled={!active}
          css={[tw`text-black normal-case px-12 md:px-14`]}
          analytics-id="No:contact form: Would you like to share your favorited vehicles?"
        >
          {_("No")}
        </Button>
      </div>
    </FormPage>
  )
}

export default ShareFavoritesView
