import { useContext } from "react"
import { Button } from "../../atoms/Button"
import { ContactContext } from "../../../contexts/Contact"
import tw from "twin.macro"
import { setActiveView } from "../../../contexts/Contact/actions"
import Icon from "../../atoms/Icon"
import { FormView } from "../../../contexts/Contact/contact.d"
import { LanguageContext } from "../../../contexts/Language"

type Props = {
  resetForm: () => void
  lastActiveView: FormView
}

const CancelConfirmation = ({ resetForm, lastActiveView }: Props) => {
  const [{ dealer }, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  return (
    <div
      css={[
        tw`bg-white z-[99999] w-full p-6 flex flex-col items-center justify-center h-full absolute top-0 bottom-0 right-0 left-0 rounded`,
      ]}
    >
      <h3
        css={[tw`text-3xl mb-6 block normal-case text-center lg:(text-left)`]}
      >
        {_("Are you sure you want to cancel your request?")}
      </h3>
      {dealer?.Name && (
        <p css={[tw`block text-center text-lg mb-6 normal-case`]}>
          <span css={[tw`font-semibold`]}>{dealer?.Name}</span>{" "}
          {_("is looking forward to working with you.")}
        </p>
      )}
      <div css={[tw`flex space-x-4`]}>
        <Button
          primary
          onClick={() => {
            dispatch(setActiveView(lastActiveView))
          }}
        >
          {_("Let's Keep Going")}
        </Button>
        <Button
          secondary
          onClick={() => {
            resetForm()
          }}
        >
          {_("Cancel Request")}
        </Button>
      </div>
      <button
        css={[tw`absolute top-6 right-6`]}
        onClick={() => {
          dispatch(setActiveView(lastActiveView))
        }}
      >
        <Icon.X color="gray-700" css={[tw`w-4 h-4 hover:(cursor-pointer)`]} />
      </button>
    </div>
  )
}

export default CancelConfirmation
