import React, { useContext, useEffect, useRef } from "react"
import Icon from "../../atoms/Icon"
import { Button } from "../../atoms/Button"
import tw from "twin.macro"
import { SearchBarProps } from "./SearchBar.d"
import { SearchContext } from "../../../contexts/Search"
import { navigate } from "gatsby"
import { HeaderContext } from "../../../contexts/Header"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"

/**
 *
 * @author Stu
 * @summary - Search bar component, containing search form. Should direct user to Search page upon submit.
 *
 * @param {boolean} props.mobileView - passed state of mobile view
 * @param {Function} props.setToggleMobileDropdown - State management for dropdown in mobile view.  Will contain spelling suggestions.
 * @param {boolean} props.secondarySearchBar - when true, returns a small white search input with fully rounded edges, centered text, and no find button.
 * @returns <SearchBar />
 *
 * @todo - Fetch spelling suggestions from Swiftype as input changes
 * @todo - Unit tests
 */

const SearchBar: React.FC<SearchBarProps> = ({
  mobileView,
  setToggleMobileDropdown,
  secondarySearchBar = false,
}) => {
  const [searchData, dispatch] = useContext(SearchContext)
  const { setToggleMenu, setOpen } = useContext(HeaderContext)
  const { language, _ } = useContext(LanguageContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const inputRef = useRef<HTMLInputElement>(null)

  // Submit search query, navigate to search page, close menus, and clear SearchBar form.
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent,
  ) => {
    e.preventDefault()
    if (searchData.entry !== "") {
      setToggleMenu(false)
      setOpen(null)
      dispatch({ type: "SET_SEARCH_QUERY", payload: searchData.entry })
      dispatch({ type: "SET_SEARCH_ENTRY", payload: searchData.entry })
      navigate(language === "es" ? "/es/search" : "/search/")
    }
  }

  // Show mobile search dropdown menu (for spelling-suggest) on focus
  const handleFocus = () => {
    if (setToggleMobileDropdown) {
      setToggleMobileDropdown(true)
    }
  }
  // Hide mobile search dropdown menu (for spelling-suggest) on focus
  const handleBlur = () => {
    if (setToggleMobileDropdown) {
      setToggleMobileDropdown(false)
    }
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div
      css={[
        tw`w-full flex p-4 bg-gray-50`,
        secondarySearchBar && tw`bg-transparent px-0 rounded-full`,
      ]}
    >
      <div css={[tw`relative py-4 w-full`]}>
        <form
          css={[
            tw`bg-white rounded-lg flex items-center`,
            secondarySearchBar && tw`rounded-full border border-[#b2b2b2]`,
          ]}
          onSubmit={handleClick}
        >
          <div
            css={[
              tw`flex items-center w-full mx-2 my-3`,
              tw`md:(mx-7 mt-4 mb-3 pb-1 border-b border-[#b2b2b2])`,
              secondarySearchBar &&
                tw`relative border-none pb-0 my-2.5 px-6 md:(my-2.5)`,
            ]}
          >
            <div
              css={[
                tw`h-5 w-5 min-w-5 mr-3 -mb-1`,
                mobileView && tw`h-4 min-w-4 max-w-4`,
                secondarySearchBar && tw`absolute left-2 md:left-0`,
              ]}
            >
              <Icon.Search color="black" css={[tw`w-full`]} />
            </div>

            <div css={[tw`flex-grow`]}>
              <input
                value={searchData.entry}
                name="search"
                type="string"
                css={[
                  tw`font-semibold pt-0 text-lg w-full`,
                  tw`focus:(outline-none)`, // Removes outline on focus
                  tw`md:(text-2xl)`,
                  tw`placeholder:(text-black opacity-100)`,
                  secondarySearchBar &&
                    tw`text-center placeholder:(text-base uppercase) md:(text-base)`,
                ]}
                placeholder={_("Search")}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: "SET_SEARCH_ENTRY",
                    payload: e.target.value,
                  })
                }}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()}
                ref={inputRef}
              />
            </div>
            {!secondarySearchBar && (
              <Button
                css={[
                  tw`max-w-[4.875rem] w-full h-6 flex justify-center items-center text-xs font-semibold tracking-normal capitalize`,
                ]}
                onClick={e => {
                  trackTealEvent({
                    tealium_event: "cta_click",
                    click_text: `top nav:find`,
                    page_type: tealPageData.page_type,
                    link_href: "/search/",
                    coupon_module_text: "Find",
                    search_query: searchData.entry,
                    page_name: "search results",
                  })
                  handleClick(e)
                }}
                analytics-id={`find:sidebar:`}
                primary
                type="submit"
              >
                {_("Find")}
              </Button>
            )}
          </div>
        </form>

        {/* Clear text button */}
        {/* Element is placed outside of form so it won't interfere with the enter key initiating the "find" button */}
        <button
          aria-roledescription={_("Clear text")}
          onClick={e => {
            e.preventDefault()
            dispatch({ type: "SET_SEARCH_ENTRY", payload: "" })
          }}
          css={[
            tw`absolute top-9 right-20 p-0 rounded-none font-book mx-3`,
            tw`md:(top-10 right-[105px])`,
            !searchData.entry && tw`invisible`,
            secondarySearchBar &&
              tw`right-1 md:(right-3 top-1/2 transform -translate-y-1/2)`,
          ]}
        >
          <Icon.X color="black" css={[tw`h-3 `, tw`md:(h-4)`]} />
        </button>
      </div>
    </div>
  )
}

export default SearchBar
