import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * User icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const User: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 11 12" {...remainingProps}>
      <g
        fill={colorString}
        fill-rule="nonzero"
        stroke={colorString}
        stroke-width=".5"
      >
        <path d="M2.25 4.333c0 1.834 1.5 3.334 3.333 3.334 1.834 0 3.334-1.5 3.334-3.334C8.917 2.5 7.417 1 5.583 1A3.343 3.343 0 0 0 2.25 4.333Zm5.882 0a2.557 2.557 0 0 1-2.549 2.55 2.557 2.557 0 0 1-2.549-2.55 2.557 2.557 0 0 1 2.55-2.549 2.557 2.557 0 0 1 2.548 2.55ZM1.577 11a5.58 5.58 0 0 1 4.006-1.68A5.58 5.58 0 0 1 9.59 11l.577-.584a6.437 6.437 0 0 0-9.167 0l.577.584Z" />
      </g>
    </svg>
  )
}

export default User
