import React from "react"
import tw from "twin.macro"
import BackAndNextButtons from "./BackAndNextButtons"
import FormHeading from "./FormHeading"
import type { FormPageProps } from "./FormPage.d"

const FormPage: React.FC<FormPageProps> = ({
  active,
  showButtons = true,
  nextButtonType = "button",
  submitting = false,
  prevOnClick,
  nextOnClick,
  heading,
  showFormHeading,
  formHeading,
  children,
  nextButtonText,
  ...remainingProps
}) => {
  const { analyticsHeading } = remainingProps
  return (
    <article css={[tw`absolute top-0 h-full w-full flex flex-col`]}>
      <FormHeading
        showFormHeading={showFormHeading}
        formHeading={formHeading}
        css={!active && tw`opacity-0 z-0`}
      />
      <section
        css={[
          tw`relative flex flex-col justify-center items-center z-0 flex-grow`,
          active
            ? tw`opacity-100 z-20 transition-opacity duration-700 ease-in-out lg:(z-20)`
            : tw`opacity-0 z-0`,
          !showFormHeading && tw`mt-14 lg:mt-0 z-0`,
        ]}
        {...remainingProps}
      >
        {heading && (
          <h3
            css={tw`text-center px-4 text-2xl font-semibold normal-case pb-4`}
          >
            {heading}
          </h3>
        )}
        {children}
      </section>
      {showButtons && (
        <BackAndNextButtons
          prevOnClick={prevOnClick}
          nextOnClick={nextOnClick}
          active={active}
          type={nextButtonType}
          submitting={submitting}
          nextButtonText={nextButtonText}
          heading={heading || analyticsHeading}
        />
      )}
    </article>
  )
}

export default FormPage
