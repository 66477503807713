import tw from "twin.macro"
import { ButtonLink } from "../../atoms/Button"
import { SummaryViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import React, { useContext, useRef, useState } from "react"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import ReCAPTCHA from "react-google-recaptcha"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { tealiumNavClick, tealiumSubmit } from "./TealiumHelpers"
import { useTealiumContext } from "../../../contexts/Tealium"

const TactSummaryView: React.FC<SummaryViewProps> = ({
  active,
  submitting,
  sentSuccess,
  onSubmit,
}) => {
  if (!active) return null

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()
  const firstSubmit = useRef(true)
  const firstTYLoad = useRef(true)
  const [
    { dealer, gRecaptchaResponse, firstName, lastName, contact, phone, email },
    dispatch,
  ] = useContext(ContactContext)
  // contact info is cleared on submit but is needed for tealium event on thank you page
  const [contactPostSubmit, setContactPostSubmit] = useState(contact)
  const { _ } = useContext(LanguageContext)
  const recaptchaRef = useRef(null)

  const [isValid, setIsValid] = useState(false)
  const [showValidatioMessage, setShowValidationMessage] = useState(false)
  let summaryMessage = ""

  switch (true) {
    case phone && !email: {
      // Phone only
      summaryMessage = `${_(
        "You are inquiring about Toyota Technician apprenticeship program with your local Toyota Dealer. You told us your name is",
      )} ${firstName} ${lastName}, ${_(
        "and you want to be contacted via phone at",
      )} ${phone}.`
      break
    }
    case email && !phone: {
      // Email only
      summaryMessage = `${_(
        "You are inquiring about Toyota Technician apprenticeship program with your local Toyota Dealer. You told us your name is",
      )} ${firstName} ${lastName}, ${_(
        "and you want to be contacted via email at",
      )} ${email}.`
      break
    }
    case email !== "" && phone !== "": {
      // Both email and phone
      summaryMessage = `${_(
        "You are inquiring about Toyota Technician apprenticeship program with your local Toyota Dealer. You told us your name is",
      )} ${firstName} ${lastName}, ${_(
        "and you want to be contacted via phone at",
      )} ${phone} ${_("or via email at")} ${email}.`
      break
    }
  }

  const updateRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    dispatch(updateField("gRecaptchaResponse", recaptchaValue))
    setIsValid(true)
    setShowValidationMessage(false)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (firstSubmit.current) {
      tealiumSubmit(
        "contact_dealer_submission",
        "Review & Submit Your Request",
        contact,
        trackTealEvent,
        contactDealerData,
      )
      firstSubmit.current = false
    }
    if (!gRecaptchaResponse) {
      setShowValidationMessage(true)
      setIsValid(false)
    } else {
      setIsValid(true)
      setContactPostSubmit(contact)
      onSubmit()
    }
  }

  if (sentSuccess != null) {
    // Explicitly pass contactDealerData (required for event in SDR)
    // and prevent event from firing on navigate back when anchor link is appended to url
    return (
      <div css={[tw`flex items-center justify-center mx-8 flex-col mt-20`]}>
        <article css={[tw`text-center normal-case text-lg max-w-md mb-10`]}>
          {sentSuccess === true
            ? `${_("Thank you for reaching out, a representative from")} ${
                dealer.Name
              } 
            ${_(
              "will contact you via your preferred contact method within 24 hours.",
            )}`
            : _(`An unexpected error has occurred. Please try again.`)}
        </article>
        <ButtonLink
          to={dealer?.TactUrl}
          primary
          onClick={() => {
            trackTealEvent({
              tealium_event: "tact_apply",
              dealer_name: dealer?.Name,
              dealer_code: dealer?.DealerCode,
            })
          }}
          analytics-id="Visit Dealer Website:contact form:Review & Submit Your Request"
        >
          {_("Visit Dealer Website")}
        </ButtonLink>
      </div>
    )
  }
  return (
    <FormPage
      active={active}
      nextButtonType={isValid ? "submit" : "button"}
      prevOnClick={() => {
        dispatch(setActiveView("OptionalCommentsView"))
        tealiumNavClick(
          "Review & Submit Your Request",
          "Back",
          trackTealEvent,
          contactDealerData,
        )
      }}
      nextOnClick={e => handleSubmit(e)}
      submitting={submitting}
      heading={_("Review & Submit Your Request")}
      nextButtonText={_("Submit")}
    >
      <p css={[tw`mt-3 text-sm normal-case text-center max-w-[30rem]`]}>
        {summaryMessage}
      </p>
      <div css={tw`mt-6 w-full max-w-[30rem] relative text-center md:mt-8`}>
        <ReCAPTCHA
          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          onChange={updateRecaptcha}
          css={tw`inline-block`}
        />
        {/* reCaptcha require validation popover */}
        <section
          css={[
            tw`absolute -bottom-12 left-1/2 -translate-x-1/2 overflow-hidden transition-all duration-300 w-auto`,
            showValidatioMessage ? tw`max-h-[60px]` : tw`max-h-0`,
          ]}
        >
          <div
            css={[
              tw`bg-red-400 text-gray-50 text-xs leading-3 normal-case mx-auto mt-3 py-2 px-3 rounded-md`,
              // Pointed div
              tw`before:(absolute right-[calc(50% - 10px)] top-1.5 border-solid border-[10px] border-t-0 border-transparent border-b-red-400)`,
            ]}
          >
            {_("Please confirm that you are not a robot.")}
          </div>
        </section>
      </div>
    </FormPage>
  )
}

export default TactSummaryView
