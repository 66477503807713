import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Heart icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */
const HeartIcon: React.FC<IconProps> = ({
  color,
  filled,
  ...remainingProps
}) => {
  const colorString = processIconColor(color)

  return (
    <>
      {filled ? (
        <svg
          viewBox="-1 0 26 24"
          css={[`fill:${colorString}`]}
          {...remainingProps}
        >
          <path
            d="M17.546 0a6.409 6.409 0 0 0-3.905 1.312 6.51 6.51 0 0 0-1.64 1.836 6.503 6.503 0 0 0-1.642-1.836A6.406 6.406 0 0 0 6.454 0C2.895 0 0 2.888 0 6.438c0 1.446 0 4.83 7.692 11.71L12 22l4.308-3.852C24 11.268 24 7.884 24 6.438 24 2.888 21.106 0 17.546 0"
            fill-rule="evenodd"
          />
        </svg>
      ) : (
        <svg
          viewBox="-1 0 26 24"
          css={[`fill:${colorString}`]}
          {...remainingProps}
        >
          <path
            d="M22.648 7.438c0 1.265 0 4.228-7.243 10.705L12 21.188l-3.405-3.045c-7.242-6.477-7.242-9.44-7.242-10.705 0-2.805 2.288-5.089 5.101-5.089 1.126 0 2.193.359 3.086 1.037a5.127 5.127 0 0 1 1.811 2.622l.65 2.216.648-2.216a5.133 5.133 0 0 1 1.812-2.622 5.064 5.064 0 0 1 3.085-1.037c2.813 0 5.102 2.284 5.102 5.09M17.546 1a6.409 6.409 0 0 0-3.905 1.312 6.51 6.51 0 0 0-1.64 1.836 6.503 6.503 0 0 0-1.642-1.836A6.406 6.406 0 0 0 6.454 1C2.895 1 0 3.888 0 7.438c0 1.446 0 4.83 7.692 11.71L12 23l4.308-3.852C24 12.268 24 8.884 24 7.438 24 3.888 21.106 1 17.546 1"
            fill-rule="evenodd"
          />
        </svg>
      )}
    </>
  )
}
const Heart: React.FC<IconProps> = ({
  color,
  filled,
  noDuplicate,
  ...remainingProps
}) => {
  return (
    <div css={[tw`inline-block relative`]}>
      <HeartIcon color={color} filled={filled} {...remainingProps} />
      <HeartIcon
        color={color}
        filled
        css={[
          tw`invisible absolute opacity-100 bottom-0`,
          filled &&
            tw`transition-all ease-out duration-500 visible opacity-0 bottom-6`,
        ]}
        {...remainingProps}
      />
    </div>
  )
}

export default Heart
