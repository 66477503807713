import React, { useContext } from "react"
import tw from "twin.macro"
import { OptionProps } from "./Select.d"
import { LanguageContext } from "../../../contexts/Language"

const Option: React.FC<OptionProps> = ({
  label,
  value,
  selected,
  onClick,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)
  return (
    <li
      css={[
        tw`text-left px-3 py-2 cursor-pointer hover:(bg-gray-100)`,
        selected && tw`bg-red-400 text-white hover:(bg-red-500)`,
        value === _("I'm not sure") && tw`border-b`,
      ]}
      onClick={() => onClick(label, value)}
      {...remainingProps}
    >
      {label}
    </li>
  )
}

export default Option
