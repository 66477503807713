import React, { useRef, useState } from "react"
import { SearchIconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Search icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Search: React.FC<SearchIconProps> = ({ color, ...remainingProps }) => {
  const [iconHeight] = useState(0) //TODO this doesn't seem to be needed anymore -- but leaving it in for now
  const ref = useRef(null)

  const colorString = processIconColor(color)
  return (
    <svg viewBox="-1 -1 74 74" ref={ref} {...remainingProps}>
      <g fill="none" fill-rule="evenodd" stroke={colorString}>
        {iconHeight >= 48 ? (
          <g transform="translate(1 8)" fill="#C00">
            <circle cx="27.5" cy=".5" r="1" />
            <circle cx="30.5" cy=".5" r="1" />
            <circle cx="24.5" cy=".5" r="1" />
            <circle cx="21.5" cy=".5" r="1" />
            <circle cx="19.5" cy="2.5" r="1" />
            <circle cx="22.5" cy="2.5" r="1" />
            <circle cx="25.5" cy="2.5" r="1" />
            <circle cx="20.5" cy="4.5" r="1" />
            <circle cx="17.5" cy="4.5" r="1" />
            <circle cx="14.5" cy="4.5" r="1" />
            <circle cx="15.5" cy="6.5" r="1" />
            <circle cx="12.5" cy="6.5" r="1" />
            <circle cx="18.5" cy="6.5" r="1" />
            <circle cx="13.5" cy="8.5" r="1" />
            <circle cx="10.5" cy="8.5" r="1" />
            <circle cx="16.5" cy="8.5" r="1" />
            <circle cx="11.5" cy="10.5" r="1" />
            <circle cx="8.5" cy="10.5" r="1" />
            <circle cx="14.5" cy="10.5" r="1" />
            <circle cx="9.5" cy="12.5" r="1" />
            <circle cx="6.5" cy="12.5" r="1" />
            <circle cx="12.5" cy="12.5" r="1" />
            <circle cx="7.5" cy="14.5" r="1" />
            <circle cx="10.5" cy="14.5" r="1" />
            <circle cx="8.5" cy="16.5" r="1" />
            <circle cx=".5" cy="18.5" r="1" />
            <circle cx="6.5" cy="18.5" r="1" />
            <circle cx="9.5" cy="18.5" r="1" />
            <circle cx="7.5" cy="20.5" r="1" />
            <circle cx="5.5" cy="22.5" r="1" />
            <circle cx="8.5" cy="22.5" r="1" />
            <circle cx="6.5" cy="24.5" r="1" />
          </g>
        ) : null}
        {iconHeight >= 48 ? (
          <path
            d="M26.517 5.626c3.248 0 6.314.767 9.058 2.095a22.854 22.854 0 0 0-5.893-.792 22.598 22.598 0 0 0-16.087 6.665c-5.959 5.958-7.896 14.424-5.848 22.032a20.735 20.735 0 0 1-2.12-9.11c0-11.519 9.371-20.89 20.89-20.89"
            stroke="#000"
            fill="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        ) : null}
        <path
          d="M26.64 0C11.928 0 0 11.928 0 26.64s11.928 26.64 26.64 26.64 26.64-11.928 26.64-26.64S41.352 0 26.64 0m0 2.88c13.101 0 23.76 10.659 23.76 23.76S39.741 50.4 26.64 50.4 2.88 39.741 2.88 26.64 13.539 2.88 26.64 2.88"
          stroke={iconHeight <= 24 && color ? colorString : "#000"}
          strokeWidth={iconHeight <= 24 ? "3" : "0"}
          fill={iconHeight <= 24 && color ? colorString : "#000"}
        />
        <path
          d="M70.56 72c-.369 0-.737-.141-1.018-.422l-24.92-25.92a1.438 1.438 0 0 1 0-2.036 1.438 1.438 0 0 1 2.036 0l25.92 25.92A1.438 1.438 0 0 1 70.56 72"
          stroke={iconHeight <= 24 && color ? colorString : "#000"}
          strokeWidth={iconHeight <= 24 ? "3" : "0"}
          fill={iconHeight <= 24 && color ? colorString : "#000"}
        />
      </g>
    </svg>
  )
}

export default Search
