import React from "react"
import { GlobalStyles as TWGlobalStyles } from "twin.macro"
import { Global } from "@emotion/react"
import customGlobalStyles from "../../../styles/global"
import "../../../styles/typography.css"

const GlobalStyles = () => {
  return (
    <>
      <TWGlobalStyles />
      <Global styles={customGlobalStyles} />
    </>
  )
}

export default GlobalStyles
