import React from "react"
import { CondWrapperProps } from "./ConditionalWrapper.d"

/**
 * @author Stu
 * @summary Conditionally wraps React children in a provided components, OR returns unwrapped components if "condition" is false and "falseWrapper" is undefined.
 * Reference: https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 *
 * @param {boolean} condition - A boolean value to specify if the wrapper is used
 * @param {function} wrapper - A function that accepts React children as a parameter and returns the children wrapped in the provided JSX element.
 * @param {function} falseWrapper - A function that accepts React children as a parameter and returns the children wrapped in the provided JSX element IF the condition is false.
 *
 * @returns JSX
 *
 */

const ConditionalWrapper: React.FC<CondWrapperProps> = ({
  condition,
  wrapper,
  falseWrapper,
  children,
}) => {
  if (condition) {
    return wrapper(children)
  } else if (falseWrapper) {
    return falseWrapper(children)
  }
  return <>{children}</>
}

export default ConditionalWrapper
