import { ACTION_TYPES } from "./actions"
import { SeriesPageContextProps } from "./seriespage"

const seriesPageReducer = (
  state: SeriesPageContextProps,
  action: { type: ACTION_TYPES; payload?: any },
) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SERIES_PAGE_DATA:
      return {
        ...action.payload,
      }
    case ACTION_TYPES.UPDATE_SERIES_PAGE_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      }
    default:
      return state
  }
}

export default seriesPageReducer
