import React, { createContext } from "react"
import useStringTranslations from "../../hooks/useStringTranslations"
import {
  AcceptedLanguages,
  LanguageProviderProps,
  Translation,
} from "./language.d"

const translateString = (
  string: string,
  language: AcceptedLanguages = "en",
  defaultToEnglish: boolean = false, // Optional parameter. Keeps solution backwards compatible.
): string => {
  if (language === "en") return string

  const translationData = useStringTranslations()
  const currentTranslation = translationData?.find(
    (translation: Translation) =>
      translation?.en?.toLowerCase() === string?.toLocaleLowerCase(),
  )
  // If no translation is found and flag is true, return the original string.
  if (!currentTranslation && defaultToEnglish) return string
  return currentTranslation?.es ? currentTranslation?.es : ""
}

export const LanguageContext = createContext<{
  language: AcceptedLanguages
  _: (arg0: string, arg1?: boolean) => string
}>({ language: "en", _: (string: string) => translateString(string) })

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  language,
  children,
}) => {
  return (
    <LanguageContext.Provider
      value={{
        language: language,
        _: (string: string, defaultToEnglish?: boolean) =>
          translateString(string, language, defaultToEnglish),
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
