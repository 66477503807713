import React, { useRef } from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"
import { css } from "@emotion/react"

/**
 * ProgressRing icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const ProgressRing = ({
  color,
  filled,
  progress,
  ...remainingProps
}: IconProps & { progress: number }): JSX.Element => {
  const colorString = processIconColor(color)
  const circle = useRef()

  const radius = circle?.current?.r.baseVal.value
  const circumference = radius * 2 * Math.PI
  const offset = 0 || circumference - (progress / 100) * circumference

  return (
    <>
      <svg viewBox="0 0 120 120" {...remainingProps}>
        <circle
          ref={circle}
          className="progress-ring__circle"
          stroke-width="12"
          stroke={colorString}
          fill="transparent"
          css={[
            tw`origin-center transform -rotate-90`,
            css`
              stroke-dasharray: ${circumference} ${circumference};
              stroke-dashoffset: ${offset};
            `,
          ]}
          r="48"
          cx="60"
          cy="60"
        />
      </svg>
    </>
  )
}

export default ProgressRing
