import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"

/**
 * PaginationButton icon
 *
 * @param {IconProps} props
 *
 * @returns {JSX.Element} <svg />
 */
const PaginationButton = ({
  color,
  filled,
  direction = "left",
  ...remainingProps
}: IconProps): JSX.Element => {
  const { inactive } = remainingProps
  let rotation = 0
  switch (direction) {
    case "right":
      rotation = 180
      break
    case "up":
      rotation = 90
      break
    case "down":
      rotation = -90
      break
    default:
      break
  }

  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      css={[direction, !inactive && tw`cursor-pointer`]}
      {...remainingProps}
    >
      <rect
        width="31"
        height="31"
        x=".5"
        y=".5"
        rx="7.5"
        css={[
          tw`fill-[#fff] group-hover:(fill-[#e10a1d])`,
          inactive && tw`fill-[#fff] group-hover:(fill-[#fff])`,
        ]}
      />
      <path
        d="m19.727 20 .94-.94L17.613 16l3.054-3.06-.94-.94-4 4 4 4Z"
        transform={`rotate(${rotation}, ${32 / 2}, ${32 / 2})`}
        css={[
          tw`fill-[#252525] group-hover:(fill-[#fff])`,
          inactive &&
            tw`opacity-20 fill-[#252525] group-hover:(fill-[#252525])`,
        ]}
      />
      <path
        d="m15.333 20 .94-.94L13.22 16l3.053-3.06-.94-.94-4 4 4 4Z"
        transform={`rotate(${rotation}, ${32 / 2}, ${32 / 2})`}
        css={[
          tw`fill-[#252525] group-hover:(fill-[#fff])`,
          inactive &&
            tw`opacity-20 fill-[#252525] group-hover:(fill-[#252525])`,
        ]}
      />
      <rect width="31" height="31" x=".5" y=".5" stroke="#F1F3F5" rx="7.5" />
    </svg>
  )
}

export default PaginationButton
