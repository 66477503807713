import { OfferSortingType } from "../components/organisms/Series/Offers/Offers.d"
import { OffersParams, HeroOfferParams, OffersResponse } from "./OfferClient"

export class OffersClient {
  private static url: string = `${process.env.GATSBY_API_URL}/offers`
  private static key: string = process.env.GATSBY_API_KEY

  /**
   * Makes an arbitrary call to the Filter API
   *
   * @param {"GET"|"POST"|"DELETE"|"UPDATE"} method - Which HTTP method to use
   * @param {OffersParams} params - Key/value object that will be converted to URL query params in the call
   *
   * @returns {Promise<T>} - JSON response from the API
   */
  private static fetch = async <T>(
    method: "GET" | "POST" | "DELETE" | "UPDATE",
    params?: OffersParams,
  ): Promise<T> => {
    const processedParams = this.processParams(params)

    const url = `${this.url}/${
      processedParams && "?" + new URLSearchParams(processedParams)
    }`

    const config = {
      method: method,
      headers: {
        "x-api-key": this.key,
      },
    }

    try {
      const res = await fetch(url, config)
      const json: T = await res.json()
      return json
    } catch (error) {
      console.error(`Couldn't fetch offers.`, error)
    }
  }

  /**
   * Prepares an object of api params
   *
   * @param {OffersParams} params - Params object to process and flatten
   *
   * @returns - Object with arrays and objects flattened
   */
  private static processParams = (params: OffersParams) => {
    let processedParams: any = {}

    for (const key in params) {
      const value = params[key]

      if (Array.isArray(value)) {
        // If it's an array of objects, flatten to key:value,key:value
        if (typeof value[0] === "object") {
          processedParams[key] = value
            .map((e: { key: string; value: string }) => `${e.key}:${e.value}`)
            .join(",")
          // If it's just an array, comma separate
        } else {
          processedParams[key] = value.join(",")
        }
      } else {
        processedParams[key] = value
      }
    }

    return processedParams
  }

  /**
   * Get offers by specified criteria in the CMS for the Offers and Series Offers Hero Carousel
   *
   * @param {string} series - The seriesName to filter by
   * @param {string} year - The offer year to filter by
   * @param {string} type - The offer type to filter by
   * @param {string} model - The offer model to filter by
   * @param {boolean} heroItem - Limits data returned and adds backup types
   *
   * @returns {FiltersResponse} - An array of offers which is ordered by requested offer type and then back up types
   */
  public static getHeroOffers = async (options?: HeroOfferParams) => {
    return await this.fetch<OffersResponse>("GET", {
      ...options,
      heroItem: true,
      totalCount: true,
    })
  }

  /**
   * Get offers by specified criteria
   *
   * @param {string} series - The seriesName to filter by
   * @param {boolean} year - The offer year to filter by
   * @param {string} model - The offer model to filter by
   * @param {boolean} type - The offer type to filter by
   * @param {number} limit - Number of offers to return
   * @param {boolean} groupBySeries - Whether to structure data by series
   * @param {boolean} groupByYear - Whether to structure data by year
   * @param {boolean} groupBySegment - Whether to structure data by segment

   * @returns {OffersResponse} - Array of offers which matches query
   */
  public static getManyOffers = async (options?: OffersParams) => {
    return await this.fetch<OffersResponse>("GET", {
      ...options,
    })
  }
  /**
   * Get offers by specified sort order
   *
   * @param {string} series - The series name to filter by
   * @param {boolean} year - The year to filter by
   * @param {Array} sortOrder - The type and series type (Primart or Secondary) to order offers by
   * @param {number} limit - The number of offers to return
   * @returns {OffersResponse} - Array of offers which matches query
   */
  public static getOffersBySortOrder = async (
    sortOrder: OfferSortingType[],
    series?: string,
    year?: string,
    limit?: number,
  ) => {
    return await this.fetch<OffersResponse>("GET", {
      sortOrder: JSON.stringify([...sortOrder]),
      series: JSON.stringify(series) || null,
      year: JSON.stringify(year) || null,
      limit: limit || null,
    })
  }

  /**
   * Get offers by id
   *
   * @param {string} ids - The id(s) of the offer
   * @returns {FiltersResponse} - A offer(s) which matches the id
   */
  public static getOfferById = async (ids?: string[]) => {
    return await this.fetch<OffersResponse>("GET", {
      id: JSON.stringify([...ids]),
    })
  }
}
