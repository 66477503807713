import React, { createContext, useReducer } from "react"
import { LocalStorageClient } from "../../clients/LocalStorageClient"
import useLocalStorage from "../../hooks/useLocalStorage"
import { FavoritesState } from "./favorites.d"
import favoritesReducer, { defaultState } from "./reducers"

export const FavoritesContext = createContext<
  [FavoritesState, React.Dispatch<any>, (query?: string) => any]
>([defaultState, () => {}, () => {}])

export const FavoritesProvider: React.FC<FavoritesState> = ({ children }) => {
  const [favoritedDealer] = useLocalStorage("favoritedDealer") || null
  const favoritedVehicles = LocalStorageClient.read("favoritedVehicles") || []
  const favoritedOffers = LocalStorageClient.read("favoritedOffers") || []
  const favoritedAccessories =
    LocalStorageClient.read("favoritedAccessories") || []
  const favoriteMessage = "default"

  const [favoritesState, dispatch] = useReducer(favoritesReducer, {
    ...defaultState,
    favoritedDealer,
    favoritedVehicles,
    favoritedOffers,
    favoritedAccessories,
    favoriteMessage,
    shouldShowTooltip: false,
  })

  return (
    <FavoritesContext.Provider value={[favoritesState, dispatch]}>
      {children}
    </FavoritesContext.Provider>
  )
}
