import React, { useContext } from "react"
import { LinkProps } from "./Link.d"
import { Link as GatsbyLink } from "gatsby"
import { css } from "@emotion/react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"

const Link: React.FC<LinkProps> = ({
  to,
  target = "_blank",
  onClick,
  activeClassName,
  tabIndex,
  animated,
  animatedThin,
  disabled,
  children,
  forceExternal = false,
  state,
  ...remainingProps
}) => {
  const { language } = useContext(LanguageContext)

  /* Checks to see if "to" is an internal link (first character is a "/") */
  const isInternalLink = /^\/(?!\/)/.test(to)
  /* Base styles */
  const baseStyles = tw`inline-block relative py-2`

  /* Animation styles applied for non-mobile devices when animated = true */
  const underlineAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-0.5 bottom-0 left-0 bg-red-400 origin-bottom-left transition-transform ease-out duration-300`}
      ${animatedThin && tw`h-px`}
      content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left`}
      }
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`origin-bottom-left`}
    }
  `

  if (forceExternal || !isInternalLink) {
    return (
      <a
        data-linktype="external"
        href={to}
        target={target}
        onClick={onClick}
        tabIndex={tabIndex}
        rel="noopener"
        css={[
          baseStyles,
          { ...(animated && underlineAnimation) },
          disabled && tw`opacity-50 pointer-events-none cursor-not-allowed`,
        ]}
        {...remainingProps}
      >
        {children}
      </a>
    )
  }

  let path = to === "/home/" || to === "/@" ? "/" : to
  if (language === "es" && path != "/es/") {
    path = `/es${path}`
  }

  return (
    <GatsbyLink
      data-linktype="internal"
      to={path}
      activeClassName={activeClassName}
      onClick={onClick}
      tabIndex={tabIndex}
      css={[
        baseStyles,
        { ...(animated && underlineAnimation) },
        disabled && tw`opacity-50 pointer-events-none cursor-not-allowed`,
      ]}
      state={state}
      {...remainingProps}
    >
      {children}
    </GatsbyLink>
  )
}

export default Link
