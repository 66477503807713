import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * ExternalLink icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const ExternalLink: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 19 19" {...remainingProps}>
      <path
        d="M15 10.222V18H1V4h7.778M11 1h7v7M8 11 18 1 8 11Z"
        stroke={colorString}
        stroke-width="2"
        fill="none"
        fill-rule="evenodd"
      />
    </svg>
  )
}

export default ExternalLink
