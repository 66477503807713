import React from "react"
import tw from "twin.macro"
import { Button } from "../Button"
import { PillProps } from "./Pill.d"

/**
 * Pill navigation
 *
 * @component
 * @param {PillItemProps} props
 * @param {PillItem[]} props.items - List of Pill Items
 * @param {boolean} props.items.selected - Controls whether pill is selected
 * @param {boolean} props.items.children - Allows text inside the pill
 *
 */
const Pill: React.FC<PillProps> = ({
  selected = false,
  noShadow = false,
  alignment = "left",
  onClick,
  children,
  ...remainingProps
}) => {
  return (
    <Button
      onClick={onClick}
      css={[
        tw`shadow bg-white font-book normal-case text-gray-600 rounded-lg px-4 py-2 text-left mr-3 md:(py-3 px-3 text-left mr-3) lg:(py-3 px-4 text-left mr-3)
        hover:(text-gray-700 bg-gray-50)
        focus:(text-gray-700 bg-gray-50)
        `,
        alignment === "center" && tw`text-center`,
        noShadow && tw`shadow-none`,
        selected && tw`shadow-lg text-gray-800 font-semibold`,
      ]}
      aria-selected={`${selected}`}
      {...remainingProps}
    >
      <span itemProp="name">{children}</span>
    </Button>
  )
}

export default Pill
