import React, { createContext, useMemo, useState } from "react"
import { HeaderProviderProps, HeaderState } from "./header"

const initialState: HeaderState = {
  toggleMenu: false,
  open: null,
  viewedAcc: null,
  setViewedAcc: null,
  pageType: "",
}

export const HeaderContext = createContext<HeaderState>(initialState)

export const HeaderProvider: React.FC<HeaderProviderProps> = ({
  children,
  pageType,
}) => {
  const [toggleMenu, setToggleMenu] = useState(initialState.toggleMenu)
  const [open, setOpen] = useState(initialState.open)
  const [viewedAcc, setViewedAcc] = useState(null)

  const value = useMemo(
    () => ({
      toggleMenu,
      setToggleMenu,
      open,
      setOpen,
      viewedAcc,
      setViewedAcc,
      pageType,
    }),
    [toggleMenu, open, pageType],
  )
  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}
