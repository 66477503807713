import { seriesNameMap } from "../helpers"
import {
  InventoryParams,
  InventoryResponse,
  SingleInventoryResponse,
} from "./InventoryClient.d"

export class InventoryClient {
  private static url: string = `${process.env.GATSBY_API_URL}/inventory`
  private static key: string = process.env.GATSBY_API_KEY

  /**
   * Makes an arbitrary call to the Inventory API
   *
   * @param {"GET"|"POST"|"DELETE"|"UPDATE"} method - Which HTTP method to use
   * @param {InventoryParams} params - Key/value object that will be converted to URL query params in the call
   *
   * @returns {Promise<T>} - JSON response from the API
   */
  private static fetch = async <T>(
    method: "GET" | "POST" | "DELETE" | "UPDATE",
    params?: InventoryParams,
    throwError = false,
  ): Promise<T> => {
    const processedParams = this.processParams(params)

    const url = `${this.url}/${
      processedParams && "?" + new URLSearchParams(processedParams)
    }`

    const config = {
      method: method,
      headers: {
        "x-api-key": this.key,
      },
    }

    try {
      const res = await fetch(url, config)
      const json: T = await res.json()
      if (!throwError) return json

      if (res.ok) {
        return json
      } else {
        throw Error("An error has occurred")
      }
    } catch (error) {
      console.error(`Couldn't fetch inventory.`, error)
      if (throwError) {
        throw error
      }
    }
  }

  /**
   * Prepares an object of api params
   *
   * @param {InventoryParams} params - Params object to process and flatten
   *
   * @returns - Object with arrays and objects flattened
   */
  private static processParams = (params: InventoryParams) => {
    let processedParams: any = {}

    for (const key in params) {
      const value = params[key]

      // If slug or alternative name was passed in, get seriesName.
      if (key === "series") {
        const seriesName = seriesNameMap.get(value)
        processedParams[key] = seriesName ? seriesName : value
        continue
      }
      // If the key is "accessoryFIAs" or "accessoryPIOs", add their values to the "accessory" param
      if (key === "accessoryFIAs" || key === "accessoryPIOs") {
        if (!processedParams["accessory"]) {
          processedParams["accessory"] = []
        }
        processedParams["accessory"] = [
          ...processedParams["accessory"],
          ...value,
        ]
        continue
      }

      if (Array.isArray(value)) {
        // If it's an array of objects, flatten to key:value,key:value
        if (value[0] && typeof value[0] === "object") {
          processedParams[key] = value
            .map((e: { key: string; value: string }) => `${e.key}:${e.value}`)
            .join(",")
          // If it's just an array, comma separate
        } else {
          processedParams[key] = value.join(",")
        }
      } else {
        processedParams[key] = value
      }
    }

    return processedParams
  }

  /**
   * Fetch the total number of vehicles available for a series
   *
   * @param {string} series - The series to search for
   * @param {InventoryParams} options - Additional parameters for the query
   *
   * @returns {InventoryResponse} - Count only response
   */
  public static getCount = async (series: string, options: InventoryParams) => {
    return await this.fetch<InventoryResponse>("GET", {
      countOnly: true,
      series: series,
      ...options,
    })
  }

  /**
   * Get all inventory by specific series
   *
   * @param {string} series - The series to search for
   * @param {InventoryParams} options - Additional parameters for the query
   *
   * @returns {InventoryResponse} - Array of inventory which matches query
   */
  public static getBySeries = async (
    series: string,
    options?: InventoryParams,
  ) => {
    if (!options.dealer || options.dealer === "" || !series) return
    // console.log("inventory getBySeries", options)
    return await this.fetch<InventoryResponse>(
      "GET",
      {
        ...options,
        series: series,
      },
      true,
    )
  }

  /**
   * Get one vehicle matched by VIN
   *
   * @param {string} vin - The VIN of the vehicle
   * @param {InventoryParams} options - Parameters for the query
   *
   * @returns {SingleInventoryResponse} - Single vehicle matching VIN, or empty
   */
  public static getByVin = async (vin: string, options: InventoryParams) => {
    return await this.fetch<SingleInventoryResponse>("GET", { vin, ...options })
  }

  /**
   * Generic GET call to Inventory API
   *
   * @param {InventoryParams} options - Parameters for the query
   *
   * @returns {InventoryResponse} - Array of inventory which matches query
   */
  public static get = async (options: InventoryParams) => {
    return await this.fetch<InventoryResponse>("GET", options)
  }
}
