import React from "react"
import { JumpToButtonProps } from "./StickyFooter.d"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"

const JumpToButton: React.FC<JumpToButtonProps> = ({ toggle, setToggle }) => {
  const { trackTealEvent } = useTealiumEvent()

  return (
    <button
      onClick={() => {
        // If other menu is open when this menu opens, close other menu
        if (toggle.chatBubble && !toggle.jumpTo)
          setToggle({ ...toggle, jumpTo: true, chatBubble: false }),
            trackTealEvent({
              tealium_event: "nav_click",
              click_text: "sticky footer:Jump To",
            })
        else setToggle({ ...toggle, jumpTo: !toggle.jumpTo })
      }}
      css={[tw`flex items-center gap-3 font-semibold`]}
    >
      <span css={[tw`whitespace-nowrap`]}>JUMP TO</span>
      <Icon.Chevron
        direction="up"
        color="white"
        css={[
          tw`h-2 transition-transform duration-300`,
          toggle.jumpTo && tw`rotate-180`,
        ]}
      />
    </button>
  )
}

export default JumpToButton
