import React from "react"
import tw from "twin.macro"
import { Card } from "../../atoms/Card"
import { Badge } from "../../atoms/Badge"
import Icon from "../../atoms/Icon"

const LoadingOfferCard = ({}) => {
  return (
    <div
      css={[
        tw`relative w-72 mx-auto`,
        tw`md:(w-72)`,
        tw`lg:(w-[16rem])`,
        tw`xl:(w-72)`,
      ]}
    >
      <button
        onClick={() => {}}
        css={[
          tw`absolute top-8 right-4 xl:right-4 z-10 flex items-center border border-transparent p-1 rounded-full hover:bg-gray-100`,
          tw`focus-visible:outline-gray-50`,
        ]}
      >
        <Icon.Heart
          color="gray-400"
          css={[tw`w-6 mt-0.5 ml-auto`]}
          filled={false}
        />
      </button>
      <Card
        shadowLevel={2}
        css={[
          tw`relative w-[16rem] bg-white mt-3 overflow-visible group-hover:(shadow-4 ease-in-out transition-all duration-150) group-focus-visible:(shadow-4 outline-gray-50)`,
          tw`md:(w-72)`,
          tw`lg:(w-[16rem])`,
          tw`xl:(w-72)`,
        ]}
      >
        {/* BADGE AND HEART ICON */}
        <Badge
          color={"gray-200"}
          css={[tw`absolute -top-3 left-0 right-0 mx-auto w-20 h-7`]}
        >
          {""}
        </Badge>
        {/* OFFER CARD CONTAINER */}
        <article
          css={[tw`flex flex-col gap-2 justify-between pb-5 w-full h-60`]}
        >
          {/* TYPE */}
          <div
            css={[
              tw`text-center flex items-center justify-center font-semibold mx-4 min-h-[70px] !leading-[1.7rem]`,
            ]}
          >
            <div>{}</div>
          </div>
        </article>
      </Card>
    </div>
  )
}

export default LoadingOfferCard
