import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * History icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */
const History: React.FC<IconProps> = ({ color, filled, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="-2 -2 28 28" {...remainingProps}>
      <g
        stroke={colorString}
        stroke-width="2.5"
        fill="none"
        fill-rule="evenodd"
        stroke-linejoin="round"
      >
        <path d="M1.575 0v6.923h6.9" />
        <path d="M2.335 6.556C4.015 3.306 7.32 1.143 12.5 1.143c6.351 0 11.5 5.116 11.5 11.428C24 18.883 18.851 24 12.5 24 6.15 24 1 18.883 1 12.571" />
        <path d="M11.925 8.286v7.428H17.1" />
      </g>
    </svg>
  )
}

export default History
