import { observePointAllowList } from "../helpers"
import { DealersClient } from "./DealersClient"

interface ResponseType {
  cloudFrontViewerPostalCode: number | string
  cloudFrontViewerCity: string
  cloudFrontViewerCountryRegionName: string
  cloudFrontViewerCountryRegion: string
  datasource: string
  latitude: number | string
  longitude: number | string
  user_ip: string
  geocoded_zip: string
}

/* Placeholder location data for ObservePoint IP Addresses, 
  for which cloudfront does not return location data */
const observePointLocationData = {
  cloudFrontViewerCity: "<ObservePoint Proxy - Location N/A>",
  cloudFrontViewerCountryRegion: "<ObservePoint Proxy - Location N/A>",
  cloudFrontViewerCountryRegionName: "<ObservePoint Proxy - Location N/A>",
  cloudFrontViewerPostalCode: "<ObservePoint Proxy - Location N/A>",
  geocoded_zip: "<ObservePoint Proxy - Location N/A>",
  latitude: "<ObservePoint Proxy - Location N/A>",
  longitude: "<ObservePoint Proxy - Location N/A>",
}

async function runDealerMatchCheck(
  query: string | number,
  json: any,
  url: string,
) {
  let response
  if (
    (typeof query === "string" &&
      !["al", "ga", "sc", "nc", "fl"]?.includes(
        json?.cloudFrontViewerCountryRegion.toLowerCase(),
      ) &&
      query.length > 4) ||
    json == null
  ) {
    const dealers = await DealersClient.getAll()
    const findDealersByName = (dealers: any[], query: string) => {
      return dealers.filter(dealer =>
        dealer.Name.toLowerCase().includes(query.toLowerCase()),
      )[0]?.Name
    }
    const dealerNameSearch =
      dealers.length > 0 && findDealersByName(dealers, query)
    if (dealerNameSearch !== undefined) {
      const res2 = await fetch(`${url}?query=${dealerNameSearch}`)
      const json2 = await res2.json()
      response = json2
    }
  }
  return response
}

export class LocationClient {
  private static url: string = "https://location.exploretoyota.com"
  /* 
  UPDATING THE LOCATION CLOUDFRONT DISTRIBUTION: The logic for this Cloudfront distrbituion 
  is in the AWS console inside the Lambda function "exploretoyota-location-headers". 
  Open the index.js file in the Lambda function and update the logic there.
  */

  /**
   * Get location information from Cloudfront headers
   *
   * @returns - Location information
   */
  public static getLocationInfo = async (
    query?: string | number,
  ): Promise<{
    cloudFrontViewerPostalCode: number | string
    cloudFrontViewerCity: string
    cloudFrontViewerCountryRegionName: string
    cloudFrontViewerCountryRegion: string
    datasource: string
    latitude: number | string
    longitude: number | string
    user_ip: string
    geocoded_zip: string
  }> => {
    let response: ResponseType

    const url = query ? `${this.url}?query=${query}` : this.url

    const res = await fetch(url)

    if (!res.ok) {
      response = runDealerMatchCheck(query, null, this.url).then(res => {
        if (res == undefined) {
          return null
        } else {
          return res
        }
      })
    } else {
      const json = await res.json()
      if (json) {
        response = runDealerMatchCheck(query, json, this.url).then(res => {
          if (res == undefined) {
            return json
          } else {
            return res
          }
        })
      }
    }

    /* 
    AUTHOR: Stu
    
    DATE: May 23, 2023

    DESCRIPTION: There is an issue with ObservePoint IP addresses and Cloudfront location headers. 
      Cloudfront is not currently returning location data for those IPs. This is a temporary workaround, 
      to allow placeholder location data to be set on the client when an ObservePoint proxy IP is detected.
      
    NOTES: If Cloudfront returns null vales for some properties, the order of objects being spread may need to be reversed.
      This order was selected in case cloudfront _does_ return non-null data for any of the ObservePoint IPs.

    REMAINING TODO: Determine why Cloudfront does not return location data for the ObservePoint proxy IP addresses.
    */
    if (observePointAllowList.includes(response.user_ip)) {
      // When a manual zip code query is made by the user, provide a placeholder value for geocoded_zip (all other location values are returned from Google location API)
      if (query) {
        response.geocoded_zip = "<ObservePoint Proxy - Location N/A>"
        return response
      }
      // If no manual zip query is made, overwrite the default values for response with placeholder data.
      const opResponse = {
        ...response,
        ...observePointLocationData,
      }
      return opResponse
    }
    /*  DEFAULT:
    If an ObservePoint proxy IP address is not detected, return the unmodified "response" object */
    return response
  }
}
