import React from "react"
import { SearchGlobalDesktopProps } from "./SearchGlobalDesktop.d"
import tw from "twin.macro"
import { SearchDropDown } from "../../atoms/SearchDropDown"

import { SearchMenu } from "../SearchMenu"
import { SearchBar } from "../SearchBar"

/**
 *
 * @author Stu
 * @summary - Global search functionality for desktop view. Lives in HeaderNavigation.
 *
 * @param {number} props.open - passed open state for desktop menus
 * @param {boolean} props.mobileView - passed state of mobile view
 * @returns <SearchGlobaDesktop />
 *
 * @todo - Unit tests
 */

const SearchGlobalDesktop: React.FC<SearchGlobalDesktopProps> = ({
  open,
  mobileView,
  searchIndex,
}): JSX.Element => {
  return (
    <div>
      <SearchDropDown show={open} index={searchIndex} mobileView={mobileView}>
        {/* Inner container for justification of all children */}
        <section css={[tw`max-w-[66rem] w-full pt-8`]}>
          <SearchBar mobileView={mobileView} />
          <div css={[tw`w-full bg-gray-50 grid justify-items-stretch pb-10`]}>
            <SearchMenu />
          </div>
        </section>
      </SearchDropDown>
    </div>
  )
}

export default SearchGlobalDesktop
