import React, { useContext, useEffect, useState } from "react"
import { RecentlyViewedProps } from "./RecentlyViewed.d"
import tw from "twin.macro"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import { Vehicle } from "../../../global"
import { InventoryClient } from "../../../clients/InventoryClient"
import InventoryResultCard from "../../organisms/Inventory/InventoryResultCard"
import RecentlyViewedDropDown from "./RecentlyViewedDropDown"
import Lottie from "react-lottie-player"
import lottieJson from "../../../animations/14717-sedan-car-animation.json"
import { LanguageContext } from "../../../contexts/Language"
import { css } from "@emotion/react"

/**
 *
 * @author Cody
 * @summary - Recently Viewed menu. Lives in HeaderNavigation.
 *
 * @param {number} props.open - passed open state for desktop menus
 * @returns <RecentlyViewedDropDown />
 *
 * @todo - Unit tests
 */

const RecentlyViewed: React.FC<RecentlyViewedProps> = ({
  open,
}): JSX.Element => {
  const [isLoading, setVehiclesLoading] = useState<boolean>(false)
  const [vehicleData, setVehicleData] = useState<Vehicle[]>([])
  const { _ } = useContext(LanguageContext)

  useEffect(() => {
    const updateVehicleData = async () => {
      // recently viewed vins ordered from least recently viewed to most recently viewed
      const recentlyViewed = LocalStorageClient.read("recentlyViewed")

      if (
        !recentlyViewed ||
        (Array.isArray(recentlyViewed) && recentlyViewed.length === 0)
      ) {
        setVehiclesLoading(false)
        return
      }

      // 20 most recently viewed vins
      const mostRecentlyViewed: string[] = Array.isArray(recentlyViewed)
        ? [...recentlyViewed].reverse()?.splice(0, 20)
        : [recentlyViewed]
      const vehicleData = await InventoryClient.get({
        vin: mostRecentlyViewed.join(","),
        include: ["accessories"],
        limit: 20,
      })
      if (vehicleData?.count === 0) {
        LocalStorageClient.delete("recentlyViewed")
      }
      const sortedVehicleData = vehicleData.vehicles.sort(
        (a: Vehicle, b: Vehicle) => {
          return (
            mostRecentlyViewed.indexOf(a.vin) -
            mostRecentlyViewed.indexOf(b.vin)
          )
        },
      )
      setVehicleData(sortedVehicleData)
      setVehiclesLoading(false)

      // Update local storage to only store 20 most recently viewed vehicles, ordered from least recently viewed to most recently viewed
      if (Array.isArray(recentlyViewed) && recentlyViewed.length > 20) {
        const recentlyViewedVins = recentlyViewed.slice(-20)
        LocalStorageClient.delete("recentlyViewed")
        LocalStorageClient.write("recentlyViewed", recentlyViewedVins)
      }
    }

    if (open) {
      setVehiclesLoading(true)
      updateVehicleData()
    }
  }, [open])

  return (
    <div>
      <RecentlyViewedDropDown show={open}>
        <section
          aria-label="Recently Viewed Vehicles Section"
          css={tw`bg-gradient-to-b from-gray-100 text-center w-full scrollbar-hide`}
        >
          <h2 css={[tw`text-3xl font-book tracking-widest mt-8 mb-3 px-2`]}>
            {_("Recently Viewed Vehicles")}
          </h2>
          {isLoading ? (
            <div
              css={[
                tw`z-10 bg-opacity-70 flex items-center justify-center h-96`,
              ]}
            >
              <div>
                <span
                  css={[
                    tw`block mt-4 text-lg text-gray-700 font-bold text-center leading-tight`,
                  ]}
                >
                  {_(
                    "Hang tight, we're fetching your recently viewed vehicles.",
                  )}
                </span>
                <div>
                  <Lottie loop animationData={lottieJson} play />
                </div>
              </div>
            </div>
          ) : (
            <div
              css={[
                tw`grid grid-cols-1 gap-0 mx-0 mb-[200px]`,
                tw`lg:(grid-cols-2 gap-6 mx-10 mb-[200px])`,
                tw`xl:(grid-cols-3 gap-6 mx-20 mb-[200px])`,
                tw`2xl:(grid-cols-4 gap-6 mx-10 mb-[200px])`,
                css`
                  @media (550px < width < 770px) {
                    margin-left: 40px;
                    margin-right: 40px;
                  }
                  @media (770px < width < 1024px) {
                    margin-left: 40px;
                    margin-right: 40px;
                    grid-template-columns: 1fr 1fr;
                    gap: 24px;
                  }
                `,
              ]}
            >
              {vehicleData && vehicleData.length > 0 ? (
                vehicleData.map((vehicle: Vehicle, index: number) => (
                  <div css={tw``}>
                    <InventoryResultCard
                      css={[tw`col-span-1`]}
                      vehicle={vehicle}
                      index={index}
                      location={"recent"}
                      removeRecentlyViewedVehicle={vehicle => {
                        setVehicleData(
                          vehicleData.filter(v => v.vin !== vehicle.vin),
                        )
                      }}
                    />
                  </div>
                ))
              ) : (
                <div
                  css={[
                    tw`col-span-2 col-start-1 text-center mt-6 mx-6`,
                    tw`md:(mx-20)`,
                    tw`xl:(col-span-3 mx-[300px])`,
                    tw`2xl:(col-start-2 col-end-4 mx-0)`,
                  ]}
                >
                  <h3
                    css={[tw`text-2xl font-book tracking-widest text-center`]}
                  >
                    {_(
                      "You don't have any recently viewed vehicles. Search inventory to view your vehicles.",
                    )}
                  </h3>
                </div>
              )}
            </div>
          )}
        </section>
      </RecentlyViewedDropDown>
    </div>
  )
}

export default RecentlyViewed
