import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Star icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */
const Star: React.FC<IconProps> = ({ color, filled, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 19 18" {...remainingProps}>
      <path
        d="m17.13 6.36-4.643-.666-2.075-4.137c-.372-.738-1.449-.747-1.824 0L6.513 5.694l-4.642.665c-.833.119-1.166 1.128-.563 1.706l3.359 3.218-.794 4.547c-.143.822.737 1.437 1.474 1.053L9.5 14.736l4.153 2.147c.737.381 1.617-.231 1.474-1.053l-.794-4.547 3.359-3.218c.603-.578.27-1.587-.563-1.706Z"
        fill-rule="nonzero"
        stroke={colorString}
        fill={filled ? colorString : "none"}
      />
    </svg>
  )
}

export default Star
