import React, { useContext, useRef, useState } from "react"
import { StickyFooterProps } from "./StickyFooter.d"
import tw from "twin.macro"
import { Link } from "../../atoms/Link"
import { LocationContext } from "../../../contexts/Location"
import useScrollSpy from "../../../hooks/useScrollSpy"
import JumpToMenu from "./JumpToMenu"
import ChatBubbleMenu from "./ChatBubbleMenu"
import ChatBubbleButton from "./ChatBubbleButton"
import JumpToButton from "./JumpToButton"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"

/**
 * The menu at the bottom of the screen, contains the "Jump To" TOC menu and a chat bubble with contact options.
 *
 * @param { current: HTMLElement } mainRef Ref to <main> tag in Layout comp, using here to access DOM for scroll spy
 * @param {templateSection[]} templateSections Object containing all current template's page section TOC data for the 'Jump To' menu
 * @param {string} templateSlug Current template slug to add to anchor tags
 * @returns <section></section>
 */

const StickyFooter: React.FC<StickyFooterProps> = ({
  mainRef,
  // templateSections,
  // templateSlug,
}) => {
  // Toggle state for all menus in the sticky footer
  const [toggle, setToggle] = useState({ jumpTo: false, chatBubble: false })

  // Pulls any and all dealers within 25 miles of user's location
  // const [{ dealers }] = useContext(LocationContext)

  // Pass template section slugs to useScrollSpy hook
  // Hook returns the index of the currently in-view section
  // const [inViewSection] = useScrollSpy(
  //   templateSections?.map((node: { slug: string }) =>
  //     mainRef?.current?.querySelector(`[id="${node?.slug}"]`)
  //   ),
  //   {
  //     rootMargin: "-71px 0px 0px 0px",
  //   }
  // )

  return (
    <section
      css={[tw`fixed bottom-0 right-0 w-auto`, "z-index: 997;"]}
      aria-label="Chat, Text, Contact Dealer Section"
    >
      <div
        css={[
          tw`relative flex justify-end items-center gap-x-14 h-14 ml-auto pl-3 pr-4 pb-6 text-[#b2b2b2] text-[0.625rem] uppercase`,
          tw`lg:(pr-6)`,
          // Remove blue highlight when clicked/tapped
          `& > button {
            -webkit-tap-highlight-color: transparent;
          }`,
          //templateSections && tw`justify-end`,
        ]}
      >
        {/*~~~~~~ Jump To Menu ~~~~~~*/}
        {/* {templateSections && (
          <>
            <JumpToButton toggle={toggle} setToggle={setToggle} />
            <JumpToMenu
              templateSections={templateSections}
              templateSlug={templateSlug}
              toggle={toggle}
              inViewSection={inViewSection}
            />
          </>
        )} */}

        {/* Display # of dealers within 25 miles of user's location */}
        {/* dealers?.length ? (
          <Link 
            to="/dealers/" 
            css={[tw`font-semibold`]}
            }
          >
            {dealers?.length || "FIND"} DEALERS NEAR YOU
          </Link>
        ) : (
          <div css={[tw`font-semibold`]}>LOOKING FOR DEALERS...</div>
        )} */}

        {/*~~~~~~ ChatBubble ~~~~~~*/}
        <ChatBubbleButton toggle={toggle} setToggle={setToggle} />
        <ChatBubbleMenu toggle={toggle} setToggle={setToggle} />
      </div>
    </section>
  )
}

export default StickyFooter
