import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * EvHybrid icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const EvHybrid = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg fill="none" viewBox="0 0 37 36" {...remainingProps}>
      <mask
        id="a"
        width="37"
        height="36"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path fill="#fff" d="M36.5 0H.5v36h36V0Z" />
      </mask>
      <g fill={colorString} mask="url(#a)">
        <path d="M32.756 3.744c-2.742-2.742-7.204-2.741-9.946 0-3.563 3.564-4.776 11.169-5.072 13.495-2.326.295-9.93 1.508-13.494 5.072a7.04 7.04 0 0 0 0 9.945 7.009 7.009 0 0 0 4.973 2.057 7.013 7.013 0 0 0 4.973-2.057c3.563-3.563 4.776-11.168 5.072-13.494 2.326-.296 9.932-1.51 13.495-5.072a7.042 7.042 0 0 0-.001-9.946Zm-19.76 27.319a5.351 5.351 0 0 1-7.558 0 5.352 5.352 0 0 1 0-7.56c2.97-2.968 9.528-4.165 12.09-4.53-.366 2.561-1.562 9.12-4.531 12.09Zm6.477-14.036c.365-2.562 1.561-9.12 4.53-12.09a5.33 5.33 0 0 1 3.78-1.562 5.33 5.33 0 0 1 3.78 1.562 5.35 5.35 0 0 1 0 7.56c-2.97 2.969-9.529 4.165-12.09 4.53Z" />
        <path d="M27.781 34.313a7.039 7.039 0 0 1-7.031-7.032 7.039 7.039 0 0 1 7.031-7.031 7.039 7.039 0 0 1 7.032 7.031 7.039 7.039 0 0 1-7.032 7.032Zm0-12.376a5.35 5.35 0 0 0-5.343 5.344 5.35 5.35 0 0 0 5.343 5.344 5.35 5.35 0 0 0 5.344-5.344 5.35 5.35 0 0 0-5.344-5.343ZM9.219 15.75a7.039 7.039 0 0 1-7.031-7.031 7.039 7.039 0 0 1 7.03-7.031 7.039 7.039 0 0 1 7.032 7.03 7.039 7.039 0 0 1-7.03 7.032Zm0-12.375a5.35 5.35 0 0 0-5.344 5.344 5.35 5.35 0 0 0 5.344 5.344 5.35 5.35 0 0 0 5.344-5.344 5.35 5.35 0 0 0-5.344-5.344Z" />
      </g>
    </svg>
  )
}

export default EvHybrid
