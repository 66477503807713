import React, { useState, useRef, ReactNode, useEffect } from "react"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import tw from "twin.macro"
import { SelectContext } from "./selectContext"
import { SelectProps } from "./Select.d"
import { Chevron } from "../Icon"

const Select: React.FC<SelectProps> = ({
  children,
  value,
  placeholder = "Choose an option",
  border,
  small,
  red,
  onChange,
  ...remainingProps
}) => {
  const [selectedOption, setSelectedOption] = useState(value)
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const selectContainerRef = useRef(null)
  const showDropdownHandler = () => setShowDropdown(!showDropdown)
  useOnClickOutside(selectContainerRef, () => setShowDropdown(false))

  const updateSelectedOption = (value: string, label: string) => {
    setSelectedOption([value, label])
    if (onChange) onChange([value, label])
    setShowDropdown(false)
  }

  return (
    <div
      css={[
        tw`inline-block relative bg-white`,
        small && tw`inline-block text-sm rounded-2xl leading-tight px-2`,
      ]}
      {...remainingProps}
      ref={selectContainerRef}
    >
      <div
        css={[
          tw`py-1.5 flex items-center justify-between cursor-pointer`,
          border && tw`px-5 border-black border-b-2`,
        ]}
        onClick={showDropdownHandler}
      >
        {selectedOption ? selectedOption[1] : placeholder}
        <Chevron
          color={red ? "red-400" : "black"}
          direction="down"
          css={[tw`w-4 inline-block ml-4`, small && tw`w-3`]}
        />
      </div>
      <ul
        css={[
          tw`absolute min-w-full shadow-3 max-h-0 invisible overflow-y-scroll transform -translate-y-2 border border-gray-50 bg-white
            transition-all duration-300 z-20`,
          showDropdown && tw`max-h-[220px] visible translate-y-0`,
        ]}
      >
        {React.Children.map(children, (child: ReactNode) => {
          return React.cloneElement(child, {
            selected: selectedOption && child.props.value === selectedOption[0],
            onClick: (label: string, value: string | number) =>
              updateSelectedOption(value, label),
          })
        })}
      </ul>
    </div>
  )
}

export default Select
