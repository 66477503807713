import { LocalStorageClient } from "../../clients/LocalStorageClient"
import { Dealer } from "../../global"

export enum ACTION_TYPES {
  TOGGLE_DRAWER,
  EXPAND_FAVORITES,
  ADD_FAVORITED_VEHICLE,
  REMOVE_FAVORITED_VEHICLE,
  ADD_FAVORITED_ACCESSORY,
  REMOVE_FAVORITED_ACCESSORY,
  ADD_FAVORITED_DEALER,
  REMOVE_FAVORITED_DEALER,
  ADD_FAVORITED_OFFER,
  REMOVE_FAVORITED_OFFER,
  SET_FAVORITE_MESSAGE,
  SET_SHOULD_SHOW_TOOLTIP,
}

export const toggleDrawer = () => ({
  type: ACTION_TYPES.TOGGLE_DRAWER,
})

export const expandFavorites = () => ({
  type: ACTION_TYPES.EXPAND_FAVORITES,
})

export const addFavoritedVehicle = (vin: string) => {
  LocalStorageClient.put("favoritedVehicles", [vin])
  return {
    type: ACTION_TYPES.ADD_FAVORITED_VEHICLE,
    payload: vin,
  }
}

export const removeFavoritedVehicle = (vin: string) => {
  LocalStorageClient.delete("favoritedVehicles", vin)
  return {
    type: ACTION_TYPES.REMOVE_FAVORITED_VEHICLE,
    payload: vin,
  }
}
export const addFavoritedAccessory = (acc: any) => {
  LocalStorageClient.put("favoritedAccessories", [acc])
  return {
    type: ACTION_TYPES.ADD_FAVORITED_ACCESSORY,
    payload: acc,
  }
}

export const removeFavoritedAccessory = (acc: any) => {
  LocalStorageClient.delete("favoritedAccessories", acc.code, "code")
  return {
    type: ACTION_TYPES.REMOVE_FAVORITED_ACCESSORY,
    payload: acc,
  }
}
export const addFavoritedDealer = (dealer: Dealer) => {
  LocalStorageClient.write("favoritedDealer", dealer)
  return {
    type: ACTION_TYPES.ADD_FAVORITED_DEALER,
    payload: dealer,
  }
}

export const removeFavoritedDealer = () => {
  LocalStorageClient.delete("favoritedDealer")
  return {
    type: ACTION_TYPES.REMOVE_FAVORITED_DEALER,
  }
}

export const addFavoritedOffer = (offerId: string) => {
  LocalStorageClient.put("favoritedOffers", [offerId])
  return {
    type: ACTION_TYPES.ADD_FAVORITED_OFFER,
    payload: offerId,
  }
}

export const removeFavoritedOffer = (offerId: string) => {
  LocalStorageClient.delete("favoritedOffers", offerId)
  return {
    type: ACTION_TYPES.REMOVE_FAVORITED_OFFER,
    payload: offerId,
  }
}

export const setFavoriteMessage = (message: string) => {
  return {
    type: ACTION_TYPES.SET_FAVORITE_MESSAGE,
    payload: message,
  }
}

export const setShouldShowTooltip = (shouldShow: boolean) => {
  return {
    type: ACTION_TYPES.SET_SHOULD_SHOW_TOOLTIP,
    payload: shouldShow,
  }
}
