import React, { useState, useEffect, useRef } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { InputFieldProps } from "./ContactDealer.d"

const InputField: React.FC<InputFieldProps> = ({
  active,
  showVal,
  fieldName,
  type,
  label,
  validationMessage,
  value,
  customOnChange,
  heading,
  ...remainingProps
}) => {
  const [isActive, setIsActive] = useState(false)
  const inputFieldRef = useRef(null)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  useEffect(() => {
    if (value) {
      setIsActive(true)
    }
  }, [value])

  const keyDownHandler = (e: any) => {
    if (e.key === "Enter" && document.activeElement === inputFieldRef.current) {
      const nextBtn = document.getElementById("contactFormNextBtn")
      if (nextBtn) {
        nextBtn.focus()
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [])

  return (
    <div
      css={[
        tw`relative flex flex-col justify-end h-16 max-w-full w-full`,
        tw`after:(invisible block border-b-2 absolute bottom-0 left-1/2 w-0 h-px transform -translate-x-1/2 transition-all duration-700 ease-in-out)`,
        isActive && tw`after:(visible w-full z-10)`,
        showVal
          ? tw`after:(border-red-400 visible w-full z-10)`
          : tw`after:(border-black)`,
      ]}
      {...remainingProps}
    >
      <label
        htmlFor={fieldName}
        css={[
          tw`transition-all normal-case`,
          isActive
            ? tw`relative top-1 text-xs text-black`
            : tw`relative top-8 text-lg text-gray-600 cursor-text font-semibold z-0`,
        ]}
      >
        {label}
      </label>

      <input
        ref={inputFieldRef}
        onChange={e => {
          customOnChange(e.target?.value)
        }}
        onFocus={() => setIsActive(true)}
        onBlur={e =>
          e.target.value === ""
            ? setIsActive(false)
            : heading == `Enter ZIP, state, city, or dealer name`
              ? trackTealEvent({
                  tealium_event: "contact_field_change",
                  contact_question: heading,
                  customer_zip: e.target?.value,
                  vehicle_segment_vehicle_page:
                    contactDealerData?.fromInventory
                      ?.vehicle_segment_vehicle_page ||
                    contactDealerData?.fromSelection
                      ?.vehicle_segment_vehicle_page,
                  vehicle_model_truncated:
                    contactDealerData?.fromInventory?.vehicle_model ||
                    contactDealerData?.fromSelection?.vehicle_model_truncated,
                  vehicle_year:
                    contactDealerData?.fromInventory?.vehicle_year ||
                    contactDealerData?.fromSelection?.vehicle_year,
                })
              : trackTealEvent({
                  tealium_event: "contact_field_change",
                  contact_question: heading,
                  vehicle_segment_vehicle_page:
                    contactDealerData?.fromInventory
                      ?.vehicle_segment_vehicle_page ||
                    contactDealerData?.fromSelection
                      ?.vehicle_segment_vehicle_page,
                  vehicle_model_truncated:
                    contactDealerData?.fromInventory?.vehicle_model ||
                    contactDealerData?.fromSelection?.vehicle_model_truncated,
                  vehicle_year:
                    contactDealerData?.fromInventory?.vehicle_year ||
                    contactDealerData?.fromSelection?.vehicle_year,
                })
        }
        id={fieldName}
        name={fieldName}
        type={type}
        disabled={!active}
        value={value}
        css={[
          tw`block bg-transparent w-full border-gray-400 border-b py-1 text-lg text-black font-semibold z-10`,
          tw`focus:outline-none` /* Removes blue outline on focus */,
        ]}
      />
      {/* Validation popover */}
      <section
        css={[
          tw`absolute -bottom-9 left-1/2 -translate-x-1/2 overflow-hidden transition-all duration-300`,
          showVal ? tw`max-h-[50px]` : tw`max-h-0`,
        ]}
      >
        <div
          css={[
            tw`bg-red-400 text-red-50 text-xs leading-3 normal-case whitespace-nowrap mx-auto mt-3 py-2 px-3 rounded-md`,
            // Pointed div
            tw`before:(absolute right-[calc(50% - 10px)] top-1.5 border-solid border-[10px] border-t-0 border-transparent border-b-red-400)`,
          ]}
        >
          {validationMessage}
        </div>
      </section>
    </div>
  )
}

export default InputField
