import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Lock icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */
const Lock: React.FC<IconProps> = ({ color, filled, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 23 36" {...remainingProps}>
      <g
        stroke={colorString}
        stroke-width="1.245"
        fill={filled ? colorString : "none"}
        fill-rule="evenodd"
      >
        <path d="M21.48 18.576H4.046S1.44 34.355 7.986 35h9.574c6.545-.645 3.92-16.424 3.92-16.424ZM10.773 30.948l1.251-5.112s-1.465-3.922.738-3.8c2.204-.122.75 3.8.75 3.8l1.258 5.112h-3.997ZM3.666 3.019c-6.55 6.035 1.03 14.242 1.03 14.242l4.158-.024c-.443-.476-1.856-2.335-2.215-2.806-4.517-5.99-1.227-9.418-1.227-9.418s4.278-3.486 10.28 3.652l2.445-2.261s-7.906-9.42-14.47-3.385" />
      </g>
    </svg>
  )
}

export default Lock
