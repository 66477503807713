import React, { useContext } from "react"
import { ToyotaMenuProps } from "./Footer.d"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { LinkType } from "../../../global"
import { Link } from "../../atoms/Link"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { getCTALinkValue } from "../../../helpers"

/**
 *
 * @author Tyler
 * @summary - Row of Toyota-specific links in the bottom row
 *
 * @component
 * @param {Link} links - Array of links to map over
 *
 * @returns <ToyotaMenu />
 *
 */
const ToyotaMenu: React.FC<ToyotaMenuProps> = ({
  links,
  ...remainingProps
}) => {
  const { _ } = useContext(LanguageContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  if (!links) return null
  return (
    <ul
      css={[tw`flex justify-center flex-wrap text-sm text-white`]}
      {...remainingProps}
    >
      {links?.map((link: LinkType, index: number) => (
        <li
          key={link?._key}
          css={[tw`whitespace-nowrap text-xs text-gray-600`]}
          analytics-id={`${link?.displayText}:footer:`}
          onClick={() =>
            trackTealEvent({
              tealium_event: "nav_click",
              click_text: `footer:${link?.displayText}`,
            })
          }
        >
          <Link
            animated
            animatedThin
            to={getCTALinkValue(link)}
            target={link?.linkType === "external" ? "_blank" : "_self"}
            css={[
              tw`py-1 my-1 border border-transparent inline-block items-center`,
              tw`focus-visible:(border-white border-dashed border outline-none p-2 -ml-2 -mb-0 -mt-0 -mr-2)`,
            ]}
          >
            {link?.displayText}
            {link?.externalUrl && (
              <span css={[tw``]}>
                <span css={[tw`sr-only`]}>{_("opens in new window")}</span>
                <Icon.ExternalLink
                  color="gray-600"
                  css={[tw`max-h-3 w-3 inline-flex ml-2 mr-0`]}
                />
              </span>
            )}
          </Link>
          {index !== links.length - 1 && (
            <span css={[tw`mx-3 text-gray-600`]}>|</span>
          )}
        </li>
      ))}
    </ul>
  )
}

export default ToyotaMenu
