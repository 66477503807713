import { graphql, useStaticQuery } from "gatsby"

const useStringTranslations = () => {
  const translationStrings = useStaticQuery(graphql`
    query TranslationStrings {
      translationStrings: allSanityTranslationStrings {
        nodes {
          strings {
            en
            es
          }
        }
      }
    }
  `)
  const data =
    translationStrings?.translationStrings?.nodes &&
    translationStrings?.translationStrings?.nodes[0]?.strings
  return data
}

export default useStringTranslations
