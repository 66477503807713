import React from "react"
import tw from "twin.macro"
import { getTWColorHex, processIconColor } from "../../../helpers"
import { IconProps } from "./Icon.d"

/**
 * Marker icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns <svg />
 */
const Marker: React.FC<IconProps> = ({ color, label, ...remainingProps }) => {
  return (
    <div css={[tw`relative`]}>
      <svg viewBox="0 0 24 24" {...remainingProps}>
        <path
          d="M12 0C6.29 0 2.104 6.154 4.879 11.159L12 24l7.121-12.841C21.896 6.154 17.71 0 12 0"
          fill={getTWColorHex(color)}
          fill-rule="evenodd"
        />
      </svg>
    </div>
  )
}

export default Marker
