import React, { useState, useRef } from "react"
import { FooterAccordionProps } from "./Footer.d"
import tw from "twin.macro"
import Icon from "../../atoms/Icon"
import { NavigationSubGroup } from "./NavigationSubGroup"

/**
 *
 * @author Tyler
 * @summary - Mobile view for navigation groups
 *
 * @returns <FooterAccordion />
 *
 */
const FooterAccordion: React.FC<FooterAccordionProps> = ({ group }) => {
  const [active, setActive] = useState(false)

  /* Ref to accordion container to pull its scroll height, note
     that you cannot animate height without a fixed value, using this
     ref we can set a hard height value on each container and animate it */
  const content = useRef(null)

  return (
    group && (
      <div key={group?._key} css={[tw`flex flex-col w-full`]}>
        <button
          type="button"
          css={[
            tw`flex justify-between items-center`,
            /* Remove blue highlight on tap */
            "-webkit-tap-highlight-color: transparent;",
          ]}
          // Expanded attribute set to true when element is activated
          aria-expanded={active}
          onClick={() => setActive(!active)}
        >
          {group?.heading && (
            <span css={[tw`text-sm font-semibold uppercase`]}>
              {group?.heading}
            </span>
          )}
          <Icon.Chevron
            direction="down"
            color="white"
            css={[
              tw`w-5 transition-transform duration-500`,
              active && tw`transform -rotate-180`,
            ]}
          />
        </button>
        <div
          ref={content}
          css={[
            tw`overflow-hidden transition-all ease-out duration-500 opacity-100`,
            active
              ? `height: ${content.current.scrollHeight}px`
              : tw`h-0 opacity-0`,
          ]}
        >
          {group?.items && (
            <div css={[tw`flex flex-wrap gap-8 pt-5`]}>
              <NavigationSubGroup items={group?.items} />
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default FooterAccordion
