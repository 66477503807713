import React, { useContext } from "react"
import { FavoriteTooltipProps } from "./FavoriteTooltip.d"
import { Tooltip } from "../../atoms/Tooltip"
import { LanguageContext } from "../../../contexts/Language"
import { FavoritesContext } from "../../../contexts/Favorites/context"

const FavoriteTooltip: React.FC<FavoriteTooltipProps> = ({}): JSX.Element => {
  const { _ } = useContext(LanguageContext)
  const [{ favoriteMessage, shouldShowTooltip }] = useContext(FavoritesContext)

  return shouldShowTooltip ? (
    <>
      {favoriteMessage === "default" && (
        <Tooltip fromTopNav>{_("Discover your favorites here.")}</Tooltip>
      )}
      {favoriteMessage === "dealer" && (
        <Tooltip fromTopNav>{_("Discover your favorite dealer here.")}</Tooltip>
      )}
      {favoriteMessage === "inventory" && (
        <Tooltip fromTopNav>
          {_("Discover your favorite vehicles here.")}
        </Tooltip>
      )}
      {favoriteMessage === "offers" && (
        <Tooltip fromTopNav>{_("Discover your favorite offers here.")}</Tooltip>
      )}
      {favoriteMessage === "accessories" && (
        <Tooltip fromTopNav>
          {_("Discover your favorite accessories here.")}
        </Tooltip>
      )}
    </>
  ) : null
}

export default FavoriteTooltip
