import React, { useState } from "react"
import { TabGroupProps } from "./TabGroup.d"

const TabGroup: React.FC<TabGroupProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<number | string>(0)

  return (
    <div role="tablist">{children({ activeTab, changeTab: setActiveTab })}</div>
  )
}

export default TabGroup
