import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Mail icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 *
 * @returns {JSX.Element} <svg />
 */
const Mail = ({ color, ...remainingProps }: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg
      width="103"
      height="84"
      viewBox="0 0 103 84"
      fill="none"
      {...remainingProps}
    >
      <path
        d="M100 12.75C100 7.3875 95.635 3 90.3 3H12.7C7.365 3 3 7.3875 3 12.75M100 12.75V71.25C100 76.6125 95.635 81 90.3 81H12.7C7.365 81 3 76.6125 3 71.25V12.75M100 12.75L51.5 46.875L3 12.75"
        stroke={colorString}
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Mail
