import React from "react"
import { NavigationGroup, NavigationGroupProps } from "./Footer.d"
import tw from "twin.macro"
import { NavigationSubGroup } from "./NavigationSubGroup"

/**
 *
 * @author Tyler
 * @summary - Section that displays the navigation menus grouped together
 *
 * @component
 * @param {NavigationGroup} groups - Array of groups of NavigationSubGroups
 *
 * @returns <NavigationGroupComp />
 *
 */
const NavigationGroupComp: React.FC<NavigationGroupProps> = ({ groups }) => {
  return (
    <>
      {groups?.map((group: NavigationGroup) => (
        <nav key={group?._key} css={[tw`flex-col`]} aria-label={group?.heading}>
          {group?.heading && (
            <h3 css={[tw`uppercase font-semibold mb-5`]}>{group?.heading}</h3>
          )}
          {group?.items && (
            <div css={[tw`flex flex-wrap gap-8`]}>
              <NavigationSubGroup items={group?.items} />
            </div>
          )}
        </nav>
      ))}
    </>
  )
}

export default NavigationGroupComp
