import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { Button } from "../../atoms/Button"
import ScheduleAServiceApptView from "./ScheduleAServiceApptView"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { DealerDetailsPageContext } from "../../../templates/dealerDetails"
import { useTealiumContext } from "../../../contexts/Tealium"
import { tealiumNavClick } from "./TealiumHelpers"
import useVehicleData from "../../../hooks/Tealium/useVehicleData"

const IntroView: React.FC<ViewProps> = ({
  active,
  showFormHeading,
  fromContactDealer,
}) => {
  const [{ leadType }, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)
  const { dealer } = useContext(DealerDetailsPageContext)
  console.log("leadtType", leadType)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()
  const [vehicleData] = useVehicleData()

  const options = [
    {
      label: _("Finance Options"),
      handleClick: () => {
        dispatch(updateField("leadType", "Finance Options"))
        dispatch(setActiveView("SelectVehicleView"))
        tealiumNavClick(
          "What can we do for you?",
          "Finance Options",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      },
    },
    {
      label: _("Schedule a Service Appointment"),
      handleClick: () => {
        dispatch(updateField("leadType", "Schedule Service Appointment"))
        dispatch(setActiveView("ScheduleAServiceApptView"))
        tealiumNavClick(
          "What can we do for you?",
          "Schedule a Service Appointment",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      },
    },
    {
      label: _("Special Offers & Incentives"),
      handleClick: () => {
        dispatch(updateField("leadType", "Special Offers & Incentives"))
        dispatch(setActiveView("SelectVehicleView"))
        tealiumNavClick(
          "What can we do for you?",
          "Special Offers & Incentives",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      },
    },
    {
      label: _("New Inventory Availability"),
      handleClick: () => {
        dispatch(updateField("leadType", "New Inventory Availability"))
        dispatch(setActiveView("SelectVehicleView"))
        tealiumNavClick(
          "What can we do for you?",
          "New Inventory Availability",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      },
    },
    {
      label: _("Used & Pre-Owned Inventory"),
      handleClick: () => {
        dispatch(updateField("leadType", "Used & Preowned Inventory"))
        dispatch(setActiveView("SelectVehicleView"))
        tealiumNavClick(
          "What can we do for you?",
          "Used & Pre-Owned Inventory",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      },
    },
    {
      label: _("Other Information"),
      handleClick: () => {
        dispatch(updateField("leadType", "Other"))
        dispatch(setActiveView("SelectVehicleView"))
        tealiumNavClick(
          "What can we do for you?",
          "Other Information",
          trackTealEvent,
          contactDealerData,
          vehicleData,
        )
      },
    },
  ]

  if (!active) return null

  return (
    <FormPage
      active={active}
      showButtons={false}
      showFormHeading={showFormHeading ?? true}
      heading={_("What can we do for you?")}
    >
      <section
        css={[
          tw`flex flex-wrap justify-center gap-3 my-7`,
          fromContactDealer === "true" && tw`px-8 lg:(px-4)`,
          tw`lg:px-4`,
        ]}
      >
        {options?.map(({ label, handleClick }) => (
          <Button
            negative
            onClick={handleClick}
            disabled={!active}
            css={[tw`text-black normal-case`]}
            analytics-id={`${label}:contact form:What can we do for you?`}
          >
            {label}
          </Button>
        ))}
      </section>
    </FormPage>
  )
}
export default IntroView
