import React, { useContext } from "react"
import tw from "twin.macro"
import { HeaderContext } from "../../../contexts/Header/context"
import Icon from "../../atoms/Icon"
import { RecentlyViewedDropDownProps } from "./RecentlyViewedDropDown.d"

/**
 *
 * @author Cody Clark
 * @summary - Custom dropdown behaviour for recently viewed functionality.
 *
 * @returns <FavoritesDropDown />
 *
 * @param {RecentlyViewedDropDownProps} props
 * @param {boolean} props.show - Show or hide search dropdown
 * @param {children} props.children - Child elements passed to dropdown
 * @param {number} props.index - Index of Nav item
 * @param {boolean} props.mobileView - passed state of mobile view
 * @param {boolean} props.toggleMobileDropdown - state for display of dropdown in mobile view
 *
 * @todo - Unit tests
 */

const RecentlyViewedDropDown: React.FC<RecentlyViewedDropDownProps> = ({
  show,
  children,
  mobileView,
  toggleMobileDropdown,
}): JSX.Element => {
  const { setOpen } = useContext(HeaderContext)

  return (
    <>
      <div
        css={[
          tw`z-[49] grid justify-items-center w-full absolute top-full left-0 bg-gray-100 max-h-0  transition-all  ease-out duration-300 shadow-3 overflow-x-auto scrollbar-hide`,
          show && tw` max-h-screen`,
          mobileView && toggleMobileDropdown && tw` max-h-screen`,
        ]}
      >
        {children}
      </div>
      <div
        css={[
          tw`hidden`,
          show &&
            tw`lg:(block bg-gray-900 opacity-70 h-full w-full fixed top-[118px] left-0 z-40)`,
        ]}
      ></div>
    </>
  )
}

export default RecentlyViewedDropDown
