import React, { useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Exterior icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */

const Exterior: React.FC<IconProps> = ({ ...remainingProps }) => {
  return (
    <svg viewBox="0 0 76 64" fill="none" {...remainingProps}>
      <path
        d="M10.5 39C10.7761 39 11 38.7761 11 38.5C11 38.2239 10.7761 38 10.5 38C10.2239 38 10 38.2239 10 38.5C10 38.7761 10.2239 39 10.5 39Z"
        fill="#C00000"
      />
      <path
        d="M9.5 38C9.77614 38 10 37.7761 10 37.5C10 37.2239 9.77614 37 9.5 37C9.22386 37 9 37.2239 9 37.5C9 37.7761 9.22386 38 9.5 38Z"
        fill="#C00000"
      />
      <path
        d="M7.5 38C7.77614 38 8 37.7761 8 37.5C8 37.2239 7.77614 37 7.5 37C7.22386 37 7 37.2239 7 37.5C7 37.7761 7.22386 38 7.5 38Z"
        fill="#C00000"
      />
      <path
        d="M5.5 38C5.77614 38 6 37.7761 6 37.5C6 37.2239 5.77614 37 5.5 37C5.22386 37 5 37.2239 5 37.5C5 37.7761 5.22386 38 5.5 38Z"
        fill="#C00000"
      />
      <path
        d="M8.5 39C8.77614 39 9 38.7761 9 38.5C9 38.2239 8.77614 38 8.5 38C8.22386 38 8 38.2239 8 38.5C8 38.7761 8.22386 39 8.5 39Z"
        fill="#C00000"
      />
      <path
        d="M6.5 39C6.77614 39 7 38.7761 7 38.5C7 38.2239 6.77614 38 6.5 38C6.22386 38 6 38.2239 6 38.5C6 38.7761 6.22386 39 6.5 39Z"
        fill="#C00000"
      />
      <path
        d="M4.5 39C4.77614 39 5 38.7761 5 38.5C5 38.2239 4.77614 38 4.5 38C4.22386 38 4 38.2239 4 38.5C4 38.7761 4.22386 39 4.5 39Z"
        fill="#C00000"
      />
      <path
        d="M13.5 42C13.7761 42 14 41.7761 14 41.5C14 41.2239 13.7761 41 13.5 41C13.2239 41 13 41.2239 13 41.5C13 41.7761 13.2239 42 13.5 42Z"
        fill="#C00000"
      />
      <path
        d="M11.5 42C11.7761 42 12 41.7761 12 41.5C12 41.2239 11.7761 41 11.5 41C11.2239 41 11 41.2239 11 41.5C11 41.7761 11.2239 42 11.5 42Z"
        fill="#C00000"
      />
      <path
        d="M9.5 42C9.77614 42 10 41.7761 10 41.5C10 41.2239 9.77614 41 9.5 41C9.22386 41 9 41.2239 9 41.5C9 41.7761 9.22386 42 9.5 42Z"
        fill="#C00000"
      />
      <path
        d="M7.5 42C7.77614 42 8 41.7761 8 41.5C8 41.2239 7.77614 41 7.5 41C7.22386 41 7 41.2239 7 41.5C7 41.7761 7.22386 42 7.5 42Z"
        fill="#C00000"
      />
      <path
        d="M5.5 42C5.77614 42 6 41.7761 6 41.5C6 41.2239 5.77614 41 5.5 41C5.22386 41 5 41.2239 5 41.5C5 41.7761 5.22386 42 5.5 42Z"
        fill="#C00000"
      />
      <path
        d="M11.5 44C11.7761 44 12 43.7761 12 43.5C12 43.2239 11.7761 43 11.5 43C11.2239 43 11 43.2239 11 43.5C11 43.7761 11.2239 44 11.5 44Z"
        fill="#C00000"
      />
      <path
        d="M9.5 44C9.77614 44 10 43.7761 10 43.5C10 43.2239 9.77614 43 9.5 43C9.22386 43 9 43.2239 9 43.5C9 43.7761 9.22386 44 9.5 44Z"
        fill="#C00000"
      />
      <path
        d="M7.5 44C7.77614 44 8 43.7761 8 43.5C8 43.2239 7.77614 43 7.5 43C7.22386 43 7 43.2239 7 43.5C7 43.7761 7.22386 44 7.5 44Z"
        fill="#C00000"
      />
      <path
        d="M5.5 44C5.77614 44 6 43.7761 6 43.5C6 43.2239 5.77614 43 5.5 43C5.22386 43 5 43.2239 5 43.5C5 43.7761 5.22386 44 5.5 44Z"
        fill="#C00000"
      />
      <path
        d="M11.5 46C11.7761 46 12 45.7761 12 45.5C12 45.2239 11.7761 45 11.5 45C11.2239 45 11 45.2239 11 45.5C11 45.7761 11.2239 46 11.5 46Z"
        fill="#C00000"
      />
      <path
        d="M9.5 46C9.77614 46 10 45.7761 10 45.5C10 45.2239 9.77614 45 9.5 45C9.22386 45 9 45.2239 9 45.5C9 45.7761 9.22386 46 9.5 46Z"
        fill="#C00000"
      />
      <path
        d="M7.5 46C7.77614 46 8 45.7761 8 45.5C8 45.2239 7.77614 45 7.5 45C7.22386 45 7 45.2239 7 45.5C7 45.7761 7.22386 46 7.5 46Z"
        fill="#C00000"
      />
      <path
        d="M5.5 46C5.77614 46 6 45.7761 6 45.5C6 45.2239 5.77614 45 5.5 45C5.22386 45 5 45.2239 5 45.5C5 45.7761 5.22386 46 5.5 46Z"
        fill="#C00000"
      />
      <path
        d="M11.5 48C11.7761 48 12 47.7761 12 47.5C12 47.2239 11.7761 47 11.5 47C11.2239 47 11 47.2239 11 47.5C11 47.7761 11.2239 48 11.5 48Z"
        fill="#C00000"
      />
      <path
        d="M9.5 48C9.77614 48 10 47.7761 10 47.5C10 47.2239 9.77614 47 9.5 47C9.22386 47 9 47.2239 9 47.5C9 47.7761 9.22386 48 9.5 48Z"
        fill="#C00000"
      />
      <path
        d="M7.5 48C7.77614 48 8 47.7761 8 47.5C8 47.2239 7.77614 47 7.5 47C7.22386 47 7 47.2239 7 47.5C7 47.7761 7.22386 48 7.5 48Z"
        fill="#C00000"
      />
      <path
        d="M5.5 48C5.77614 48 6 47.7761 6 47.5C6 47.2239 5.77614 47 5.5 47C5.22386 47 5 47.2239 5 47.5C5 47.7761 5.22386 48 5.5 48Z"
        fill="#C00000"
      />
      <path
        d="M11.5 50C11.7761 50 12 49.7761 12 49.5C12 49.2239 11.7761 49 11.5 49C11.2239 49 11 49.2239 11 49.5C11 49.7761 11.2239 50 11.5 50Z"
        fill="#C00000"
      />
      <path
        d="M9.5 50C9.77614 50 10 49.7761 10 49.5C10 49.2239 9.77614 49 9.5 49C9.22386 49 9 49.2239 9 49.5C9 49.7761 9.22386 50 9.5 50Z"
        fill="#C00000"
      />
      <path
        d="M7.5 50C7.77614 50 8 49.7761 8 49.5C8 49.2239 7.77614 49 7.5 49C7.22386 49 7 49.2239 7 49.5C7 49.7761 7.22386 50 7.5 50Z"
        fill="#C00000"
      />
      <path
        d="M5.5 50C5.77614 50 6 49.7761 6 49.5C6 49.2239 5.77614 49 5.5 49C5.22386 49 5 49.2239 5 49.5C5 49.7761 5.22386 50 5.5 50Z"
        fill="#C00000"
      />
      <path
        d="M11.5 52C11.7761 52 12 51.7761 12 51.5C12 51.2239 11.7761 51 11.5 51C11.2239 51 11 51.2239 11 51.5C11 51.7761 11.2239 52 11.5 52Z"
        fill="#C00000"
      />
      <path
        d="M9.5 52C9.77614 52 10 51.7761 10 51.5C10 51.2239 9.77614 51 9.5 51C9.22386 51 9 51.2239 9 51.5C9 51.7761 9.22386 52 9.5 52Z"
        fill="#C00000"
      />
      <path
        d="M11.5 38C11.7761 38 12 37.7761 12 37.5C12 37.2239 11.7761 37 11.5 37C11.2239 37 11 37.2239 11 37.5C11 37.7761 11.2239 38 11.5 38Z"
        fill="#C00000"
      />
      <path
        d="M13.5 38C13.7761 38 14 37.7761 14 37.5C14 37.2239 13.7761 37 13.5 37C13.2239 37 13 37.2239 13 37.5C13 37.7761 13.2239 38 13.5 38Z"
        fill="#C00000"
      />
      <path
        d="M9.5 40C9.77614 40 10 39.7761 10 39.5C10 39.2239 9.77614 39 9.5 39C9.22386 39 9 39.2239 9 39.5C9 39.7761 9.22386 40 9.5 40Z"
        fill="#C00000"
      />
      <path
        d="M7.5 40C7.77614 40 8 39.7761 8 39.5C8 39.2239 7.77614 39 7.5 39C7.22386 39 7 39.2239 7 39.5C7 39.7761 7.22386 40 7.5 40Z"
        fill="#C00000"
      />
      <path
        d="M5.5 40C5.77614 40 6 39.7761 6 39.5C6 39.2239 5.77614 39 5.5 39C5.22386 39 5 39.2239 5 39.5C5 39.7761 5.22386 40 5.5 40Z"
        fill="#C00000"
      />
      <path
        d="M11.5 40C11.7761 40 12 39.7761 12 39.5C12 39.2239 11.7761 39 11.5 39C11.2239 39 11 39.2239 11 39.5C11 39.7761 11.2239 40 11.5 40Z"
        fill="#C00000"
      />
      <path
        d="M13.5 40C13.7761 40 14 39.7761 14 39.5C14 39.2239 13.7761 39 13.5 39C13.2239 39 13 39.2239 13 39.5C13 39.7761 13.2239 40 13.5 40Z"
        fill="#C00000"
      />
      <path
        d="M12.5 39C12.7761 39 13 38.7761 13 38.5C13 38.2239 12.7761 38 12.5 38C12.2239 38 12 38.2239 12 38.5C12 38.7761 12.2239 39 12.5 39Z"
        fill="#C00000"
      />
      <path
        d="M14.5 39C14.7761 39 15 38.7761 15 38.5C15 38.2239 14.7761 38 14.5 38C14.2239 38 14 38.2239 14 38.5C14 38.7761 14.2239 39 14.5 39Z"
        fill="#C00000"
      />
      <path
        d="M10.5 41C10.7761 41 11 40.7761 11 40.5C11 40.2239 10.7761 40 10.5 40C10.2239 40 10 40.2239 10 40.5C10 40.7761 10.2239 41 10.5 41Z"
        fill="#C00000"
      />
      <path
        d="M8.5 41C8.77614 41 9 40.7761 9 40.5C9 40.2239 8.77614 40 8.5 40C8.22386 40 8 40.2239 8 40.5C8 40.7761 8.22386 41 8.5 41Z"
        fill="#C00000"
      />
      <path
        d="M6.5 41C6.77614 41 7 40.7761 7 40.5C7 40.2239 6.77614 40 6.5 40C6.22386 40 6 40.2239 6 40.5C6 40.7761 6.22386 41 6.5 41Z"
        fill="#C00000"
      />
      <path
        d="M4.5 41C4.77614 41 5 40.7761 5 40.5C5 40.2239 4.77614 40 4.5 40C4.22386 40 4 40.2239 4 40.5C4 40.7761 4.22386 41 4.5 41Z"
        fill="#C00000"
      />
      <path
        d="M12.5 41C12.7761 41 13 40.7761 13 40.5C13 40.2239 12.7761 40 12.5 40C12.2239 40 12 40.2239 12 40.5C12 40.7761 12.2239 41 12.5 41Z"
        fill="#C00000"
      />
      <path
        d="M10.5 43C10.7761 43 11 42.7761 11 42.5C11 42.2239 10.7761 42 10.5 42C10.2239 42 10 42.2239 10 42.5C10 42.7761 10.2239 43 10.5 43Z"
        fill="#C00000"
      />
      <path
        d="M8.5 43C8.77614 43 9 42.7761 9 42.5C9 42.2239 8.77614 42 8.5 42C8.22386 42 8 42.2239 8 42.5C8 42.7761 8.22386 43 8.5 43Z"
        fill="#C00000"
      />
      <path
        d="M6.5 43C6.77614 43 7 42.7761 7 42.5C7 42.2239 6.77614 42 6.5 42C6.22386 42 6 42.2239 6 42.5C6 42.7761 6.22386 43 6.5 43Z"
        fill="#C00000"
      />
      <path
        d="M4.5 43C4.77614 43 5 42.7761 5 42.5C5 42.2239 4.77614 42 4.5 42C4.22386 42 4 42.2239 4 42.5C4 42.7761 4.22386 43 4.5 43Z"
        fill="#C00000"
      />
      <path
        d="M12.5 43C12.7761 43 13 42.7761 13 42.5C13 42.2239 12.7761 42 12.5 42C12.2239 42 12 42.2239 12 42.5C12 42.7761 12.2239 43 12.5 43Z"
        fill="#C00000"
      />
      <path
        d="M10.5 45C10.7761 45 11 44.7761 11 44.5C11 44.2239 10.7761 44 10.5 44C10.2239 44 10 44.2239 10 44.5C10 44.7761 10.2239 45 10.5 45Z"
        fill="#C00000"
      />
      <path
        d="M8.5 45C8.77614 45 9 44.7761 9 44.5C9 44.2239 8.77614 44 8.5 44C8.22386 44 8 44.2239 8 44.5C8 44.7761 8.22386 45 8.5 45Z"
        fill="#C00000"
      />
      <path
        d="M6.5 45C6.77614 45 7 44.7761 7 44.5C7 44.2239 6.77614 44 6.5 44C6.22386 44 6 44.2239 6 44.5C6 44.7761 6.22386 45 6.5 45Z"
        fill="#C00000"
      />
      <path
        d="M4.5 45C4.77614 45 5 44.7761 5 44.5C5 44.2239 4.77614 44 4.5 44C4.22386 44 4 44.2239 4 44.5C4 44.7761 4.22386 45 4.5 45Z"
        fill="#C00000"
      />
      <path
        d="M12.5 45C12.7761 45 13 44.7761 13 44.5C13 44.2239 12.7761 44 12.5 44C12.2239 44 12 44.2239 12 44.5C12 44.7761 12.2239 45 12.5 45Z"
        fill="#C00000"
      />
      <path
        d="M10.5 47C10.7761 47 11 46.7761 11 46.5C11 46.2239 10.7761 46 10.5 46C10.2239 46 10 46.2239 10 46.5C10 46.7761 10.2239 47 10.5 47Z"
        fill="#C00000"
      />
      <path
        d="M8.5 47C8.77614 47 9 46.7761 9 46.5C9 46.2239 8.77614 46 8.5 46C8.22386 46 8 46.2239 8 46.5C8 46.7761 8.22386 47 8.5 47Z"
        fill="#C00000"
      />
      <path
        d="M6.5 47C6.77614 47 7 46.7761 7 46.5C7 46.2239 6.77614 46 6.5 46C6.22386 46 6 46.2239 6 46.5C6 46.7761 6.22386 47 6.5 47Z"
        fill="#C00000"
      />
      <path
        d="M4.5 47C4.77614 47 5 46.7761 5 46.5C5 46.2239 4.77614 46 4.5 46C4.22386 46 4 46.2239 4 46.5C4 46.7761 4.22386 47 4.5 47Z"
        fill="#C00000"
      />
      <path
        d="M10.5 49C10.7761 49 11 48.7761 11 48.5C11 48.2239 10.7761 48 10.5 48C10.2239 48 10 48.2239 10 48.5C10 48.7761 10.2239 49 10.5 49Z"
        fill="#C00000"
      />
      <path
        d="M8.5 49C8.77614 49 9 48.7761 9 48.5C9 48.2239 8.77614 48 8.5 48C8.22386 48 8 48.2239 8 48.5C8 48.7761 8.22386 49 8.5 49Z"
        fill="#C00000"
      />
      <path
        d="M6.5 49C6.77614 49 7 48.7761 7 48.5C7 48.2239 6.77614 48 6.5 48C6.22386 48 6 48.2239 6 48.5C6 48.7761 6.22386 49 6.5 49Z"
        fill="#C00000"
      />
      <path
        d="M10.5 51C10.7761 51 11 50.7761 11 50.5C11 50.2239 10.7761 50 10.5 50C10.2239 50 10 50.2239 10 50.5C10 50.7761 10.2239 51 10.5 51Z"
        fill="#C00000"
      />
      <path
        d="M8.5 51C8.77614 51 9 50.7761 9 50.5C9 50.2239 8.77614 50 8.5 50C8.22386 50 8 50.2239 8 50.5C8 50.7761 8.22386 51 8.5 51Z"
        fill="#C00000"
      />
      <path
        d="M6.5 51C6.77614 51 7 50.7761 7 50.5C7 50.2239 6.77614 50 6.5 50C6.22386 50 6 50.2239 6 50.5C6 50.7761 6.22386 51 6.5 51Z"
        fill="#C00000"
      />
      <path
        d="M48.4727 21.512C56.1656 11.756 69.2496 3.0098 74 2"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.3052 21.4306C49.0315 28.1735 50.6928 36.2845 54.6886 53.4783"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.9496 50.3957C43.4629 43.1818 38.031 36.9177 30.8171 36.4044C23.6033 35.8911 17.3392 41.323 16.8259 48.5369C16.3126 55.7507 21.7445 62.0148 28.9583 62.5281C36.1722 63.0414 42.4363 57.6095 42.9496 50.3957Z"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.0004 21.0342C72.4857 21.056 71.009 21.0777 69.5703 21.094"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.2395 21.2731C54.0103 21.3546 50.628 21.4251 48.4672 21.512C30.3613 22.1798 15.4695 26.2733 7.92852 28.7652C4.68738 29.8347 2.38003 32.6958 2 36.089C2 36.089 2.92837 46.3173 4.2802 49.4716C5.90891 53.2719 11.4791 53.5325 12.0003 53.5217C12.5595 53.5054 12.9992 53.0548 12.9992 52.4956V49.4716C12.9992 40.2585 20.3773 32.7664 29.5524 32.5927C39.0152 32.4135 46.7679 40.3399 46.7679 49.8082V52.2404C46.7679 52.9299 47.3217 53.4837 48.0112 53.4837H74"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.374 7.03815L62.2676 17.7388"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.0815 22.3535C58.4887 24.5089 65.6333 24.9703 67.7832 24.5632C69.9385 24.156 69.7919 22.0821 69.3902 19.9322C68.9884 17.7823 68.5107 16.805 66.3174 16.767C62.3487 16.6965 57.6743 20.1982 58.0815 22.3535Z"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.00586 36.0836H14.3407L17.028 38.5266"
        stroke="black"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default Exterior
