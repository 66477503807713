import React, { useState } from "react"
import { SocialMediaIconProps, SocialMediaIcons } from "./SocialMediaIcons.d"
import tw from "twin.macro"
import {
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  YouTube,
} from "../../atoms/Icon"
import { Link } from "../../atoms/Link"

const iconData: SocialMediaIcons = {
  facebook: {
    color: "#3b5998",
    icon: Facebook,
  },
  twitter: {
    color: "#000000",
    icon: Twitter,
  },
  instagram: {
    color: "#cd486b",
    icon: Instagram,
  },
  youtube: {
    color: "#ff0000",
    icon: YouTube,
  },
  pinterest: {
    color: "#c8232c",
    icon: Pinterest,
  },
}

/**
 *
 * @author Tyler
 * @summary - Conditionally renders an icon based on type of icon. Consumed by SocialMediaMenu.
 *
 * @component
 * @param {string} iconType - Determines which brand logo is being rendered
 * @param {string} linkTo - URL the icon is pointed to
 *
 * @returns <SocialMediaNavItem />
 *
 */
const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({
  network,
  url,
  ...remainingProps
}) => {
  const [hover, setHover] = useState<boolean>(false)
  const data = iconData[network]
  if (!data) return null
  const Icon = data.icon

  return (
    <li>
      <Link
        to={url}
        css={[
          tw`w-12 h-12 flex items-center justify-center rounded-full outline-none p-0 focus-visible:outline-none`,
          hover && `background-color: ${data.color}`,
        ]}
        // This is necessary to set dynamic background colors, we can't just use group-hover like normal
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        {...remainingProps}
      >
        <span
          css={[
            tw`flex items-center justify-center w-full h-full rounded-full transform`,
          ]}
        >
          <Icon
            color={hover ? "white" : "gray-600"}
            css={[
              tw`h-8 transition-all ease-out duration-500`,
              hover && tw`scale-[65%]`,
            ]}
          />
        </span>
      </Link>
    </li>
  )
}

export default SocialMediaIcon
