import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Bar icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Stroke color of the icon
 *
 * @returns <svg />
 *
 */

const Bar: React.FC<IconProps> = ({ color, ...remainingProps }) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 10 10" {...remainingProps}>
      <path
        fill="none"
        stroke={colorString}
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M 1,5 l 8,0"
      />
    </svg>
  )
}

export default Bar
