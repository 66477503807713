import React from "react"
import tw from "twin.macro"
import { CarouselItemProps } from "./Carousel.d"

const CarouselItem: React.FC<CarouselItemProps> = ({
  active,
  children,
  ...remainingProps
}) => {
  return (
    <div
      css={[
        tw`absolute left-0 right-0 top-0 bottom-0 opacity-0 transition duration-500 ease-in-out z-0`,
        active && tw`opacity-100 z-[49]`,
      ]}
      {...remainingProps}
    >
      {children}
    </div>
  )
}

export default CarouselItem
