import React, { createContext, useReducer } from "react"
import { SearchProviderProps, SearchState } from "./search"
import searchReducer from "./reducers"

const initialState = {
  entry: "",
  query: "",
}

export const SearchContext = createContext<[SearchState, React.Dispatch<any>]>([
  initialState,
  () => {},
])

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const [searchState, dispatch] = useReducer(searchReducer, initialState)

  return (
    <SearchContext.Provider value={[searchState, dispatch]}>
      {children}
    </SearchContext.Provider>
  )
}
