import React from "react"
import { ImageLinkProps } from "./ImageLink.d"
import { Image } from "../../atoms/Image"
import { Link } from "../../atoms/Link"
import tw from "twin.macro"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { getCTALinkValue } from "../../../helpers"

/**
 *
 * @author Tyler
 * @summary - As the name implies, this is a link wrapped around an image.
 *
 * @component
 * @param {Object} image - Image object
 * @param {Object} link - Will be either an internal slug or an external URL
 *
 * @param remainingProps
 * @returns <ImageLink />
 */

const ImageLink: React.FC<ImageLinkProps> = ({
  image,
  link,
  ...remainingProps
}) => {
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  return (
    <>
      {image && link && (
        <Link
          to={getCTALinkValue(link)}
          target={link?.linkType === "external" ? "_blank" : "_self"}
          css={[
            tw`p-0 justify-self-end`,
            `@media (max-width: 1420px) {
              margin: 2.5rem;
              margin-top: 1.5rem;

            }`,
          ]}
          onClick={() => {
            trackTealEvent({
              tealium_event: "nav_click",
              click_text: `top nav:${image.alt}`,
            })
          }}
          {...remainingProps}
        >
          <Image
            imageData={image.image}
            css={[tw`w-full`]}
            objectFit="contain"
          />
        </Link>
      )}
    </>
  )
}

export default ImageLink
