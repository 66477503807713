import { useContext, useState } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import { setActiveView, updateField } from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { Button, ButtonLink } from "../../atoms/Button"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import { tealiumNavClick } from "./TealiumHelpers"

const ScheduleAServiceApptView: React.FC<ViewProps> = ({
  active,
  resetForm,
}) => {
  const [{}, dispatch] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        dispatch(setActiveView("IntroView"))
        tealiumNavClick(
          "How should we contact you?",
          "Back",
          trackTealEvent,
          contactDealerData,
        )
      }}
      nextButtonText={null}
      heading={_("How should we contact you?")}
    >
      <section css={[tw`flex justify-center gap-5 mt-8`]}>
        <Button
          primary
          onClick={() => {
            dispatch(updateField("contactType", "phone"))
            dispatch(setActiveView("NameView"))
            tealiumNavClick(
              "How should we contact you?",
              "Call",
              trackTealEvent,
              contactDealerData,
            )
          }}
        >
          {_("Call")}
        </Button>
        <ButtonLink
          primary
          to="/schedule-service/#dealersNearYou"
          onClick={() => {
            resetForm()
            tealiumNavClick(
              "How should we contact you?",
              "Online",
              trackTealEvent,
              contactDealerData,
            )
          }}
        >
          {_("Online")}
        </ButtonLink>
      </section>
    </FormPage>
  )
}

export default ScheduleAServiceApptView
