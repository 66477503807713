import React from "react"
import tw from "twin.macro"
import { IconProps } from "./Icon.d"
import { getTWColorHex, processIconColor } from "../../../helpers"

/**
 * Pencil icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns {JSX.Element} <svg />
 */
const Pencil = ({
  color,
  filled,
  ...remainingProps
}: IconProps): JSX.Element => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 72 72" {...remainingProps}>
      <g fill={colorString} fill-rule="evenodd">
        <path d="M47.322 6.624 3.473 50.472 21.53 68.528 65.377 24.68 47.322 6.624Zm0 5.157L60.22 24.68 21.529 63.367 8.631 50.472l38.69-38.69Z" />
        <path d="m53.944 0-8.616 8.616 18.056 18.056L72 18.056 53.944 0Zm-3.458 8.616 3.458-3.455L66.84 18.056l-3.455 3.458L50.486 8.616ZM0 72l24-6.431L6.431 48 0 72Zm7.94-17.143 9.203 9.203L4.57 67.43 7.94 54.857Z" />
        <path d="m0 72 11-2.948c-.727-2.095-1.727-3.779-3-5.052-1.273-1.273-2.957-2.273-5.052-3L0 72Z" />
      </g>
    </svg>
  )
}

export default Pencil
