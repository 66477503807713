import { setValues } from "framer-motion/types/render/utils/setters"
import { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import { ContactContext } from "../../../contexts/Contact"
import {
  setActiveView,
  updateField,
  setPreviousView,
} from "../../../contexts/Contact/actions"
import { LanguageContext } from "../../../contexts/Language"
import { useTealiumContext } from "../../../contexts/Tealium"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import InputField from "./InputField"
import { tealiumNavClick } from "./TealiumHelpers"
import { Radio } from "../../atoms/Radio"

const NameView: React.FC<ViewProps> = ({
  active,
  prevView,
  contactInputs,
  showFormHeading,
  nextPageOverride,
}) => {
  const [showVal, setShowVal] = useState(false)
  const [
    { fullName, contactButtonType, activeView, leadType, contact, contactType },
    dispatch,
  ] = useContext(ContactContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()
  const [activeContactInput, setActiveContactInput] = useState(
    contactInputs?.[0],
  )

  useEffect(() => {
    if (
      (contactButtonType === "CheckAvailability" || leadType === "tact") &&
      activeView == "NameView"
    ) {
      trackTealEvent({
        tealium_event: "contact_dealer",
        contact_question: "What's your name?",
        ...contactDealerData?.fromInventory,
        ...contactDealerData?.dealerData,
        ...contactDealerData?.allocatedDealer,
      })
    }
  }, [contactButtonType, contactDealerData, active])

  const handleClickNext = () => {
    const names = fullName?.trim()?.split(" ")
    if (names?.length === 2) {
      dispatch(updateField("firstName", names[0]))
      dispatch(updateField("lastName", names[1]))
      dispatch(setPreviousView("NameView"))
      dispatch(setActiveView(nextPageOverride ?? "SummaryView"))
      tealiumNavClick(
        "What's your name?",
        "Next",
        trackTealEvent,
        contactDealerData,
      )
    } else if (names?.length > 2) {
      const names = fullName?.split(" ")
      const initialFirstName = names?.shift()
      dispatch(updateField("firstName", initialFirstName))
      const initialLastNameValue = names?.join(" ")
      dispatch(updateField("lastName", initialLastNameValue))
      dispatch(setActiveView("NameValidationView"))
      tealiumNavClick(
        "What's your name?",
        "Next",
        trackTealEvent,
        contactDealerData,
      )
    } else {
      setShowVal(true)
      const nameInputEl = document.getElementById("fullName")
      nameInputEl?.focus()
      tealiumNavClick(
        "What's your name?",
        "Next",
        trackTealEvent,
        contactDealerData,
        null,
        null,
        null,
        activeContactInput?.validationMessage,
      )
    }
  }

  useEffect(() => {
    if (showVal) {
      setShowVal(false)
    }
  }, [fullName])

  useEffect(() => {
    setActiveContactInput(
      contactInputs?.find(input => input.value === contactType),
    )
    setShowVal(false)
  }, [contactType])

  useEffect(() => {
    if (showVal) {
      setShowVal(false)
    }
  }, [contact])

  const [isIosSafari, setIsIosSafari] = useState(false)

  useEffect(() => {
    if (window === undefined) return
    const ua = window.navigator.userAgent
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    const webkit = !!ua.match(/WebKit/i)
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)
    setIsIosSafari(iOSSafari)
  }, [])

  if (!active) return null

  return (
    <FormPage
      active={active}
      prevOnClick={() => {
        dispatch(setActiveView(prevView ? prevView : "SelectVehicleView")),
          tealiumNavClick(
            "What's your name?",
            "Back",
            trackTealEvent,
            contactDealerData,
          )
      }}
      nextOnClick={handleClickNext}
      heading={_("What's your name?")}
      showFormHeading={showFormHeading}
      css={isIosSafari && tw`translate-y-[45px]`}
    >
      <InputField
        active={active}
        showVal={showVal}
        fieldName="fullName"
        label={_("First & Last Name")}
        validationMessage={_("We need your first and last name.")}
        value={fullName}
        customOnChange={(value: string) =>
          dispatch(updateField("fullName", value))
        }
        type="text"
        css={[tw`max-w-[15rem]`]}
        heading="What's your name?"
      />
      <section css={[tw`flex flex-wrap justify-center gap-5 mt-4 mb-1`]}>
        <h3
          css={[
            tw`text-center text-2xl font-semibold normal-case mt-6 mb-3 block w-full`,
          ]}
        >
          {_("How should we contact you?")}
        </h3>
        {contactInputs?.length > 1 &&
          active && // prevent setting defaultChecked before view is active
          contactInputs?.map((node: any) => (
            <Radio
              label={node.label}
              name="group"
              value={node.value}
              onChange={e => {
                dispatch(updateField("contactType", e.target?.value))
                dispatch(updateField("contact", ""))
                tealiumNavClick(
                  "How should we contact you?",
                  e.target.value,
                  trackTealEvent,
                  contactDealerData,
                )
              }}
              defaultChecked={contactType === node.value}
              analytics-id={`${node.label}:contact form:How should we contact you?`}
            />
          ))}
      </section>
      <InputField
        active={active}
        value={contact}
        type={activeContactInput?.input}
        fieldName="contact"
        label={activeContactInput?.label}
        validationMessage={activeContactInput?.validationMessage}
        customOnChange={value => dispatch(updateField("contact", value))}
        showVal={showVal}
        css={[tw`max-w-[20rem]`]}
        heading="How should we contact you?"
      />
    </FormPage>
  )
}

export default NameView
