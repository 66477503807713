import React from "react"
import { CarouselIndicatorProps } from "./Carousel.d"
import tw from "twin.macro"
import { css } from "@emotion/react"

/**
 * @component
 * @author Matt Carstensen
 * @summary An Animated Progress Guage used as a Carousel Indicator
 *
 * @param   {CarouselIndicatorProps} props React Props
 * @param   {boolean} props.active indicates whether this component is active
 * @param   {Function} props.changeSlide passed prop to set the current slide
 * @param   {number} props.slideId for the aria label to indicate the current slide on screen readers
 * @param   {number} props.timeout number of milliseconds until the slide advances
 *   used to set the animation length.
 *
 * @returns <button></button>
 */

const CarouselIndicator: React.FC<CarouselIndicatorProps> = ({
  active,
  changeSlide,
  slideId,
  timeout = 0,
  isHovered,
  analyticsId,
  handleTealClick,
  captionHeading = "",
}) => {
  return (
    <button
      css={[
        tw`relative mx-1 mb-3 transition-colors bg-transparent border-transparent border-2 rounded hover:(border-gray-200)`,
        tw`focus-visible:(outline-white)`,
      ]}
      onClick={() => {
        changeSlide(slideId),
          handleTealClick("radio button", slideId, captionHeading)
      }}
      analytics-id={analyticsId ? analyticsId : null}
      aria-label={
        active
          ? `Current Slide, Slide ${slideId + 1}`
          : `Go To Slide ${slideId + 1}`
      }
    >
      <div
        css={[
          tw`bg-gray-200 w-8 h-1 border-gray-200 relative overflow-hidden`,
          `border-width: 0.025rem;`,
          active && tw`bg-transparent border-toyotaRed`,
        ]}
      >
        {active ? (
          <span
            css={[
              tw`bg-toyotaRed border-r-2 border-toyotaRed h-full absolute left-0 top-0 bottom-0 w-full`,
              css`
                animation: progressBar ${timeout}ms linear forwards;
                @keyframes progressBar {
                  /* Note:  Animating Width leaves a stutering effect.  Use use Traslate X */
                  from {
                    transform: translateX(-100%);
                  }
                  to {
                    transform: translateX(0%);
                  }
                }
              `,
              isHovered && `animation-play-state: paused;`,
            ]}
            role="presentation"
            className="progress-completed"
          />
        ) : null}
      </div>
    </button>
  )
}

export default CarouselIndicator
