import React, { useRef } from "react"
import { RadioProps } from "./Radio.d"
import tw from "twin.macro"
import useComponentId from "../../../hooks/useIdGenerator"

/**
 * Radio input
 *
 * @param {string} props.label - Label text and id of radio value
 * @param {string} props.name - Name of the label radio
 * @param {string} props.value - Value of radio
 * @param {"left" | "center" | "right"} props.alignment - Position of radio input relative to label text
 *
 * @returns <label></label>
 */

const Radio: React.FC<RadioProps> = ({
  label,
  value,
  name,
  alignment,
  children,
  inputProps = null,
  ...remainingProps
}) => {
  const inputRef = useRef(null)
  const uuid = useComponentId()

  return (
    <label
      htmlFor={`${label}-${uuid}`}
      css={[
        tw`flex items-center gap-3 text-sm relative`,
        alignment === "center" && tw`flex-col gap-0`,
        !remainingProps.disabled && tw`cursor-pointer`,
        "width: fit-content",
      ]}
      {...remainingProps}
    >
      <input
        ref={inputRef}
        type="radio"
        name={`${name}`}
        id={`${label}-${uuid}`}
        value={value}
        disabled={remainingProps.hasOwnProperty("disabled")}
        defaultChecked={remainingProps.defaultChecked}
        className="peer"
        css={[
          tw`appearance-none absolute h-5 w-5 rounded-full`,
          alignment === "right" && tw`right-0`,
        ]}
        {...inputProps}
      />
      <span
        css={[
          tw`h-5 w-5 rounded-full relative border-2 border-gray-500 bg-none peer-disabled:opacity-50`,
          !remainingProps.disabled &&
            (inputRef?.current?.checked || remainingProps?.defaultChecked) &&
            "box-shadow: 0px 0px 0px 2px white inset;",
          tw`transition-all
            peer-checked:(bg-red-400 border-red-400) 
            peer-hover:(border-red-500)
            peer-focus:(border-red-500) 
            `,
        ]}
      ></span>
      {children || (
        <span
          css={[
            tw`order-last 
              peer-disabled:opacity-30`,
            alignment === "right" && tw`order-first`,
          ]}
        >
          {label}
        </span>
      )}
    </label>
  )
}

export default Radio
